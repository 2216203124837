import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { FormUsersComponent } from './form-users/form-users.component';
import { UsersService } from './users.service';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { NotifierService } from 'angular-notifier';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

enum Columns {
  view = 'view',
  status = 'status',
  nickName = 'nickName',
  name = 'name',
  identifier = 'identification',
  rol_name = 'rol.name',
  operator_name = 'operator.name',
}

const FILTER_COLUMNS: { field: string, type: FieldType }[] = [
  { field: 'nickName', type: 'string' },
  { field: 'name', type: 'string' },
  { field: 'identification', type: 'string' },
]

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class UsersComponent implements OnInit, AfterViewInit {

  displayedColumns = Object.keys(Columns);
  dataSource = new MatTableDataSource<Users>();
  isAdmin: boolean = false;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = "id";
  direccion: string | null = "ASC";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // Filters config
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    const fielterField = new FilterField(column.field, `users.${column.field}`, column.type);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormUsersComponent>;
  private popupDialogRef!: MatDialogRef<ConfirmDialogComponent>;


  ///filtros
  nameFilter = new FormControl();
  userNameFilter = new FormControl();
  identifierFilter = new FormControl();

  selectedOpe: Operator = new Operator();
  listaOpe: Operator[] = [];
  exportFileName: string | undefined;


  constructor(public dialog: MatDialog,
    public servicios: UsersService,
    public commonService: CommonService,
    private translate: TranslateService,
    private notifier: NotifierService,
    private utilsService: UtilService,
    public rol:RolHandlerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.isAdmin = this.utilsService.isAdmin();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.exportFileName = this.translate.instant('export.fileUser');
    this.displayedColumns = [
      'status',
      'nickName',
      'name',
      'identifier',
      'rol_name',
      ...(this.isAdmin ? ['operator_name'] : []),  // Agregar columna de operador solo si es admin
      'view'
    ];

    if (this.isAdmin) await this.cargar();
    this.lanzarLLamada();
  }

  private getFilterableValue(column: string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  private removeFilterableValue(column: string) {
    const toRemoveIndex = this.filterList.findIndex((filter) => filter.column === column);
    this.filterList.splice(toRemoveIndex, 1);
  }

  async lanzarLLamada() {
    const request = new UsersConsoleRequest;
    const selectedOperator = this.getFilterableValue("idOperator");
    if (selectedOperator) {
      request.idOperator = selectedOperator.value as number;
      this.removeFilterableValue('idOperator');
    }
    request.filterAndPagBean = this.getFilterAndPagBean();

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    console.log(JSON.stringify(value))
    if (value) {
      this.dataSource = new MatTableDataSource<Users>(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;
    return new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );
  }

  async cargar() {
    //operadores
    this.utilsService.findOperators().subscribe({
      next: (operators) => {
        const operationsOptions = operators.map((op: Operator) => { return { label: op.name, value: op.id } });
        this.filterColumns.push(new FilterField('idOperator', 'users.operator', 'string', operationsOptions));
      },
      error: (err) => {
        console.error('Failed to load operators:', err);
      },
      complete: () => console.log('Operator loading completed.')
    });
  }

  operatorFilter(ob: MatSelectChange) {
    this.selectedOpe = ob.value;
    this.paginator.firstPage();
    this.lanzarLLamada();
  }


  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag: PageEvent) {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar=" + JSON.stringify(sortState))
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  onNew() {
    this.dialogRef = this.dialog.open(FormUsersComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/ }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  onEdit(row: Users) {
    this.dialogRef = this.dialog.open(FormUsersComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: { obj: row }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  onDelete(row: Users) {
    if(row.dateDelete) return;

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteUserTitle'); 
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteUserText'); 
    dialogData.textValue = row.name;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.servicios.deleteUser(row);
        console.log('CONFIRM received from dialog window');
        this.lanzarLLamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL received from dialog window');
      }
    });
  }

  onResetPassword(row: Users) {
    if(row.dateDelete) return;

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.resetPasswordTitle'); // "Reset Password"
    dialogData.textI18n = this.translate.instant('confirmDialog.resetPasswordText'); // "Are you sure you want to reset the password?"
    dialogData.textValue = row.name;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let sr = await this.servicios.resetUserPass(row);
        if (sr!.status >= 0) this.notifier.notify('success', this.translate.instant('notification.resetSuccess')); // "Reset successfully"
        this.lanzarLLamada();
      } 
    });
  }

  getLabelToExportFile(user:Users):string{
    return (!user.dateDelete ? 'users.statusType.enable' : 'users.statusType.disable');
  }

}
