<main id="main" class="main">
  <div class="pagetitle">
    <h1 i18n>{{ 'customizableAlerts.title' | translate }}</h1>
    <div>
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>

      <button *ngIf="rol.canByInput(rol.functIds.edit_alerts)" mat-raised-button class="custom-add-button"  (click)="onNew()">
        <i class="bi bi-plus"></i> {{ 'customizableAlerts.newAlert' | translate }}
      </button>
    </div>
  </div>

  <!-- Filtro de perfiles -->
  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" style="display:none;" i18n>
    {{ 'customizableAlerts.tableDescription' | translate }}
  </div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" 
    matSort (matSortChange)="announceSortChange($event)"
    matTableExporter #exporter="matTableExporter">

      <!-- typeAlert Column -->
      <ng-container matColumnDef="typeAlert">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.typeAlert' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ getTypeLabel(element.typeAlert) }}</span>
        </td>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'customizableAlerts.description' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ getDescriptionLabel(element.typeAlert) }} {{element.operation}} {{element.value}}</span>
        </td>
      </ng-container>


      <!-- priority Column -->
      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.priority' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.priority === 0">{{ 'customizableAlerts.priorityOption.none' | translate }}</span>
          <span *ngIf="element.priority === 1">{{ 'customizableAlerts.priorityOption.low' | translate }}</span>
          <span *ngIf="element.priority === 2">{{ 'customizableAlerts.priorityOption.medium' | translate }}</span>
          <span *ngIf="element.priority === 3">{{ 'customizableAlerts.priorityOption.high' | translate }}</span>
        </td>
      </ng-container>


      <!-- dateCreate Column -->
      <ng-container matColumnDef="dateCreate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.dateCreate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
      </ng-container>

      <ng-container matColumnDef="operator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'customizableAlerts.operator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | operatorName}}</td>
      </ng-container>

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
        <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
          <button *ngIf="rol.canByInput(rol.functIds.delete_alerts)" matTooltip="{{ 'tooltips.delete' | translate }}" (click)="onDelete(element)" mat-icon-button class="iconoTabla delete-icon">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>


    <!-- Paginador -->
    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
  </div>

</main>