import { OnInit, Component} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { MonitoringService } from '../../views/monitoring/monitoring.service';
import { SearchMachineBean } from 'src/app/models/searchMachineBean.model';
import { CustosDualListComponent } from '../custos-dual-list/custos-dual-list.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { CommonModule } from '@angular/common';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

const FILTER_COLUMNS:{ field:string, label:string, type: FieldType }[] = [
  { field:'atentoID', type:'string', label:'vendingMachine.imei' },
  { field:'serialNumber', type:'string', label:'vendingMachine.terminalSN' },
  { field:'modelo', type:'string', label:'vendingMachine.model' },
  { field:'version', type:'string', label:'vendingMachine.terminalSW' },
  { field:'versionPinpad', type:'string', label:'vendingMachine.sWPinpad' },
  { field:'modeloPinpad', type:'string', label:'vendingMachine.modelPinpad' },
]

@Component({
  standalone:true,
  imports:[CommonModule,FilterComponent, CustosDualListComponent, TranslateModule, MatLegacyButtonModule],
  selector: 'app-select-machine-window',
  templateUrl: './select-machine-window.component.html',
  styleUrls: ['./select-machine-window.component.css']
})
export class SelectMachineWindowComponent implements OnInit {
  keepSorted = true;
  key: string ='id';
  display: string = 'serialNumber';
  filter = true;
  source: Array<any> = [];
  confirmed: Array<any> = [];
  userAdd = '';
  disabled = false;
  sourceLeft = true;
  format = { add: "Add", remove: 'Remove', all: 'All', none: 'None', 
            direction: CustosDualListComponent.LTR, draggable: true, locale: undefined };

  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, column.label, column.type);
    return fielterField;
  })

  request: SearchMachineBean = new SearchMachineBean();
  rf: FilterAndPagBean= new FilterAndPagBean("DESC", 'serialNumber', [],  null,  null, null);

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(private servicios : MonitoringService,
    private utilService: UtilService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectMachineWindowComponent>,
    public commonService: CommonService,
    private translate:TranslateService
  ) {}

  async lanzarLLamada(){
    this.request.filterAndPagBean =  this.rf;
    let value = await this.servicios.serviciosSearchMachineBean(this.request);
    console.log(JSON.stringify(value))
    if(value){
      this.source = value.data;     
    }
    this.confirmed = new Array<any>();
  }

  async cargar(){   
    //operadores
    this.utilService.findOperators().subscribe((operators:Operator[]) => {
        const options = operators.map((op:Operator)=>{ return new FilterOption(op.name, op.id as number)});
        const operationFilter = new FilterField('operatorID', 'vendingMachine.operator', 'string', options );
        this.filterColumns = [...[operationFilter]].concat(this.filterColumns);
      });
  }

  async ngOnInit(): Promise<void> {
    const isAdmin = await this.utilService.isAdmin();
    if (isAdmin) this.cargar();

    const idOperator = this.utilService.getOperatorId();
    if (idOperator) {
      this.setFilterOnRequesObj('operatorID', idOperator.toString());
      this.lanzarLLamada();
    }

  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    appliedFilters.forEach((appliedFilter)=>{
      const filter = appliedFilter.filter;
      this.setFilterOnRequesObj(filter.column, filter.value as string);
    });
    this.lanzarLLamada();
  }

  private setFilterOnRequesObj(column: string, value: string) {
    this.request = new SearchMachineBean;
    switch(column){
      case "operatorID" :
        this.request.operatorID = Number(value);
        break;
      case "atentoID" :
        this.request.atentoID = value;
        break;
      case "serialNumber" :
        this.request.serialNumber = value;
        break;
      case "modelo" :
        this.request.modelo = value;
        break;
      case "version" :
        this.request.version = value;
        break;
      case "versionPinpad" :
        this.request.versionPinpad = value;
        break;
      case "modeloPinpad" :
        this.request.modeloPinpad = value;
        break;
    }
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close({ result: FormCreateActions.EXIT});
          
  }

  onFormSubmit(): void { 
    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.selectMachineTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.selectMachineText');

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        this.dialogRef.close({ data: this.confirmed , result: FormCreateActions.SAVED});  
      }
    });
  }

}

