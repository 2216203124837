import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoveragePeriod } from 'src/app/models/coveragePeriod.model';
import { ApexAxisChartSeries, ApexChart, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, NgApexchartsModule } from 'ng-apexcharts';
import { parseStringToUTCDate } from 'src/app/util/util';
import { CoverageChartPeriod } from './coverage-chart-period.model';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis,
  yaxis: ApexYAxis,
  tooltip: ApexTooltip,
  stroke: ApexStroke,
};

@Component({
  selector: 'app-coverage-chart',
  standalone: true,
  imports: [CommonModule, NgApexchartsModule],
  templateUrl: './coverage-chart.component.html',
  styleUrls: ['./coverage-chart.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class CoverageChartComponent implements OnChanges {
  @Input() list:CoveragePeriod[] = [];
  options?:ChartOptions;
  
  coverage3GSeries: CoverageChartPeriod[] = [];
  coverage2GSeries: CoverageChartPeriod[] = [];
  coverage4GSeries: CoverageChartPeriod[] = [];

  ngOnChanges(): void {
    this.mapDataPerDay();
    this.initializeChartOptions();
  }


  private compareMin(previusValue:(number | null) = null, currentValue:(number | null) = null): number | null {
    return currentValue && (!previusValue || currentValue <= previusValue) ? currentValue : previusValue;
  }

  private compareMax(previusValue:(number | null) = null, currentValue:(number | null) = null): number | null {
    return currentValue && (!previusValue || currentValue >= previusValue) ? currentValue : previusValue;
  }

  private sumAverage(previusValue:(number | null) = null, currentValue:(number | null) = null): number | null {
    return currentValue && previusValue ? (currentValue+previusValue) : (currentValue ?? previusValue);
  }

  private set3GPeriodData(periodKey:string, period:string, previousPeriod:(CoverageChartPeriod|undefined), coveragePeriod:CoveragePeriod, mapListPerDay:Map<string,any>){
    mapListPerDay.set(periodKey, new CoverageChartPeriod('3G', period, 
      this.compareMin(previousPeriod?.min, coveragePeriod.min),
      this.compareMax(previousPeriod?.max, coveragePeriod.max),
      this.sumAverage(previousPeriod?.sumAverage, coveragePeriod.average),
      previousPeriod ? previousPeriod.totalRowsPerDay+1 : 1
    ));
  }

  private set2GPeriodData(periodKey:string, period:string, previousPeriod:CoverageChartPeriod|undefined, coveragePeriod:CoveragePeriod, mapListPerDay:Map<string,any>){
    mapListPerDay.set(periodKey, new CoverageChartPeriod('2G', period, 
      this.compareMin(previousPeriod?.min, coveragePeriod.min_2g),
      this.compareMax(previousPeriod?.max, coveragePeriod.max_4g),
      this.sumAverage(previousPeriod?.sumAverage, coveragePeriod.average_2g),
      previousPeriod ? previousPeriod.totalRowsPerDay+1 : 1
    ));
  }

  private set4GPeriodData(periodKey:string, period:string, previousPeriod:CoverageChartPeriod|undefined, coveragePeriod:CoveragePeriod, mapListPerDay:Map<string,any>){
    mapListPerDay.set(periodKey, new CoverageChartPeriod('4G', period, 
      this.compareMin(previousPeriod?.min, coveragePeriod.min_4g),
      this.compareMax(previousPeriod?.max, coveragePeriod.max_4g),
      this.sumAverage(previousPeriod?.sumAverage, coveragePeriod.average_4g),
      previousPeriod ? previousPeriod.totalRowsPerDay+1 : 1
    ));
  }

  private mapDataPerDay(){
    const mapListPerDay = new Map<string, CoverageChartPeriod>();
    this.coverage3GSeries = [];
    this.coverage2GSeries = [];
    this.coverage4GSeries = [];

    this.list.forEach((coveragePeriod:CoveragePeriod) =>{
      const periodDate = parseStringToUTCDate(coveragePeriod.period as string).toLocaleDateString('en');
      const periodKey = periodDate+'_'+coveragePeriod.lastType;
      const previousPeriod = mapListPerDay.get(periodKey); 

      switch (coveragePeriod.lastType){
        case '3G':
          this.set3GPeriodData(periodKey, periodDate, previousPeriod, coveragePeriod, mapListPerDay);
          break;
        case '2G':
          this.set2GPeriodData(periodKey, periodDate, previousPeriod, coveragePeriod, mapListPerDay);
          break;
        case '4G':
          this.set4GPeriodData(periodKey, periodDate, previousPeriod, coveragePeriod, mapListPerDay);
          break;
      }
    });

    mapListPerDay.forEach((groupPeriod)=>{
      switch (groupPeriod.type){
        case '3G':
          this.coverage3GSeries.push(groupPeriod)
          break;
        case '2G':
          this.coverage2GSeries.push(groupPeriod)
          break;
        case '4G':
          this.coverage4GSeries.push(groupPeriod)
          break;
      }
    })

  }

  initializeChartOptions(): void {
    this.options = {
      series: this.series,
      chart: {
        height: 350,
        type: "boxPlot",
        zoom:{
          enabled:false
        }
      },
      stroke: {
        curve: "straight"
      },
      xaxis: this.xAxisConfig,
      yaxis: this.yAxisConfig,
      tooltip: this.tooltipConfig,
    };
  }

  private get xAxisConfig(): ApexXAxis {
    return {
      type: 'category',
      labels: {
        formatter: function (val: string) {
          return new Date(val).toLocaleDateString();
        }
      }
    };
  }

  private get yAxisConfig(): ApexYAxis {
    return  {
      title: {
        text: "Cobertura (%)"
      },
      labels: {
        formatter: function (value: number) {
          return `${value.toFixed(0)}%`;
        }
      },
      min: 0,
      max: 100
    }
  }

  private get tooltipConfig(): ApexTooltip {
    return {
      shared: false,
      intersect: false,
      enabledOnSeries: [1,3,5]
    };
  }

  private get series():ApexAxisChartSeries{
    return[
      {
        name: '3G',
        type: 'line',
        color:'#008FFB',
        data: this.coverage3GSeries.map((item)=> item.getLineSeriesData())
      },
      {
        name: '3G',
        type: 'boxPlot',
        color:'#008FFB',
        data: this.coverage3GSeries.map((item)=> item.getBoxPlotSeriesData())
      },
      {
        name: '2G',
        type: 'line',
        color:'#FEB019',
        data: this.coverage2GSeries.map((item)=> item.getLineSeriesData())
      },
      {
        name: '2G',
        type: 'boxPlot',
        color:'#FEB019',
        data: this.coverage2GSeries.map((item)=> item.getBoxPlotSeriesData())
      },
      {
        name: '4G',
        type: 'line',
        color: '#00e396',
        data: this.coverage4GSeries.map(item => item.getLineSeriesData())
      },
      {
        name: '4G',
        type: 'boxPlot',
        color: '#00e396',
        data: this.coverage4GSeries.map((item)=> item.getBoxPlotSeriesData())
      },
    ];
  }
  
}


