

export enum TIPE_CARD {
    TIPE_CHIP_OK="emv_ok",
    TIPE_CLESS_OK="cless_ok",
    TIPE_KEY_OK="manual_ok",
    TIPE_BAND_OK="stripe_ok",
    TIPE_CHIP_KO="emv_err",
    TIPE_CLESS_KO="cless_err",
    TIPE_KEY_KO="manual_err",
    TIPE_BAND_KO="stripe_err"
}

export enum COLOR_TIPE_CARD {
  TIPE_CHIP_OK= "#B8860B",
  TIPE_CLESS_OK="#556B2F",
  TIPE_KEY_OK="#00008B",
  TIPE_BAND_OK="#2F4F4F",
  TIPE_CHIP_KO="#DAA520",
  TIPE_CLESS_KO="#808000",
  TIPE_KEY_KO="#ADD8E6",
  TIPE_BAND_KO="#778899"
}



export enum COMM_REPORT {
  COMM_OK= "primary_correct",
  COMM_KO="primary_error",
  SEC_COMM_OK="second_correct",
  SEC_COMM_KO="second_error",
}


export enum TICKET {
  PRINT="without",
  MAIL="by_sms",
  SMS="by_e-mail",
  NO="printed",
}

export enum CONSUME {
  TOTAL="total",
  READ="read",
  SENT="sent"
}