import { Component, Input } from '@angular/core';
import { MatLegacyTableModule, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { SelectMachineWindowComponent } from '../select-machine-window/select-machine-window.component';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';

enum Columns {//en el orden que se quiera mostrar
  view = 'view',
  customId = 'customId'
}

@Component({
  standalone:true,
  imports:[CommonModule, MatLegacyTableModule, TranslateModule, MatLegacyButtonModule, TableEmptyStateComponent],
  selector: 'app-select-machine-detail',
  templateUrl: './select-machine-detail.component.html',
  styleUrls: ['./select-machine-detail.component.css']
})
export class SelectMachineDetailComponent{
  @Input() dataSource = new MatTableDataSource<VendingMachineAtento>();
  

  displayedColumns =  Object.keys(Columns);  

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<SelectMachineWindowComponent>;

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService,
    private translate:TranslateService
  ) {}

  
  openWindows(){
    this.dialogRef = this.dialog.open(SelectMachineWindowComponent, {
      width: '90%', panelClass: 'custom-modalbox-big',
      data: {
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {

      if(result && result.result === 1) {        
        let dataSourceTemp  = new MatTableDataSource<VendingMachineAtento>(result.data );  
        
        if(dataSourceTemp){
          const objects: VendingMachineAtento[] = dataSourceTemp.data;
          if(objects){
            const data = this.dataSource.data;
            for (let [_, value] of Object.entries(objects)) {
              data.push(value);
              this.dataSource.data = data;
            }
           
          }
        }

      }
    });
  }


  onDelete(row: any){
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteMachineTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteMachineText');
    dialogData.textValue = row.name;
  
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const index = this.dataSource.data.indexOf(row);        
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription(); // <-- Refresh the datasource
      } 
    });

  }

}
