<div class="pagetitle">
  <h1>{{ data.terminalName }}</h1>
</div>

<mat-tab-group dynamicHeight selectedIndex="0" (selectedTabChange)="changeTab($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.info' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-info-terminal></app-info-terminal>
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.installedApps' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-installed-apps></app-installed-apps>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_sells)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sells' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-ventas></app-ventas>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_alerts)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.alerts' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-alerts></app-alerts>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_incidences)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.incidences' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-incidences></app-incidences>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.view_sensors)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.sensors' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-sensores></app-sensores>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.history)">
    <ng-template mat-tab-label>
      <span>{{ 'monitoring.tabs.userHistory' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-history-user></app-history-user>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="rol.canByInput(rol.functIds.pending_tasks)">
    <ng-template mat-tab-label>
      <span >{{'monitoring.tabs.pendingTasks' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-pending-tasks></app-pending-tasks>
    </ng-template>
  </mat-tab>
  
  <mat-tab *ngIf="rol.canByInputs([rol.functIds.send_file,rol.functIds.get_file,rol.functIds.commands,rol.functIds.view_file])">
    <ng-template mat-tab-label>
      <span >{{'monitoring.tabs.maintenance' | translate }}</span>
    </ng-template>
    <ng-template matTabContent>
      <app-tabs-mantenance></app-tabs-mantenance>
    </ng-template>
  </mat-tab>
</mat-tab-group>