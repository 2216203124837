import { FilterAndPagBean } from "./FilterAndPagBean";

export class ConsoleRequest {
  data!: any | undefined;
  userID: number | undefined;	
  filterAndPagBean: FilterAndPagBean | undefined;	

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
}




