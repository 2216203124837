<main id="main" class="main">

  <div class="pagetitle">
    <h1 i18n>Groups</h1>
    <div>
      <button mat-mini-fab (click)="onNew()">
        <i class="bi bi-plus-lg"></i>
      </button>
    </div>
  </div>


  <div id="tableDescription" style="display:none;" i18n>
    This table displays organized data about groups. Use the paginator to navigate through the pages
    </div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matTableExporter
      #exporter="matTableExporter">


      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><div i18n>Name</div>     </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- cod Column -->
      <ng-container matColumnDef="cod">
        <th mat-header-cell *matHeaderCellDef><div i18n>Code</div></th>
        <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
      </ng-container>

      <ng-container matColumnDef="operator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div i18n>Operator</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.idOperator | operatorName}}</td>
      </ng-container>

      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button" style=" width: 90px;">
          <div>
            <button matTooltip="delete" (click)="onDelete(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-trash"></i>
            </button>

            <button matTooltip="edit" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
              <i class="bi bi-pencil"></i>
            </button>

          </div>
        </td>
      </ng-container>





      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>

  </div>
</main>