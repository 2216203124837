import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class LoginService {

  
  constructor(
    private merchantRest : RestService,
    public commonService: CommonService) {

    }


    async login(_username: string,_pass: string){

      let _body = {"username":_username,"pass":_pass }
      let value = await this.merchantRest.commonRestCallAuthTMS(_body, 'login/',true,true)   
      .catch(err => {
        return null
      })

      return value;   
    }


      async getUserInfo(_username: any, token: string) {

        let user!: Users;
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);

        let value = await this.merchantRest.getCommonRestCallAuthTMS('getUser/'+_username,true,true,headers)   
        .catch(err => {
          return null
        })

        if(value?.data){
          user = value.data; 
        }
        return user;
    }

}