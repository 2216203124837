import { FilterAndPagBean } from "./FilterAndPagBean";

export class Aplications {
	
	id!:number;
	//idOperator!:number;
	name!:string; 
	dateCreate!: Date; 
	dateUpdate!: Date;
	description!: string;
	fileName!:string;
	base64!:string;
	pakage!:string;
	version!:string;
	
	package!:string;
	path!:string;
	storageType!:string;
	isLast!:boolean;
	idOperator?:number;
	operator?:string;
	
	filterAndPagBean: FilterAndPagBean | undefined;
}
