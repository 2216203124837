<main id="main" class="main">
    <div class="pagetitle">
        <h1>{{ 'clients.title' | translate }}</h1>
        <div>
            <button mat-raised-button class="custom-white-button" (click)="refrescar()">
                <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
            </button>
            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_clients)">
                <button mat-raised-button class="custom-add-button"  (click)="onNew()">
                    <i class="bi bi-plus"></i> {{ 'clients.newClient' | translate }}
                </button>
            </ng-container>
        </div>
    </div>
        
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden>{{ 'clients.tableDescription' | translate }}</div>

    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
         aria-describedby="tableDescription" matTableExporter #exporter="matTableExporter">

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'clients.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- cod Column -->
            <ng-container matColumnDef="cod">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.cod' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.cod}} </td>
            </ng-container>

            <!-- localidad Column -->
            <ng-container matColumnDef="localidad">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.localidad' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.localidad}} </td>
            </ng-container>
            

            <!-- personContact Column -->
            <ng-container matColumnDef="personContact">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.personContact' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.personContact}} </td>
            </ng-container>
            
            <!-- phone Column -->
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.phone' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>

            <!-- email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.email' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- web Column -->
            <ng-container matColumnDef="web">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.web' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"><a [href]="element.web" target="_blank"> {{element.web}} </a></td>
            </ng-container>
            
            <!-- cif Column -->
            <ng-container matColumnDef="cif">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ 'clients.cif' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.cif}} </td>
            </ng-container>

            <!-- operator Column -->
            <ng-container matColumnDef="operator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header>{{ 'clients.operator' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.operatorName }}</td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 90px;">
                    <div>
                        <ng-container *ngIf="rol.canByInput(rol.functIds.delete_clients)">
                            <button matTooltip="{{ 'tooltips.delete' | translate }}" (click)="onDelete(element)" mat-icon-button class="iconoTabla delete-icon">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </ng-container>
                        
                        <ng-container *ngIf="rol.canByInput(rol.functIds.edit_clients)">
                            <button matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
                                <mat-icon>chevron_right</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>
          
        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</main>