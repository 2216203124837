<main id="main" class="main">
    <div class="pagetitle">
        <h1 i18n>{{ 'profiles.profiles' | translate }}</h1>
        <div class="pagetitle-buttons">
            <button mat-raised-button class="custom-white-button" (click)="refrescar()">
                <i class="bi bi-arrow-clockwise"></i>  {{'common.refresh' | translate }}
            </button>

            <ng-container *ngIf="rol.canByInput(rol.functIds.edit_profiles)">
                <button mat-raised-button class="custom-add-button" (click)="onNew()">
                    <i class="bi bi-plus"></i> {{ 'profiles.new' | translate }}
                </button>
            </ng-container>
        </div>
    </div>

    <!-- Filtro de perfiles -->
    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div id="tableDescription" hidden> {{'profiles.tableDescription' | translate }} </div>
    
    <!-- Tabla de Perfiles -->
    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="tableDescription" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">
            
            <!-- Operator Column -->
            <ng-container matColumnDef="operator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'profiles.operator' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
            </ng-container>

            <!-- Columna Nombre -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
                    <div i18n>{{ 'profiles.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{ element.name }} </td>
            </ng-container>
            
            <!-- Columna Descripción -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef style="width:30%">
                    <div mat-sort-header i18n>{{ 'profiles.description' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate" style="width:30%"> {{ element.description }} </td>
            </ng-container>

            <!-- Columna Fecha Creación -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.dateCreate | dateOnTimeZone:true }}
                </td>
            </ng-container>
            <!-- Columna Fecha Actualización -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header i18n>{{ 'profiles.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncate"> {{ element.dateUpdate | dateOnTimeZone:true }}
                </td>
            </ng-container>

            <!-- Columna Dispositivos Asociados -->
            <ng-container matColumnDef="numMaquinas">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;">
                    <div mat-sort-header i18n>{{ 'profiles.associated' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" class="cell-truncat" style="width: 90px;"> {{ element.numMaquinas }} </td>
            </ng-container>

            <!-- Columna Código -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef style="width: 90px;">
                    <div mat-sort-header i18n>{{ 'profiles.code' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"  style="width: 90px;">
                    <div class="hidden-tag">{{ element.cod }}</div>
                    <button *ngIf="element.cod" mat-icon-button (click)="onQrCodeClick(element.cod)">
                        <i class="bi bi-qr-code"></i>
                    </button>
                </td>
            </ng-container>

            <!-- Columna Acciones (Editar) -->
            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
                    <ng-container *ngIf="rol.canByInput(rol.functIds.edit_profiles)">
                        <button matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Filas de la tabla -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <!-- Botón para exportar -->
        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>
        
        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
</main>