import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { CUSTOM_ALERTS_TYPES, FormCreateActions } from 'src/app/util/constants';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'angular-notifier';
import { AlertCongif } from 'src/app/models/alertConfig.model';
import { CustomAlertsService } from '../custom-alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-new-custom-alert',
  templateUrl: './form-new-custom-alert.component.html',
  styleUrls: ['./form-new-custom-alert.component.css']
})
export class FormNewCustomAlertComponent implements OnInit {

  registerForm: FormGroup;

  selectedOpe:Operator = new Operator();
  listaOpe:Operator[] = [];

  listaAlertTypes:{value:string, label:string}[] = Object.keys(CUSTOM_ALERTS_TYPES).map((type:string)=> ({
    label: this.translate.instant('cutomAlertType.'+CUSTOM_ALERTS_TYPES[type].toLowerCase()),
    value: type
  }));

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  private notifier: NotifierService;

 constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,   notifier: NotifierService ,  private services: CustomAlertsService,
    private utilServicios : UtilService,private authService:AuthService,
    private translate:TranslateService,
    private dialogRef: MatDialogRef<FormNewCustomAlertComponent>,
    public dialog: MatDialog) { 
      this.notifier = notifier;
      const formControls = {
        type: ["", Validators.required],
        operacion: ["", Validators.required],
        value: ["", [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        priority: [""]
      };
        const opeMarcadoControl = this.utilServicios.isAdmin() ? 
                                [null, Validators.required] : 
                                [{ value: this.utilServicios.getOperator(), disabled: true }, Validators.required];
  
      this.registerForm = this.formBuilder.group({
        ...formControls,  
        opeMarcado: opeMarcadoControl
      });
  }


  async cargar(){
    //operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      this.listaOpe = operators;
    });  
  }


  async ngOnInit(): Promise<void> {
   await this.cargar();
  }

  compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }


  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let objNew : AlertCongif = new AlertCongif();

    objNew.typeAlert=Number(data['type'])  
    objNew.operation=data['operacion']   
    objNew.value=data['value']   
    objNew.priority=data['priority']
    objNew.dateCreate = new Date
    
    let opeNew:Operator
    opeNew=data['opeMarcado']

    if(opeNew){
      objNew.idOperator=opeNew.id
    }
    let userSession = this.authService.currentUserValue();
    if(userSession?.operator) {   
      objNew.idOperator=userSession.operator?.id
    }

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.createCustomAlertTitle'); 
    dialogData.textI18n = this.translate.instant('confirmDialog.createCustomAlertText'); 

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let val = await this.services.addUpdate(objNew)
        if(val!.status>=0){
          this.notifier.notify('success', this.translate.instant('success.customAlertCreated'))            
          this.dialogRef.close(FormCreateActions.SAVED);
        }
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}


