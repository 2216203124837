import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PerfilesService } from '../perfiles.service';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { environment } from 'src/environments/environment';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { DynamicColumns, ParamExtra, RelatedExtra } from 'src/app/models/profileParamExtra.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

const BOOLEAN_OPTIONS:FilterOption[] = [
  { label:'Verdadero', value:true },
  { label:'Falso', value:false }
]

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'numSerie', type:'long' },
  { field:'usado', type:'boolean', options:BOOLEAN_OPTIONS },
]

@Component({
  standalone:true,
  imports:[CommonModule,  MatFormFieldModule,FormsModule, MatInputModule, MatLegacyButtonModule, TranslateModule, FilterComponent, MatPaginatorModule, TableEmptyStateComponent],
  selector: 'app-list-related-extra',
  templateUrl: './list-related-extra.component.html',
  styleUrls: ['./list-related-extra.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class ListRelatedExtraComponent implements OnInit {
  listRelatedExtra:RelatedExtra[] = [];
  public headerInputs: { fileName: string; storageType: string; path: string } | null = null;
  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  originalListRelatedExtra: RelatedExtra[] = [];
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    const filterField = new FilterField(
        column.field,
        column.field,
        column.type,
        column.options
    );
    return filterField;
});
  filterList: FilterableValue[] = [];
  dynamicColumns:string[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  constructor(
    public dialogRef: MatLegacyDialogRef<ListRelatedExtraComponent>, 
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { idProfile:number, idApp:number },
    private translate: TranslateService,
    private service:PerfilesService){
      this.headerInputs = {
        fileName: '',
        storageType: '',
        path: ''
      };
    }
    
    public async ngOnInit(): Promise<void> {
      const value = await this.loadParamExtra();
      if (value) {
          this.listRelatedExtra = this.mapDataToRelatedExtra(value.data);
          this.originalListRelatedExtra = [...this.listRelatedExtra];
          this.pageLength = value.data.length;
      }
      

  }

  private mapDataToRelatedExtra(paramsExtra: ParamExtra[]): RelatedExtra[] {
    return paramsExtra.map((param) => {
      const jsonColumns: DynamicColumns = JSON.parse(param.json);
      
      // Asignamos dinámicamente los nombres de las columnas
      if (this.dynamicColumns.length === 0) {
        // Filtramos para que no incluya 'terminalID'
        this.dynamicColumns = Object.keys(jsonColumns).filter(column => column !== 'terminalID');
      }
  
      const headers = JSON.parse(param.headers);
      this.headerInputs = {
        fileName: headers.fileName,
        storageType: headers.storageType,
        path: headers.path
      };
  
      return new RelatedExtra(param.numSerie, param.usado, jsonColumns);
    });
  }
  
  async loadParamExtra(){
    const filterAndPagBean = this.getFilterAndPagBean();
    return await this.service.getExtraParams(this.data.idProfile, this.data.idApp, filterAndPagBean);
  }

  getFilterAndPagBean(): FilterAndPagBean {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return new FilterAndPagBean(null,null,this.filterList,startPage,endPage,pageIndex);
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });

    // Aplicar filtros a la lista
    this.applyFilters();
  }

  private applyFilters() {
    if (this.filterList.length > 0) {
      this.listRelatedExtra = this.originalListRelatedExtra.filter((item) => {
        return this.filterList.every((filter) => {
          let fieldValue: string | boolean | undefined;

          // Determina cómo acceder a fieldValue según el filtro
          if (filter.column === 'numSerie') {
            fieldValue = item.numSerie; 
          } else if (filter.column === 'usado') {
            fieldValue = item.usado;
          }

          // Verificar si fieldValue no es undefined
          if (fieldValue === undefined) {
            return false;
          }

          // Filtrar según el tipo
          if (filter.type === 'boolean') {
            return fieldValue === filter.value; // Para 'usado'
          } else {
            return fieldValue.toString().includes(filter.value.toString());
          }
        });
      });
    } else {
      this.listRelatedExtra = [...this.originalListRelatedExtra]; // Restablecer a la lista original
    }
  }


  pageEvent(){
    this.loadParamExtra();
  }

  public cancelChanges() {
    this.dialogRef.close();
  }
}
