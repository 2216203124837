export class Rol {
  id: number | undefined;
  name!: string ;
  listFunction: RolFunction[] | undefined; 
  
  description!: string ;

}



export class RolFunction {
  id!: number; 
  status!: boolean;
  idFunction!: number;

}


export class Permisos {
  authserver:AuthserverPermisos | undefined;
  merchantsApi:MerchantApiPermisos | undefined;
  adminXiibero:XiiberoPermisos | undefined;
}


export class AuthserverPermisos {
  role:string | undefined;
  user:string | undefined;
  group:string | undefined;
}

export class MerchantApiPermisos {
  organization:string | undefined;
  merchant:string | undefined;
  terminal:string | undefined;
  notification:string | undefined;
  transaction:string | undefined;
}

export class XiiberoPermisos {
  organization:string | undefined;
  merchant:string | undefined;
  terminal:string | undefined;
  notification:string | undefined;
  transaction:string | undefined;
}

