import { Injectable } from "@angular/core";
import { LEVEL_HIERARCHY } from "../util/constants";
import { Users } from "../models/users.model";
import { AuthService } from "../auth/auth.service";

@Injectable({
    providedIn: 'root',
})
  
export class HierarchyHandlerService {
    level = LEVEL_HIERARCHY;
    
    constructor(private _auth:AuthService){}

    /**
     * @param user - Users     * 
     * Este método devuelve el nivel de jerarquía de un usuario.
     * @returns LEVEL_HIERARCHY
     */
    getLevel(user:Users): LEVEL_HIERARCHY {
        const idLevel1 = user.operator?.id;
        const idLevel2 = user.client?.id;
        const idLevel3 = user.subClient?.id;
        
        if(idLevel1 && !idLevel2 && !idLevel3) return LEVEL_HIERARCHY.LEVEL_1;
        if(idLevel1 && idLevel2 && !idLevel3) return LEVEL_HIERARCHY.LEVEL_2;
        if(idLevel1 && idLevel2 && idLevel3) return LEVEL_HIERARCHY.LEVEL_3;
        return LEVEL_HIERARCHY.ADMIN;
    }

    /**
     * @param levelId 
     * Este método valida si el usuario loggeado puede visualizar elementos 
     * según el nivel de jerarquía al que pertenece. 
     * @returns boolean
     */
    canByLevel(levelId:LEVEL_HIERARCHY): boolean {
        const userSession = this._auth.currentUserValue();
        const userLevel = this.getLevel(userSession);
        if(levelId > userLevel) return true
        return false;
    }

}