import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { Groups } from '../models/groups.model';
import { Operator } from '../models/operator.model';
import { AuthService } from '../auth/auth.service';
import { FirmwareUpload } from '../models/firmwareUpload.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetFile } from '../models/getFile';
import { LEVEL_ROL } from '../util/constants';
import { UsersConsoleRequest } from '../models/usersConsoleRequest.model';
import { Rol } from '../models/rol.model';
import { Client, SubClient } from '../models/client.model';

@Injectable({
  providedIn: 'root',
})
export class UtilService {

  private operators$: BehaviorSubject<any> = new BehaviorSubject([]);
  private rols$: BehaviorSubject<any> = new BehaviorSubject([]);


  constructor(
    private merchantRest : RestService, public commonService: CommonService, 
    private authService:AuthService
  ) {}

  async sendFile(_body:FirmwareUpload){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'setFileToSend/',true,true )   
    .catch(err => {
      return null
    })

    return value;      
  }

  async get(id: number) {
    let _body: GetFile = new GetFile();
    _body.id = id

    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'get/', true, true)
      .catch(err => {
        return null
      })

    return value;
  }

  async setGetFileAtento(file: GetFile) {
    let _body = file
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'setGetFileAtento/', true, true)
    .catch(err => {
      return null
    })

    return value;
  }

  async findGroups(){
    let _body: Groups = new Groups();
    let userSession = this.authService.currentUserValue();
    if (userSession?.entorno) {
        _body.idEntorno = userSession.entorno.id;
    }
    if (userSession?.operator) {
        _body.idOperator = userSession.operator.id;
    }
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListGroup/',true,true )   
    .catch(err => {
      return null
    })

    return value!.data;  
  }
    
    
  async updateFileMachine(_body:FirmwareUpload){
    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'setExcludeMachineFirmware/',true,true )   
    .catch(err => {
      return null
    })

    return value;      
  }


  public async getRolsAndOperators(): Promise<void> {
    let _body: UsersConsoleRequest = new UsersConsoleRequest();
    _body.idEntorno = this.getOperator()?.idEntorno;
    let value = await this.merchantRest.commonRestCallConsolaRest(_body, 'getRolesYOperadores/', true, true);
    if (value && value.data) {
        const { roles,  operatores } =  value.data;
        this.rols$.next(roles.sort((a:Rol,b:Rol)=>{ return (a.id as number) - (b.id as number) }));
        this.operators$.next(operatores);
    } else {
        console.warn('Received null or improperly structured response:', value);
        return;
    }
  }

  public cleanRolsAndOperators(){
    this.rols$.next([]);
    this.operators$.next([]);
  }

  public findRol(): Observable<any> {
    return this.rols$;
  }

  public findOperators(): Observable<any> {
    return this.operators$;
  }

  public isAdmin(): boolean {
    const userSession = this.authService.currentUserValue();
    return userSession?.rol !== undefined && userSession.rol.id === LEVEL_ROL.ADMIN;
  }

  public getOperatorId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.operator?.id;
  }

  public getOperator(): Operator | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.operator;
  }

  public getRolId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.rol?.id;
  }

  public getRol(): Rol | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.rol;
  }

  public getClientId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.client?.id;
  }

  public getClient(): Client | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.client;
  }

  public getSubClientId(): number | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.subClient?.id;
  }

  public getSubClient(): SubClient | undefined {
    const userSession = this.authService.currentUserValue();
    return userSession.subClient;
  }
}