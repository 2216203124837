export class CoverageChartPeriod {
    type:string;
    min:number | null;
    max:number | null;
    period:string;
    sumAverage:number | null;
    totalRowsPerDay:number;
 
 
    constructor(type:string, period:string, min:(number|null) = null, max:(number|null) = null, sumAverage:(number|null) = null, totalRows:number){
     this.type = type;
     this.period = period;
     this.min = min;
     this.max = max;
     this.sumAverage = sumAverage;
     this.totalRowsPerDay = totalRows;
    }
 
    private get average():number | null {
     return this.sumAverage ? this.sumAverage / this.totalRowsPerDay : null;
    }
 
    getBoxPlotSeriesData():{ x:string, y:(number|null)[] }{
     return {
       x: this.period,
       y: [ this.min, this.average, this.average, this.average, this.max ]
     }
    }
 
    getLineSeriesData():{ x:string, y:(number|null) }{
     return {
       x: this.period,
       y: this.average
     }
    }
 }