import { FilterAndPagBean } from "./FilterAndPagBean";

export class SearchMachineBean {
	provincia:string | undefined;
	comunidad:string | undefined;
	modelo:string | undefined;
	atentoID:string | undefined;
	serialNumber:string | undefined;
	version:string | undefined;
	commerce:string | undefined;
	operatorID: number | undefined;
	clientID: number | undefined;
	groupID: number | undefined;
	idEntorno: number | undefined;
	versionPinpad:string | undefined;
	modeloPinpad:string | undefined;

	filterAndPagBean!:FilterAndPagBean;
}



