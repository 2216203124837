<div class="edit-panel">
  <div class="header">
    <div class="title">{{ data.param.name }}</div>
    <div class="action-buttons">
      <ng-container *ngIf="!readOnly">
        <button mat-flat-button class="custom-add-button" (click)="onSave()" [disabled]="form.pristine || form.invalid">
          {{ 'common.save' | translate }}
        </button>
      </ng-container>
      <button class="close-button" (click)="onCancel()">×</button>
    </div>
  </div>

  <form [formGroup]="form">

    <!-- Details Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('details')">
        <label class="title-small">{{ 'common.details' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['details'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['details']">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'parameters.name' | translate }}</mat-label>
            <input matInput formControlName="name" placeholder="{{ 'parameters.placeholder.name' | translate }}"
              required>
            <mat-error *ngIf="form.get('name')?.hasError('required')">
              {{ 'error.required.field' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'parameters.description' | translate }}</mat-label>
            <textarea matInput formControlName="description" rows="4"
              placeholder="{{ 'parameters.placeholder.description' | translate }}" required></textarea>
              <mat-error *ngIf="form.get('description')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
              <mat-error *ngIf="form.get('description')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </fieldset>

    <!-- Dates Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('dates')">
        <label class="title-small">{{ 'parameters.date' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['dates'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['dates']">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'parameters.dateCreate' | translate }}</mat-label>
            <input matInput formControlName="dateCreate" type="text"
              placeholder="{{ 'parameters.dateCreate' | translate }}"> </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'parameters.dateUpdate' | translate }}</mat-label>
            <input matInput formControlName="dateUpdate" type="text"
              placeholder="{{ 'parameters.dateUpdate' | translate }}"> </mat-form-field>
        </div>
      </div>
    </fieldset>

    <!-- File Upload Section -->
    <fieldset class="edit-container bordered">
      <legend class="details-header" (click)="toggleSection('fileUpload')">
        <label class="title-small">{{ 'parameters.file' | translate }}</label>
        <mat-icon class="toggle-icon">{{ isOpen['fileUpload'] ? 'expand_less' : 'expand_more' }}</mat-icon>
      </legend>
      <div class="edit-content" *ngIf="isOpen['fileUpload']">
        <div *ngIf="selectedFile || form.get('fileName')?.value" class="selected-file-info">
          <p>{{ 'common.selectedFile' | translate }}: {{ selectedFile?.name || form.get('fileName')?.value }}</p>
          
          <div class="action-buttons">
            <ng-container>
              <button mat-icon-button (click)="downloadVersion()">
                <mat-icon>file_download</mat-icon>
              </button>
            </ng-container>

            <ng-container *ngIf="!readOnly && rol.canByInput(rol.functIds.delete_parameters)">
              <button mat-icon-button class="delete-button" (click)="deleteFile()">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </ng-container>
          </div>
          
        </div>
        <div class="file-upload-container">
          <div class="drop-zone" *ngIf="!selectedFile && !form.get('fileName')?.value" (drop)="onFileDrop($event)"
            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
            <div class="drop-zone-icon">
              <mat-icon>cloud_upload</mat-icon>
              <p>{{ 'common.selectFiles' | translate }}</p>
            </div>
          </div>
          <input type="file" class="file-upload" (change)="onFileSelected($event)" #fileUpload style="display: none;">
        </div>
      </div>
    </fieldset>

  </form>

  <div class="footer" *ngIf="!readOnly && rol.canByInput(rol.functIds.delete_parameters)">
    <button mat-stroke-button class="custom-white-button" (click)="onDelete()">
      <mat-icon class="delete-icon">delete_outline</mat-icon> {{ 'common.delete' | translate }}
    </button>
  </div>
</div>