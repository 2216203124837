import { Component, Inject, OnInit ,ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RolHandlerService } from 'src/app/services/rol-handler.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})
export class TabsComponent implements OnInit {
  isAdmin: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { terminalId:number, terminalName:string }, 
    private utilService: UtilService,
    public rol:RolHandlerService
  ){}

  tabIndex = 0;

  changeTab(event: { index: number; }){
    console.log(event.index)
    this.tabIndex = event.index;
  }


  ngOnInit(): void {
    this.isAdmin = this.utilService.isAdmin();
  }

}
