import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import { Parameters } from 'src/app/models/parameters.model';
import { PerfilApp, PerfilAppSelected } from 'src/app/models/perfilApp.model';
import { convertFileToBase64 } from 'src/app/util/util';


@Component({
  standalone:true,
  imports:[CommonModule, 
    MatLegacyFormFieldModule, 
    MatLegacySelectModule, 
    MatLegacyButtonModule,
    MatIconModule,
    TranslateModule
  ],
  selector: 'app-new-perfil-app',
  templateUrl: './new-perfil-app.component.html',
  styleUrls: ['./new-perfil-app.component.css']
})
export class NewPerfilAppComponent {
  @Input('data') selectedApp: PerfilAppSelected = new PerfilAppSelected;
  @Input() parameters: Parameters[] = [];
  @Output() remove = new EventEmitter();


  public removeApp(app: PerfilAppSelected) {
    this.remove.emit(app);
  }
  constructor(){}


  onParameterSelect(event: any, appSelect: PerfilAppSelected): void {
    const selectedParamId = event.target.value;
    appSelect.idParam = selectedParamId;
  }
  
  removeFileApp(): void {
    this.selectedApp.fileName = '';
    this.selectedApp.paramExtra = '';
  }

  // Drag and drop events
  onFilesDrop(event: any, appSelect: PerfilAppSelected) {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      this.onAppFileSelected({ target: { files: [file] } }, appSelect);
    }
  }

  async onAppFileSelected(event: any, appSelect: PerfilAppSelected): Promise<void> {
    const file = event.target.files[0];
    if (file) {
      appSelect.fileName = file.name;
  
      // Obtener la extensión del archivo y convertir a mayúsculas
      const fileExtension = file.name.split('.').pop()?.toUpperCase() || '';
      appSelect.typeParamExtra = (fileExtension === 'CSV' || fileExtension === 'ZIP') ? fileExtension : '';
  
      const convertedFile = await convertFileToBase64(file);
      appSelect.paramExtra = convertedFile as string;
    }
  }
  
  onDragOver(event: any) {
    event.preventDefault();
  }

  onDragLeave(event: any) {
    event.preventDefault();
  }
}
