import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { Rol } from 'src/app/models/rol.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';

@Injectable({
  providedIn: 'root',
})
export class RolService {
  constructor(
    private merchantRest: RestService,
    private authService: AuthService,
    public commonService: CommonService
  ) {}

  async find(filterAndPagBean: FilterAndPagBean) {
    let userSession = this.authService.currentUserValue();
    if (userSession) {
      filterAndPagBean.idEntorno = userSession.entorno?.id;
      filterAndPagBean.idOperator = userSession.operator?.id;
      filterAndPagBean.idClient = userSession.client?.id;
      filterAndPagBean.idSubClient = userSession.subClient?.id;
    }

    // Llamada a la API para obtener la lista de roles
    let value = await this.merchantRest.commonRestCallConsolaRest(filterAndPagBean, 'getListRol/', true, true)
      .catch(err => null);

    return value;
  }

  async countTotal(filterAndPagBean: FilterAndPagBean) {
    let userSession = this.authService.currentUserValue();
    if (userSession) {
      filterAndPagBean.idEntorno = userSession.entorno?.id;
      filterAndPagBean.idOperator = userSession.operator?.id;
      filterAndPagBean.idClient = userSession.client?.id;
      filterAndPagBean.idSubClient = userSession.subClient?.id;
    }

    let value = await this.merchantRest.commonRestCallConsolaRest(filterAndPagBean, 'countListRol/', true, true)
      .catch(err => null);

    return value;
  }

  async addRol(rol: Rol) {
    let value = await this.merchantRest.commonRestCallConsolaRest(rol, 'addRol/', true, true)
      .catch(err => null);

    return value;
  }

  async updateRol(rol: Rol) {
    const value = await this.merchantRest.commonRestCallConsolaRest(rol, 'addRol/', true, true)
      .catch(err => null);
  
    return value;
  }

  async deleteRol(rolId: number) {
    const body = { id: rolId };  
    let value = await this.merchantRest.commonRestCallConsolaRest(body, 'deleteRol/', true, true)
      .catch(err => null);

    return value;
  }
}
