import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData, ConfirmDialogActions } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FileInfoGet, GetFile } from 'src/app/models/getFile';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { UtilService } from 'src/app/services/util.service';
import { FILE_TYPES } from 'src/app/util/constants';

@Component({
  selector: 'app-get-file',
  templateUrl: './get-file.component.html',
  styleUrls: ['./get-file.component.css']
})
export class GetFileComponent implements OnInit {
  @Input('data') vendingMachine!:VendingMachineAtento;
  hasVendingMachineSelected:boolean = false;
  fileTypes = FILE_TYPES;
  firstFormGroup!: FormGroup;
  selectedVendingMachines = new MatLegacyTableDataSource<VendingMachineAtento>();

  isCloseFile: boolean = false;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private service: UtilService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private notifier: NotifierService,
    private translate:TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
    this.firstFormGroup = this.formBuilder.group({
      storageType: ['', Validators.required], 
      packageName: ['', Validators.required], 
      path: ['', Validators.required], 
      fileName: ['', Validators.required], 
    });

    if(this.vendingMachine){
      this.selectedVendingMachines.data.push(this.vendingMachine);
      this.hasVendingMachineSelected = true
    }
  }

  onFormSubmit(): void {
    if (!this.selectedVendingMachines.data.length) {
      this.notifier.notify('warning', this.translate.instant('warning.terminalIsMissing'));
      return;
    }

    if (this.firstFormGroup.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    const { fileName, packageName, path, storageType } = this.firstFormGroup.value;
    const fichero:FileInfoGet = new FileInfoGet
    fichero.fileName=fileName
    fichero.packageName=packageName
    fichero.path=path
    fichero.storageType=storageType

    const objNew : GetFile = new GetFile
    objNew.listFile = [fichero];

    objNew.machines = this.selectedVendingMachines.data.map((vm)=>{ 
      const vendingMachine:VendingMachineAtento = new VendingMachineAtento;
      vendingMachine.id = vm.id;
      return vendingMachine;
    });

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.saveFileTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.saveFileText');


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
          console.log('send element:', objNew);
          const val = await this.service.setGetFileAtento(objNew)          
          if(val!.status>=0){
            this.notifier.notify('success', this.translate.instant('success.fileSaved'));
          }
      }
    });
  }
}
