
<div class="pagetitle">
    <h1>{{ 'vendingMachine.selector' | translate }}</h1>      
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
  
<app-custos-dual-list [sort]="keepSorted" [source]="source" [key]="key" [display]="display" [filter]="filter"
[destination]="confirmed" height="265px" [format]="format" [disabled]="disabled"></app-custos-dual-list>

<div class="botonera mt-3">
    <button  mat-stroke-button class="custom-white-button" (click)="formExit()">{{ 'common.cancel' | translate }}</button>
    <button  mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.confirm' | translate }}</button> 
</div>
    
      