import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectChange, MatLegacySelectModule } from "@angular/material/legacy-select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Parameters } from "src/app/models/parameters.model";
import { PerfilApp, PerfilAppSelected } from "src/app/models/perfilApp.model";
import { NewPerfilAppComponent } from "../new-perfiles/new-perfil-app/new-perfil-app.component";
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from "src/app/models/FilterAndPagBean";
import { AppsService } from "../../aplications/aplications.service";
import { AuthService } from "src/app/auth/auth.service";
import { ParamService } from "../../param/param.service";
import { NotifierService } from "angular-notifier";
import { Profile } from "src/app/models/profiles.model";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { PerfilesService } from "../perfiles.service";
import { Aplications } from "src/app/models/aplications.model";

@Component({
  standalone:true,
  imports:[CommonModule, 
    TranslateModule, 
    MatLegacyButtonModule, 
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    NewPerfilAppComponent
  ],
  selector: 'app-edit-apps',
  templateUrl: './edit-apps.component.html',
  styleUrls: ['./edit-apps.component.css']
})
export class EditAppsComponent implements OnInit{
  public resultListApp:PerfilAppSelected[] = [];
  public parameters: Parameters[] = [];
  public appsBBDD:any[] = [];
  public versionBBDD:any[] = [];

  constructor(public dialogRef: MatDialogRef<EditAppsComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile }, 
    private dialog:MatLegacyDialog,
    private appsService:AppsService,
    private paramService:ParamService,
    private authService:AuthService,
    private profileService:PerfilesService,
    private notifier:NotifierService,
    private translate:TranslateService
  ){}

  public ngOnInit(): void {
    this.loadAvailableApps();
    this.loadParameters();
  }

  public cancelChanges(){
    this.dialogRef.close();
  }

  public submitChanges(){
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.titleI18n = this.translate.instant('confirmDialog.updateProfileTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.updateProfileText');

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
      width: '35%', panelClass: 'custom-modalbox',
      data:dialogData
    });

    confirmSave.afterClosed().subscribe((response:ConfirmDialogActions) => {
      if (response === ConfirmDialogActions.CONFIRM) this.updateProfile();
    });
  }

  private mapToPerfilAppFromSelected(resultListApp:PerfilAppSelected[]): PerfilApp[]{
    return resultListApp.map((app)=> app.mapToPerfilApp());
  }

  public async updateProfile(){
    const profile = {...this.data.profile};
    profile.listApp = [ ...profile.listApp ?? [], ...this.mapToPerfilAppFromSelected(this.resultListApp)]
    const response = await this.profileService.saveApps(profile);
    if(response!.status >= 0 ) this.dialogRef.close({ result:1 });
  }

  public removeApp(app: PerfilApp): void {
    this.resultListApp = this.resultListApp.filter(a => a.idApp !== app.idApp);
  }

  private async loadAvailableApps(): Promise<void> {
    // Limpiar la lista de aplicaciones antes de cargar nuevas
    this.appsBBDD = [];
    const filterList = []
    // Obtenemos la sesión del usuario actual y agregamos el filtro de 'idOperator' si existe
    let userSession = this.authService.currentUserValue();
    if (userSession?.operator) filterList.push(new FilterableValue("idOperator", userSession.operator.id + "", "long", OPERATION_FILTER.EQUALS));
    filterList.push(new FilterableValue("isLast", "true", "boolean", OPERATION_FILTER.EQUALS));
    const request = new FilterAndPagBean(null,null,filterList,null,null,null);
    const result = await this.appsService.find(request);
  
    if (result?.status === 0) this.appsBBDD = result.data;
  }

  private async loadParameters(): Promise<void> {
    const result = await this.paramService.find({} as any);
    if (result?.status === 0) {
      this.parameters = result.data;
    }
  }

  public async onAppSelect(event: MatLegacySelectChange): Promise<void> {
    let selectedApp = event.value;

    const exists = this.existsApplication(selectedApp);
    if (exists) return;
       
    this.versionBBDD = [];
    const filterList = [];
    filterList.push(new FilterableValue("pakage", selectedApp.pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS));
    const request = new FilterAndPagBean(null,null,filterList,null,null,null);
    const result = await this.appsService.find(request);
    if (result?.status === 0) this.versionBBDD = result.data;
  }

  public onVersionSelect(event: MatLegacySelectChange) {
    let version = event.value;
    
    const exists = this.existsApplication(version);
    if (exists) return;

    let perfilApp: PerfilAppSelected = new PerfilAppSelected();
    perfilApp.idApp = version.id;
    perfilApp.appName = version.name;
    perfilApp.version = version.version;
    perfilApp.pakage = version.pakage;
    this.resultListApp.push(perfilApp);
  }

  private existsApplication(appToAdd:Aplications):boolean{
    const exists = this.resultListApp.some(app => app.pakage === appToAdd.pakage);
    if(exists) this.notifier.notify('warning', 'No es posible agregar 2 veces una aplicación');
    return exists;
  }
}
