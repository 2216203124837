import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Alerts } from 'src/app/models/alerts.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { AlertsService } from './alerts.service';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { StadisticBean } from 'src/app/models/stadisticBean.model';
import { UtilService } from 'src/app/services/util.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { Operator } from 'src/app/models/operator.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

const BaseColumns = {  
  terminal: 'nameMachineID',
  dateCreate: 'dateCreate',
  operator: 'operator',
  cod: 'cod', 
  description:'description', 
  priority: 'priority', 
  status: 'status',
  view: 'view'
}
const { operator , ...Columns } = BaseColumns;
const ColumnsAdmin= { ...BaseColumns };  

const SATUS_OPTIONS:FilterOption[] = [
  { value:"0", label: "Finalized" },
  { value:"1", label: "Active" },
  { value:"2", label: "Undefined" },
]

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'nameMachineID', type:'string' },
  { field:'cod', type:'string' },
  { field:'description', type:'string' },
  { field:'status', type:'string', options:SATUS_OPTIONS},
  { field:'dateCreate', type:'date' },
]

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class AlertsComponent implements OnInit, AfterViewInit {
  private dialogRef!: MatDialogRef<TabsComponent>;
  displayedColumns =  Object.keys(Columns);
  isOnTerminalContext:boolean = false;
  dataSource = new MatTableDataSource<Alerts>();
  searchTerminal = "";
  isAdmin: boolean = true; 
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate+"" ;
  direccion:string | null ="DESC";
  exportFileName: string | undefined;


  request: StadisticBean = new StadisticBean();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `alerts.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { terminalId:number },
      private servicios : AlertsService,
      private utilService : UtilService,
      public dialog: MatDialog,
      private authService:AuthService,
      private translate: TranslateService,
      public commonService : CommonService,
      public rol:RolHandlerService,
      private monitoringService : MonitoringService) {  

  }

  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada(){
    const idVMFilter = this.getFilterableValue("idVM");
    const idOperatorFilter = this.getFilterableValue("idOperator");

    if(this.data.terminalId && !idVMFilter){
      this.isOnTerminalContext = true;
      const idVM = this.data.terminalId;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }

    let userSession = this.authService.currentUserValue();
    if(userSession?.operator && !idOperatorFilter){
      this.filterList.push(new FilterableValue("idOperator", `${userSession.operator.id}`, "long", "EQUALS"));
    }

    const paginatorState = this.getPaginatorState();
    this.request.filterAndPagBean =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    this.request.customId = this.searchTerminal;
    let value = await this.servicios.find(this.request);
    let pagCount = await this.servicios.countTotal(this.request);
    if(value){
      this.dataSource = new MatTableDataSource<Alerts>(value.data);
      if(pagCount){
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }

  async cargar(){   
    //operadores
    this.utilService.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'stadisticsFunctionally.idOperator', 'number', operationsOptions));
    });
  }

  async ngOnInit(): Promise<void> {
    this.isAdmin = await this.utilService.isAdmin();
    this.exportFileName = this.translate.instant('export.fileAlert');
    if (this.isAdmin) {
      this.displayedColumns = Object.keys(ColumnsAdmin);
      this.cargar();
    }
 
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(){
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  onView(row: any): void {
    this.monitoringService.getMachine(row.idVM).then(response => {
      if(!response) return;

      const terminal = response.data;
      this.dialogRef = this.dialog.open(TabsComponent, {
        width: '90%',
        height: '95%',
        panelClass: 'custom-modalbox-big',
        data: { terminalId: terminal.id, terminalName:terminal.customId }
      });

      this.dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          console.log('CONFIRM received from dialog window');
        } else if (result === 0) {
          console.log('CANCEL received from dialog window');
        }
      });
    }).catch(error => {
      console.error('Error fetching data: ', error);
    });
  }
}

