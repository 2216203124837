<div id="tableDescription" style="display:none;">Tabla que muestra las aplicaciones relacionadas al perfil</div>
<table class="table related-apps-table" aria-labelledby="tableDescription">
    <thead>
      <tr>
        <th scope="col">Aplication</th>
        <th scope="col">Parametro</th>
        <th scope="col">Extra</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody class="related-apps-tbody">
      <tr *ngFor="let app of relatedApps; let i = index">
        <td class="related-apps-cells"><a class="reditect-tag" (click)="openAppDetail(app)">{{ app.appName ?? '' }} ({{ app.appVersion ?? '' }})</a></td>
        <td class="related-apps-cells"><a class="reditect-tag" (click)="openParamDetail(app)">{{ app.paramName ?? '' }}</a></td>
        <td class="related-apps-cells">
            <ng-container *ngIf="app.paramExtra">
                <button mat-icon-button (click)="openParamExtra(app)">
                    <mat-icon>attachment</mat-icon>
                </button>
            </ng-container>
        </td>
        <td class="related-apps-cells">
          <ng-container *ngIf="rol.canByInput(rol.functIds.delete_profiles)">
            <button mat-icon-button (click)="deleteItem(i)">
              <mat-icon mat-list-icon class="delete-icon">delete_outline</mat-icon>
            </button>
          </ng-container>
        </td>
      </tr>
    </tbody>
</table>

<!-- Empty state -->
<ng-container *ngIf="!relatedApps.length">
  <app-table-empty-state [icon]="false"></app-table-empty-state>
</ng-container>