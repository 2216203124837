import { Component, OnInit,Inject,ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { Pending } from 'src/app/models/pending.model';
import { environment } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NotifierService } from 'angular-notifier';
import { IncidenceService } from './incidence.service';
import { Incidence } from 'src/app/models/incidence.model';
import { FormNewIncidenceComponent } from './form-new-incidence/form-new-incidence.component';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

enum Columns {//en el orden que se quiera mostrar
  serialNumber = 'serialNumber',
  type = 'type',
  dateCreate = 'dateCreate', 
  description = 'description',
  userCreate = 'userCreate',
  resolution = 'resolution',
  dateDelete = 'dateDelete',
  userDelete = 'userDelete',
  view = 'view',
}

const TYPE_OPTIONS:FilterOption[] = [
  { value:"0", label: "Other" },
  { value:"1", label: "Suggestion" },
  { value:"2", label: "Telemetry failure" },
  { value:"3", label: "Terminal Breakdown" },
  { value:"4", label: "Non-active terminal" },
]

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'serialNumber', type:'string' },
  { field:'resolution', type:'string' },
  { field:'userCreate', type:'string' },
  { field:'description', type:'string' },
  { field:'type', type:'string', options:TYPE_OPTIONS},
  { field:'dateCreate', type:'date' },
]

@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class IncidencesComponent implements OnInit, AfterViewInit  {
  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Pending>();

  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId:string | null = Columns.dateCreate+"" ;
  direccion:string | null ="DESC";
  exportFileName: string | undefined;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `incidences.${column.field}`, column.type, column.options);
    return fielterField;
  })
  filterList: FilterableValue[] = [];

  private dialogRef!: MatDialogRef<FormNewIncidenceComponent>;
  private dialogMonitoringRef!: MatDialogRef<TabsComponent>;
  isOnTerminalContext:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ terminalId:number }, 
    private notifier: NotifierService , 
    private servicios : IncidenceService,
    private monitoringService : MonitoringService,
    public dialog: MatDialog,
    public commonService : CommonService,
    public rol:RolHandlerService,
    private translate:TranslateService
  ) {}
    
  private getFilterableValue(column:string): FilterableValue | undefined {
    return this.filterList.find((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const idVMFilter = this.getFilterableValue("idVM");   
    if(this.data.terminalId && !idVMFilter){
      this.isOnTerminalContext = true;
      const idVM = this.data.terminalId;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", "EQUALS"));
    }

    const paginatorState = this.getPaginatorState();
    const request =  new FilterAndPagBean(
      this.direccion, 
      this.sortId, 
      this.filterList, 
      paginatorState.startPage,
      paginatorState.endPage,
      paginatorState.pageIndex
    );

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    
    if (value) {
      this.dataSource = new MatTableDataSource<Pending>(value.data);
      if (pagCount) this.pageLength = pagCount.data?.total ?? 0;
    }
  }

  private getPaginatorState(){
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize:this.pageSize };
    const startPage = pageIndex*pageSize;
    const endPage = startPage+pageSize;

    return { startPage, endPage, pageIndex };
  }
  
  ngOnInit(): void {
    this.exportFileName = this.translate.instant('export.fileIncidence');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.lanzarLLamada();
  }
    
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent(){
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  async onView(row: any){
    this.monitoringService.getMachine(row.idVM).then((response)=>{
      if(!response) return;
      
      const terminal = response?.data;
      this.dialogMonitoringRef = this.dialog.open(TabsComponent, {
        width: '90%', height:'95%', panelClass: 'custom-modalbox-big',
        data: { terminalId: terminal.id, terminalName:terminal.serialNumber }
      });
  
      this.dialogMonitoringRef.afterClosed().subscribe((result) => {
        if (result === 1) {        
          this.lanzarLLamada();
        }
      });
    });


  }

  async getMachine(idVM:number): Promise<any> {
    return await this.monitoringService.getMachine(idVM);
  }


  async onEdit(row:Incidence) {
    const terminal = await this.getMachine(row.idVM); 
    if(!terminal) return;

    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '600px', panelClass: 'custom-modalbox-big',
      data: { incidencia:row,vm:terminal.data,close:false }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant('success.incidenceSave')) 
        this.lanzarLLamada();
      }
    });
  }

  async onClose(row:Incidence) {
    const terminal = await this.getMachine(row.idVM); 
    if(!terminal) return;

    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '800px', panelClass: 'custom-modalbox-big',
      data: { incidencia:row,vm:terminal.data,close:true }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant('success.incidenceClosed')) 
        this.lanzarLLamada();
      }
    });
  }

  async onNew() {
    let terminalData = null;
    if(this.data.terminalId){
      const terminal = await this.getMachine(this.data.terminalId); 
      if(terminal) terminalData = terminal.data;
    }
    
    this.dialogRef = this.dialog.open(FormNewIncidenceComponent, {
      width: '600px', panelClass: 'custom-modalbox-big',
      data: { vm:terminalData }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant('success.incidenceSave')) 
        this.lanzarLLamada();
      }
    });
  }

}
