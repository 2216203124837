<main id="main" class="main">
    <div class="pagetitle">
        <h1 > {{ 'aplications.aplications' | translate }} </h1>
        <div>
            <button mat-raised-button class="custom-white-button" (click)="refrescar()">
                <i class="bi bi-arrow-clockwise"></i>  {{'common.refresh' | translate }}
            </button>
            <button *ngIf="rol.canByInput(rol.functIds.edit_applications)" mat-raised-button class="custom-add-button" (click)="onNew()">
                <i class="bi bi-plus"></i>{{ 'aplications.newAplication' | translate }}
            </button>
        </div>
    </div>

    <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

    <div class="mat-elevation-z2 tablaPersonal">
        <table mat-table [dataSource]="dataSource" aria-describedby="" matSort
            (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">            

            <!-- Operator Column -->
            <ng-container matColumnDef="operator">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'aplications.operator' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.operator}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'aplications.name' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- dateCreate Column -->
            <ng-container matColumnDef="dateCreate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'aplications.dateCreate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateCreate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- dateUpdate Column -->
            <ng-container matColumnDef="dateUpdate">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'aplications.dateUpdate' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.dateUpdate | dateOnTimeZone:true }}</td>
            </ng-container>

            <!-- Package Column -->
            <ng-container matColumnDef="pakage">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'aplications.package' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.pakage}} </td>
            </ng-container>
            
            <!-- Version Column -->
            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>
                    <div mat-sort-header >{{ 'aplications.version' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.version}} </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef style="width:30%">
                    <div mat-sort-header >{{ 'aplications.description' | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element" style="width:30%"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="view">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
                <td mat-cell *matCellDef="let element" class="button" style="width: 60px;">
                    <button *ngIf="rol.canByInput(rol.functIds.edit_applications)" matTooltip="{{ 'common.view' | translate }}" (click)="onEdit(element)" mat-icon-button class="iconoTabla">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
        <!-- Empty state -->
        <ng-container *ngIf="!dataSource.data.length">
            <app-table-empty-state></app-table-empty-state>
        </ng-container>

        <!-- Botón para exportar -->
        <button id="export" class="icoDownload" mat-icon-button
            (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
            <i class="bi bi-file-earmark-arrow-down"></i>
        </button>

        <!-- Paginador -->
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>


    </div>
</main>