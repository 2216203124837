import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Factories } from 'src/app/models/factories.model';
import { InputFilesComponent } from 'src/app/components/input-files/input-files.component';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { NotifierService } from 'angular-notifier';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FactoriesService } from 'src/app/services/factories.service';
import { SelectMachineWindowComponent } from 'src/app/pages/comun/select-machine-window/select-machine-window.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { convertFileToJson } from 'src/app/util/util';
import { TerminalFromFile } from 'src/app/models/terminalFromFile.model';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

interface InvalidFactoryItem {
  factory:string;
  model:string;
}

@Component({
  selector: 'app-terminals-selector',
  standalone: true,
  imports: [
    CommonModule, 
    InputFilesComponent, 
    MatButtonModule,
    MatIconModule,
    MatLegacyTooltipModule, 
    FormsModule, 
    TranslateModule
  ],
  templateUrl: './terminals-selector.component.html',
  styleUrls: ['./terminals-selector.component.css']
})
export class TerminalsSelectorComponent implements OnInit{
  @Input() machines:VendingMachineAtento[] = [];
  @Output() machinesChange = new EventEmitter<VendingMachineAtento[]>();
  
  showDropZone:boolean = false;
  hasUploadAFile:boolean = false;
  hasSelectedFromPortal:boolean = false;
  
  factories =  new Factories([]);
  invalidFactories:InvalidFactoryItem[] = [];

  constructor(
    private dialog:MatLegacyDialog, 
    private notifier:NotifierService,
    private translate:TranslateService,
    private factoriesService:FactoriesService
  ){}

  ngOnInit(): void {
    this.loadFactories();
  }

  async loadFactories(){
    const response = await this.factoriesService.getFactoriesList();
    if(response && response.data) this.factories = new Factories(response.data);
  }

  openSelectMachine(): void {
    const dialogRef = this.dialog.open(SelectMachineWindowComponent, {
        width: '90%', panelClass: 'custom-modalbox-big'
    });

    dialogRef.afterClosed().subscribe((response) => {
        console.log("Datos recibidos del portal:", response.data); // Verifica aquí
        if (response?.result === 1) {
            const validMachines = response.data.map((machine: VendingMachineAtento) => ({ id:machine.id, idAtento:machine.idAtento }));
            this.showDropZone = false;
            this.hasSelectedFromPortal = true;
            this.invalidFactories = [];
            this.machines = [...validMachines];
            this.machinesChange.emit(this.machines);
        }
    });
  }

  async parseFileData(file:File, invalidTerminals:InvalidFactoryItem[]){
    const parsedFileData: { [key: string]: string }[] = await convertFileToJson(file);
    // Procesar cada línea del archivo
    parsedFileData.forEach((fileData) => {
      const { numero_serie, fabricante, modelo } = fileData;
      const terminalFromFile = new TerminalFromFile(numero_serie,fabricante,modelo);
      if(!terminalFromFile.isTerminalDataComplete()) return;
        
      if(this.factories.isAValidFactoryAndModel(fabricante, modelo)){
        const vendingMachine = terminalFromFile.mapToTerminal();
        this.machines.push(vendingMachine);
      } else {
        invalidTerminals.push({ factory:fabricante, model: modelo });
      }
    });
  }

  async onFilesSelected(files: File[]): Promise<void> {
    if(!files.length) return;

    this.machines = []; // Resetea las máquinas al cargar un nuevo archivo
    this.invalidFactories = []
    await Promise.all(
      files.map(async (file) => {
        await this.parseFileData(file,this.invalidFactories);
      })
    );

    // Verificar el conteo de terminales después de procesar el archivo
    if (this.machines.length === 0) {
        this.notifier.notify('error', this.translate.instant('warning.noValidTerminals'));
        return;
    } 

    if(this.invalidFactories.length != 0) {
      this.notifier.notify('error', this.translate.instant('warning.importValidFactories'));
      return;
    }

    this.hasUploadAFile = true;
    this.machinesChange.emit(this.machines);  
    this.notifier.notify('success', this.translate.instant('success.importValidTerms', { value: this.machines.length }));    
  }


}
