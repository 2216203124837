import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Rol } from 'src/app/models/rol.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { RolService } from '../rol.service';
import { FUNCTION_IDS } from 'src/app/util/constants';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-form-new-rol',
  templateUrl: './form-new-rol.component.html',
  styleUrls: ['./form-new-rol.component.css']
})
export class FormNewRolComponent implements OnInit {
  registerFormStep1!: FormGroup;
  registerFormStep2!: FormGroup;

  listaRol: Rol[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  rolForm!: FormGroup;
  rol: Rol | null = null;
  currentStep: number = 0; // Controlador de paso actual


  permissions = [
    {
      category: 'Monitorizacion',
      items: [
        { name: 'edit_terminals', label: 'roles.permissions.edit_terminalsg', icon: 'visibility' },
        { name: 'view_terminals', label: 'roles.permissions.view_terminals', icon: 'visibility' },
        { name: 'view_sells', label: 'roles.permissions.view_sells', icon: 'visibility' }
      ]
    },
    {
      category: 'Estadisticas',
      items: [
        { name: 'view_statistics', label: 'roles.permissions.view_statistics', icon: 'visibility' },
        { name: 'view_functionality', label: 'roles.permissions.view_functionality', icon: 'visibility' },
        { name: 'view_status', label: 'roles.permissions.view_status', icon: 'visibility' }
      ]
    },
    {
      category: 'Soporte',
      items: [
        { name: 'view_support', label: 'roles.permissions.view_support', icon: 'visibility' },
        { name: 'view_alerts', label: 'roles.permissions.view_alerts', icon: 'visibility' },
        { name: 'edit_alerts', label: 'roles.permissions.edit_alerts', icon: 'edit' },
        { name: 'delete_alerts', label: 'roles.permissions.view_alerts', icon: 'delete' },
        { name: 'view_incidences', label: 'roles.permissions.view_incidences', icon: 'visibility' },
        { name: 'edit_incidences', label: 'roles.permissions.edit_incidences', icon: 'edit' }
      ]
    },
    {
      category: 'Configuracion',
      items: [
        { name: 'view_applications', label: 'roles.permissions.view_applications', icon: 'visibility' },
        { name: 'edit_applications', label: 'roles.permissions.edit_applications', icon: 'edit' },
        { name: 'delete_applications', label: 'roles.permissions.delete_applications', icon: 'delete' },
        { name: 'view_parameters', label: 'roles.permissions.view_parameters', icon: 'visibility' },
        { name: 'edit_parameters', label: 'roles.permissions.edit_parameters', icon: 'edit' },
        { name: 'delete_parameters', label: 'roles.permissions.delete_parameters', icon: 'delete' },
        { name: 'view_profiles', label: 'roles.permissions.view_profiles', icon: 'visibility' },
        { name: 'edit_profiles', label: 'roles.permissions.edit_profiles', icon: 'edit' },
        { name: 'delete_profiles', label: 'roles.permissions.delete_profiles', icon: 'delete' },
        { name: 'view_custom_alerts', label: 'roles.permissions.view_custom_alerts', icon: 'visibility' },
        { name: 'delete_custom_alerts', label: 'roles.permissions.delete_custom_alerts', icon: 'delete' },
        { name: 'view_pending_tasks', label: 'roles.permissions.view_pending_tasks', icon: 'visibility' },
        { name: 'delete_pending_tasks', label: 'roles.permissions.delete_pending_tasks', icon: 'delete' }
      ]
    },
    {
      category: 'Administrador',
      items: [
        { name: 'view_users', label: 'roles.permissions.view_users', icon: 'visibility' },
        { name: 'edit_users', label: 'roles.permissions.edit_users', icon: 'edit' },
        { name: 'delete_users', label: 'roles.permissions.delete_users', icon: 'delete' },
        { name: 'view_clients', label: 'roles.permissions.view_clients', icon: 'visibility' },
        { name: 'edit_clients', label: 'roles.permissions.edit_clients', icon: 'edit' },
        { name: 'delete_clients', label: 'roles.permissions.delete_clients', icon: 'delete' },
        { name: 'view_subclients', label: 'roles.permissions.view_subclients', icon: 'visibility' },
        { name: 'edit_subclients', label: 'roles.permissions.edit_subclients', icon: 'edit' },
        { name: 'delete_subclients', label: 'roles.permissions.delete_subclients', icon: 'delete' }
      ]
    },
    {
      category: 'Mantenimiento',
      items: [
        { name: 'send_file', label: 'roles.permissions.send_file', icon: 'visibility' },
        { name: 'get_file', label: 'roles.permissions.get_file', icon: 'visibility' },
        { name: 'view_file', label: 'roles.permissions.view_file', icon: 'visibility' },
        { name: 'delete_file', label: 'roles.permissions.delete_file', icon: 'visibility' },
        { name: 'commands', label: 'roles.permissions.commands', icon: 'visibility' },
        { name: 'pending_tasks', label: 'roles.permissions.pending_tasks', icon: 'visibility' },
        { name: 'edit_pending_tasks', label: 'roles.permissions.edit_pending_tasks', icon: 'edit' },
        { name: 'delete_pending_tasks', label: 'roles.permissions.delete_pending_tasks', icon: 'delete' },
        { name: 'history', label: 'roles.permissions.history', icon: 'visibility' }
      ]
    }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: RolService,
    private utilServicios: UtilService,
    public dialogRef: MatDialogRef<FormNewRolComponent>,
    public dialog: MatDialog,
    private translate: TranslateService
  ) { }

  async ngOnInit(): Promise<void> {
  // Paso 1: Crear un formulario con los campos necesarios
  this.registerFormStep1 = this.formBuilder.group({
    nombre: ['', Validators.required],
    description: ['', Validators.required]
  });

  // Paso 2: Crear un formulario para los permisos
  this.registerFormStep2 = this.createPermissionsFormGroup();

  // Escuchar los cambios en los permisos
  this.listenToPermissionChanges();
    await this.cargar();
  }

  async cargar(): Promise<void> {
    try {
      this.utilServicios.findRol().subscribe((listaRolTemp: Rol[]) => {
        this.listaRol = listaRolTemp;
      });
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  createPermissionsFormGroup(): FormGroup {
    const group: { [key: string]: FormControl } = {};
    this.permissions.forEach(category => {
      category.items.forEach(permission => {
        group[permission.name] = new FormControl(false);
      });
    });
    return this.formBuilder.group(group);
  }

 async onFormSubmit(): Promise<void> {
    if (this.currentStep === 0 && this.registerFormStep1.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
      if (this.currentStep === 1 && this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    console.log('Form Data:', FormData); 

    const rolData = this.buildRolForDatabase();
    
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.rolTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.rolText');
    
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });
    
    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.saveRol(rolData);
      }
    });
  }
  
  
  
  private buildRolForDatabase(): any {
    const rolName = this.registerFormStep1.value.nombre;
    const rolDescripcion = this.registerFormStep1.value.description;
    const permissions = this.registerFormStep2.value;
  
    const listFunction = Object.keys(permissions).flatMap((key) => {
      const functionKey = key as keyof typeof FUNCTION_IDS;
      const idFunction = FUNCTION_IDS[functionKey];
  
      if (idFunction !== undefined && typeof permissions[functionKey] === 'boolean') {
        return [{ idFunction, status: permissions[functionKey] }];
      }
      return [];
    });
  
    return {
      name: rolName,
      description: rolDescripcion,
      listFunction: listFunction,
    };
  }
  

  onStepChange(event: StepperSelectionEvent) {
    this.currentStep = event.selectedIndex;
    console.log(`Pasaste al paso: ${this.currentStep}`);
    // Validación por paso
    if (this.currentStep === 0 && this.registerFormStep1.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
  
    if (this.currentStep === 1 && this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
  }
  
  
  // Lógica para guardar el rol en la base de datos
  async saveRol(objNew: any) {
    try {
      const response = await this.service.addRol(objNew);
  
      if (response && response.status >= 0) {
        this.notifier.notify('success', this.translate.instant('success.addRol'));
        this.utilServicios.getRolsAndOperators();
        this.dialogRef.close(1);  
      } else {
        this.notifier.notify('error', this.translate.instant('error.addRol'));
      }
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  // Salir del formulario
  formExit(): void {
    this.dialogRef.close();
  }

  // Lógica para escuchar cambios en los permisos de manera dinámica
private listenToPermissionChanges(): void {
  const permissionGroups = [
    { view: 'view_terminals', edit: 'edit_terminals', delete: null },
    { view: 'view_sells', edit: null, delete: null },
    { view: 'view_functionality', edit: null, delete: null },
    { view: 'view_status', edit: null, delete: null },
    { view: 'view_alerts', edit: 'edit_alerts', delete: 'delete_alerts' },
    { view: 'view_incidences', edit: 'edit_incidences', delete: null },
    { view: 'view_applications', edit: 'edit_applications', delete: 'delete_applications' },
    { view: 'view_parameters', edit: 'edit_parameters', delete: 'delete_parameters' },
    { view: 'view_profiles', edit: 'edit_profiles', delete: 'delete_profiles' },
    { view: 'view_file', edit: null, delete: 'delete_file' },
    { view: 'pending_tasks', edit: 'edit_pending_tasks', delete: 'delete_pending_tasks' },
    { view: 'view_users', edit: 'edit_users', delete: 'delete_users' },
    { view: 'view_clients', edit: 'edit_clients', delete: 'delete_clients' },
    { view: 'view_subclients', edit: 'edit_subclients', delete: 'delete_subclients' }
  ];

  // Itera sobre cada grupo de permisos y registra los listeners
  permissionGroups.forEach(group => {
    const viewControl = this.registerFormStep2.get(group.view);
    const editControl = group.edit ? this.registerFormStep2.get(group.edit) : null;
    const deleteControl = group.delete ? this.registerFormStep2.get(group.delete) : null;

    // Lógica para cambios en "view"
    viewControl?.valueChanges.subscribe(value => {
      if (!value) {
        editControl?.setValue(false, { emitEvent: false });
        deleteControl?.setValue(false, { emitEvent: false });
      }
    });

    // Lógica para cambios en "edit"
    editControl?.valueChanges.subscribe(value => {
      if (value) {
        viewControl?.setValue(true, { emitEvent: false });
      }
    });

    // Lógica para cambios en "delete"
    deleteControl?.valueChanges.subscribe(value => {
      if (value) {
        viewControl?.setValue(true, { emitEvent: false });
        editControl?.setValue(true, { emitEvent: false });
      }
    });
  });
}

}