<div *ngIf="options">
    <apx-chart
        [series]="options.series"
        [chart]="options.chart"
        [xaxis]="options.xaxis"
        [yaxis]="options.yaxis"
        [tooltip]="options.tooltip"
        [stroke]="options.stroke"
    ></apx-chart>
</div>
