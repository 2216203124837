import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotifierService } from 'angular-notifier';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { UtilService } from 'src/app/services/util.service';
import { Pending } from 'src/app/models/pending.model';
import { FILE_TYPES } from 'src/app/util/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Aplications } from 'src/app/models/aplications.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-send-file-maintenace',
  templateUrl: './send-file-maintenace.component.html',
  styleUrls: ['./send-file-maintenace.component.css']
})
export class SendFileMaintenaceComponent implements OnInit {
  @Input('data') vendingMachine!:VendingMachineAtento;
  hasDefaultVendingMachine:boolean = false;
  selectedVendingMachines = new MatTableDataSource<VendingMachineAtento>();
  fileTypes = FILE_TYPES;
  fileForm: FormGroup ;
  pendingHour:Pending = new Pending;
  

  firmWareUpload:FirmwareUpload = new FirmwareUpload;

  constructor(private utilService:UtilService, 
    private notifier: NotifierService, 
    private formBuilder:FormBuilder,
    private translate: TranslateService,
    private dialog:MatLegacyDialog,
    public rol:RolHandlerService
  ) {
    this.fileForm = this.formBuilder.group({
      storageType: ['', Validators.required], 
      packageName: ['', Validators.required], 
      path: ['', Validators.required] 
    }); 
    
  }

  public ngOnInit(): void {
    if(this.vendingMachine){
      this.selectedVendingMachines.data.push(this.vendingMachine);
      this.hasDefaultVendingMachine = true
    }
  }


  async onFormSubmit(): Promise<void> {
    if (!this.selectedVendingMachines.data.length) {
      this.notifier.notify('warning', this.translate.instant('warning.terminalIsMissing'));
      return;
    }

    if (this.firmWareUpload.fileList === undefined || this.firmWareUpload.fileList.length == 0) {
      this.notifier.notify('warning', this.translate.instant('warning.fileUploadValidation'));
      return;
    }
    
    if(this.fileForm.invalid){
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }
    const fileUpload: FirmwareUpload = new FirmwareUpload();
  
    fileUpload.machines = this.selectedVendingMachines.data.map((vm) => {
      const vendingMachine: VendingMachineAtento = new VendingMachineAtento();
      vendingMachine.id = vm.id;
      return vendingMachine;
    });

    const { packageName, path, storageType } = this.fileForm.value;
    fileUpload.fileList = this.firmWareUpload.fileList.map((file:Aplications)=>{
      const fileToUpload = new Aplications;
      fileToUpload.base64 = file.base64;
      fileToUpload.name = file.name;
      fileToUpload.pakage = packageName;
      fileToUpload.path = path;
      fileToUpload.storageType = storageType;
      return fileToUpload;
    });
  
    const { horaIni, horaDuration } = this.pendingHour;
  
    // Solo validar horaDuration si horaIni está presente
    if (horaIni) {
      fileUpload.horaIni = horaIni; // Asignar horaIni si está presente
      if (horaDuration) {
        let hi = '2022-12-24 ' + horaIni + ':00';
        let hf = '2022-12-24 ' + horaDuration + ':00';
        const momentHi = moment(hi);
        const momentHf = moment(hf);
        let diferenciaEnMinutos = momentHf.diff(momentHi, "minutes");
        if (diferenciaEnMinutos < 0) {
          this.notifier.notify('warning', this.translate.instant('warning.endTimeValidation'));
          return;
        }
        fileUpload.duracion = diferenciaEnMinutos.toString();
      }
    }
  
    // Continuar con el resto de la lógica de envío del archivo
    fileUpload.cod = "1"; // Establecer el código en 1 para el archivo
    fileUpload.destination = environment.pathTempfile;
    this.sendFile(fileUpload);
  }
  

  sendFile(fileUpload:FirmwareUpload){
    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.sendFileTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.sendFileText');

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    dialog.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {        
        let result = await this.utilService.sendFile(fileUpload);
        this.resetFormState();
        if (result!.status >= 0) {
          this.notifier.notify('success', this.translate.instant('success.fileSendedCreated'));
        }
      }
    });
  }

  private resetFormState(){
    this.firmWareUpload = new FirmwareUpload;
    this.fileForm.reset({ storageType: "", packageName: "", path: "" });
    this.resetFormControlStates();
    if(!this.hasDefaultVendingMachine){
      this.selectedVendingMachines = new MatTableDataSource<VendingMachineAtento>();
    }
  }


  private resetFormControlStates(){
    Object.keys(this.fileForm.controls).forEach((key) => {
      const control = this.fileForm.controls[key];
      control.setErrors(null);
    });
  }



}
