import { AfterViewInit, Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AlertCongif } from 'src/app/models/alertConfig.model';
import { CustomAlertsService } from './custom-alert.service';
import { UtilService } from 'src/app/services/util.service';
import { FormNewCustomAlertComponent } from './form-new-custom-alert/form-new-custom-alert.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { environment } from 'src/environments/environment';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { Operator } from 'src/app/models/operator.model';
import { MatSort, Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { CUSTOM_ALERTS_TYPES } from 'src/app/util/constants';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

const BaseColumns = {
  typeAlert: 'typeAlert',
  priority: 'priority',
  operator: 'operator',
  description: 'description',
  dateCreate: 'dateCreate',
  view: 'view'
}

const { operator, ...Columns } = BaseColumns;
const ColumnsAdmin = { ...BaseColumns };

@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class CustomAlertComponent implements OnInit, AfterViewInit {
  translate = inject(TranslateService);

  TYPE_ALERT_OPTIONS:FilterOption[] = Object.keys(CUSTOM_ALERTS_TYPES).map((type:string)=> ({
    label: this.translate.instant('cutomAlertType.'+CUSTOM_ALERTS_TYPES[type].toLowerCase()),
    value: type
  }));
  
  PRIORITY_OPTIONS:FilterOption[] = [
    { label:this.translate.instant('customizableAlerts.priorityOption.high'), value:3 },
    { label:this.translate.instant('customizableAlerts.priorityOption.medium'), value:2 },
    { label:this.translate.instant('customizableAlerts.priorityOption.low'), value:1 },
  ]
  
  FILTER_COLUMNS: { field: string, type: FieldType, options?: FilterOption[] }[] = [
    { field: 'typeAlert', type: 'number', options:this.TYPE_ALERT_OPTIONS },
    { field: 'priority', type: 'number', options:this.PRIORITY_OPTIONS },
    { field: 'dateCreate', type: 'date' },
  ]

  displayedColumns = Object.keys(Columns);
  filterColumns: FilterField[] = this.FILTER_COLUMNS.map((column) => {
    const fielterField = new FilterField(column.field, `customizableAlerts.${column.field}`, column.type, column.options);
    return fielterField;
  })
  dataSource = new MatTableDataSource<AlertCongif>();
  isAdmin: boolean = true;

  sortId: string | null = Columns.dateCreate + "";
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "DESC";
  inactiveTerminals = 0;
  pageLength = 0;
  pageSizeOptions = environment.paginacion;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  filterList: FilterableValue[] = [];
  exportFileName: string | undefined;

  constructor(
    private servicios: CustomAlertsService, 
    private notifier: NotifierService,
    private utilServicios: UtilService,
    public rol:RolHandlerService,
    public dialog: MatDialog) {}

    
  async lanzarLLamada() {
    const request = new UsersConsoleRequest;
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    request.filterAndPagBean = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<AlertCongif>(value.data);
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
        this.inactiveTerminals = this.pageLength - (pagCount.data.active !== undefined ? pagCount.data.active : 0);
      }
    }
  }

  async cargar(){   
    //operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      const operationsOptions = operators.map((op)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'customizableAlerts.operator', 'number', operationsOptions));
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.utilServicios.isAdmin();
    this.exportFileName = this.translate.instant('export.fileCustomAlert');
    if (this.isAdmin) {
      this.displayedColumns = Object.keys(ColumnsAdmin);
      this.cargar();
    }
    this.lanzarLLamada();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent() {;
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  resetPaginatorState() {
    this.paginator.firstPage();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    this.sortId=active;
   
    if(sortState.direction){
      this.direccion=sortState.direction.toUpperCase()
    }else{
      this.direccion=null
      this.sortId=null
    }
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  onNew() {
    const dialogRef = this.dialog.open(FormNewCustomAlertComponent, {
      width: '700px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/ }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) this.lanzarLLamada();
    });
  }


  async onDelete(row: AlertCongif) {
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteCustomAlertTitle'); 
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteCustomAlertText'); 

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        let result = await this.servicios.delete(row.id)

        if (result!.status >= 0) {
          this.notifier.notify('success', this.translate.instant('success.customAlertDeleted'))
          this.lanzarLLamada();
        }

      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  public getTypeLabel(type:number) {
    const alertType = CUSTOM_ALERTS_TYPES[type.toString()];
    if(!alertType) return '';
    return this.translate.instant('cutomAlertType.'+alertType.toLowerCase());
  }

  public getDescriptionLabel(type:number) {
    const alertType = CUSTOM_ALERTS_TYPES[type.toString()];
    if(!alertType) return '';
    return this.translate.instant('customizableAlerts.descriptionOption.'+alertType.toLowerCase());
  }

}
