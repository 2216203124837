import { Aplications } from "./aplications.model";
import { VendingMachineAtento } from "./vendingMachineAtento.model";

export class FirmwareUpload {
  	machines!: VendingMachineAtento[];
	listIdAtento!:string[];
	listExcludeId!:number[];
	idFirmware!:number;
	idOperator:number| undefined;
	idEntorno:number| undefined;
	id!:number;
	destination!:string;
	fileList!:Aplications[];
	comprimir:boolean = false;
	reset:boolean = false;
	active:boolean = false;
	horaIni!:string;
	duracion!:string;
	version:string | undefined;
	type:string | undefined;
	model:string | undefined;
	cod!:string;
	name!:string;
	dateCreate!:string;
	fileName!:string;
}

export class FileDescription {
  name!: string;
  base64!: string;
  content!: any[];
}