<main id="main" class="main">

  <div class="pagetitle">
    <h1>{{ 'monitoring.title' | translate }}</h1>
    <div>
      <!-- Botón Refrescar -->
      <button mat-raised-button class="custom-white-button" (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i> {{ 'common.refresh' | translate }}
      </button>

      <!-- Botón Map  -->
      <button mat-raised-button class="custom-add-button" (click)="toggleMap()">
        <i class="bi bi-geo-alt"></i> {{ 'monitoring.map' | translate }}
      </button>
    </div>
  </div>

  <div class="map-container" *ngIf="showMap">
    <app-monitoring-map></app-monitoring-map>
  </div>

  <app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>

  <div id="tableDescription" hidden>{{ 'monitoring.tableDescription' | translate }}</div>

  <div class="mat-elevation-z2 tablaPersonal">
    <table mat-table class="mat-table" [dataSource]="dataSource" aria-describedby="tableDescription" matSort
      (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">

      <!-- id Column -->
      <ng-container matColumnDef="idAtento">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
          <div mat-sort-header> {{ 'monitoring.isAtento' | translate }} </div>
        </th>
        <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{ element.isAtento }} </td>
      </ng-container>
      
      <!-- serialNumber Column -->
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.serialNumber' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.serialNumber ?? '-'}}</td>
      </ng-container>

      <!-- Manufacture Column -->
      <ng-container matColumnDef="manufacID">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.manufacID' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.manufacID ?? '-'}}</td>
      </ng-container>
      <!-- modelNumber Column -->
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.modelNumber' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.modelNumber ?? '-'}}</td>
      </ng-container>
      <!-- nameOperator Column -->
      <ng-container matColumnDef="nameOperator" *ngIf="isAdmin">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.nameOperator' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.nameOperator}}</td>
      </ng-container>

      <!-- swRev Column -->
      <ng-container matColumnDef="swRev">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.softwareRevision' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.swRev ?? '-'}}</td>
      </ng-container>
      <!-- commerce Column -->
      <ng-container matColumnDef="commerce">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.commerce' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.commerce ?? '-'}}</td>
      </ng-container>
      <!-- puntoVenta Column -->
      <ng-container matColumnDef="puntoVenta">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.puntoVenta' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.puntoVenta ?? '-'}}</td>
      </ng-container>
      <!-- lastUpdate Column -->
      <ng-container matColumnDef="lastUpdate">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header>{{ 'monitoring.lastUpdate' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.lastConect | dateOnTimeZone | diasDesdeFecha }}</td>
      </ng-container>
      <ng-container matColumnDef="coverage">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.coverageLevel' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element">
          <div [ngClass]="getCoverageClass(element)" [matTooltip]="getCoverageTooltip(element)" class="coverage-icon">
            <ng-container *ngIf="element.coverageLevel; else noDataIcon">
              <!-- Íconos normales -->
              <i class="bi bi-reception-4"></i>
              <i class="bi bi-wifi"></i>
            </ng-container>
            <ng-template #noDataIcon>
              <!-- Íconos tachados para "Sin datos" -->
              <i class="bi bi-reception-0"></i>
              <i class="bi bi-wifi-off"></i>
            </ng-template>
          </div>
        </td>
      </ng-container>




      <ng-container matColumnDef="pending">
        <th mat-header-cell *matHeaderCellDef>
          <div>{{ 'monitoring.pending' | translate }}</div>
        </th>
        <td mat-cell *matCellDef="let element" class="button">
          <div *ngIf="element.hasPending" class="cl-donwload">
            <button mat-icon-button disabled matTooltip="{{ 'monitoring.tooltip.pending' | translate }}">
              <i class="bi bi-cloud-arrow-down"></i>
            </button>
          </div>
        </td>
      </ng-container>
      <!-- view Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef style="width: 60px;"></th>
        <td mat-cell *matCellDef="let element" style="width: 60px;">
          <button *ngIf="rol.canByInput(rol.functIds.view_terminals)" matTooltip="{{ 'common.view' | translate }}" 
            (click)="onView(element)" mat-icon-button class="iconoTabla">
            <mat-icon>chevron_right</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'row-OFF': (isVmOff(row)) , 'row-pending': (row.hasPending === true) }"></tr>
    </table>

    <!-- Empty state -->
    <ng-container *ngIf="!dataSource.data.length">
      <app-table-empty-state></app-table-empty-state>
    </ng-container>

    <button id="export" class="icoDownload" mat-icon-button
      (click)="exporter.exportTable('xlsx', { fileName: exportFileName })">
      <i class="bi bi-file-earmark-arrow-down"></i>
    </button>
    <span class="text-red"> {{ 'monitoring.inactive' | translate }} {{ inactiveTerminals }} </span>

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent()"
      showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>

  </div>

</main>