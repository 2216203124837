import { Injectable } from '@angular/core';
import { PaginatorIntlService } from './paginator-intl.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentsStateService {

  constructor(
    private paginatorIntl: PaginatorIntlService
  ) {}

  updateLabelsState(){
    this.paginatorIntl.updateLabelsState();
  }
}
