
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions } from 'src/app/util/constants';
import { Incidence } from 'src/app/models/incidence.model';
import { IncidenceService } from '../incidence.service';
import moment from 'moment';
import { UtilService } from 'src/app/services/util.service';
import { Operator } from 'src/app/models/operator.model';
import { Users } from 'src/app/models/users.model';
import { TranslateService } from '@ngx-translate/core';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { MonitoringService } from '../../../monitoring/monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';

@Component({
  selector: 'app-form-new-incidence',
  templateUrl: './form-new-incidence.component.html',
  styleUrls: ['./form-new-incidence.component.css']
})
export class FormNewIncidenceComponent implements OnInit {

  isAdmin: boolean = true;
  registerForm!: FormGroup;
  disabledTerminal:boolean = false;
  isCloseIncidence:boolean = false;
  typeSelect!:string
  operatorList:Operator[] = [];
  terminals: VendingMachineAtento[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {incidencia:Incidence, vm:VendingMachineAtento, close:true },
    private formBuilder: FormBuilder,
    private service: IncidenceService,
    private utilService : UtilService,
    public dialogRef: MatDialogRef<FormNewIncidenceComponent>,
    private translate:TranslateService,
    public dialog: MatDialog,
    private monitoring:MonitoringService
  ) {}
  
  async ngOnInit(): Promise<void> {
    this.isCloseIncidence = !!this.data.close;
    if (this.data.vm) this.disabledTerminal = true;

    const isAdmin = this.utilService.isAdmin();

    this.registerForm = this.formBuilder.group({
      type: [{ value: '', disabled: this.isCloseIncidence }, Validators.required],
      numTer: [{ value: '', disabled: this.disabledTerminal }, Validators.required], 
      commet: [{ value: '', disabled: this.isCloseIncidence }, Validators.required],
      operator: [{ value: '', disabled: (!isAdmin || this.disabledTerminal || this.isCloseIncidence) }, Validators.required],
      resolution: [{value:''}],
    });
    // Si se quiere cerrar la incidencia hay que escribir un comentario
    if(this.data.close) {
      this.registerForm.get('resolution')?.addValidators(Validators.required);  
    }

    this.registerForm.get('operator')?.valueChanges.subscribe((operatorId)=>{
      if(operatorId) this.loadTerminals(operatorId);
    })

    let terminaID, type, commet, operator;
    operator = this.utilService.getOperatorId();
    
    if(this.data.vm){
        terminaID = this.data.vm.id;
        operator = this.data.vm.idOperator;
    }
    if(this.data.incidencia){
        type = this.data.incidencia.type + "";
        commet = this.data.incidencia.description;
        terminaID = this.data.incidencia.idVM;
        operator = this.data.incidencia.operator?.id;
    }
    
    this.registerForm.patchValue({
      type: type,
      numTer: terminaID, 
      commet: commet,
      operator: operator ,  // Desactivar si hay una máquina
      resolution: '',
    });

    this.loadOperators();
  }


  private loadOperators(){
    // Cargar operadores
    this.utilService.findOperators().subscribe((operators: Operator[]) => {
      this.operatorList = operators;
    });
  }
  private loadTerminals(operatorId:number){
    const request = new UsersConsoleRequest;
    request.idOperator = operatorId;

    this.monitoring.find(request).then((terminals) => {
      this.terminals = terminals?.data ?? [];
    });
  }

  onFormSubmit(): void {
    if (this.registerForm.invalid)  return;
    
    const data = {...this.registerForm.value};
    let objNew : Incidence = new Incidence();
    objNew.idOperator = data['operator']

    if(this.data.incidencia){
      objNew = {...this.data.incidencia}
      const { userCreate } = this.data.incidencia;
      objNew.userCreate = {id: userCreate.id } as Users; // solo envío el id del user.
        //hay que mapear las fechas para que el BACK las entienda  
        if(this.data.incidencia.dateCreate){  objNew.dateCreate = moment(this.data.incidencia.dateCreate, "DD-MM-YYYY HH:mm:ss").toDate();  }
        if(this.data.incidencia.dateDelete){  objNew.dateDelete = moment(this.data.incidencia.dateDelete, "DD-MM-YYYY HH:mm:ss").toDate();  }
    }

    if(this.isCloseIncidence){
        objNew.resolution=data['resolution']        
    }else{
        objNew.description=data['commet']  
        objNew.idVM=this.registerForm.controls['numTer'].value
        objNew.type=data['type']   
    }

    objNew.serialNumber = this.terminals.find((item)=> item.id === objNew.idVM)?.serialNumber;

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.saveIncidenceTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.saveIncidenceText');


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val:any;

          if(this.isCloseIncidence){
            val = await this.service.closeIncidence(objNew)
          }else{
            objNew.idOperator = this.registerForm.controls['operator'].value
            val = await this.service.addUpdate(objNew)
          }

          if(val?.status>=0){
            this.dialogRef.close(FormCreateActions.SAVED);
          }

      }
    });

  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }
}
