import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { FormCreateActions } from 'src/app/util/constants';
import { TerminalService } from './terminal.service';
import { NotifierService } from 'angular-notifier';
import { DateOnTimeZonePipe } from 'src/app/pages/comun/pipes/date-on-timezone.pipe';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-info-terminal',
  templateUrl: './info-terminal.component.html',
  styleUrls: ['./info-terminal.component.css'],
  providers:[DateOnTimeZonePipe]
})
export class InfoTerminalComponent implements OnInit {
	
  registerForm!: FormGroup;
  infoTerminal:VendingMachineAtento = new VendingMachineAtento();

  constructor(@Inject(MAT_DIALOG_DATA) public data: { terminalId:number },
    private formBuilder: FormBuilder, 
    private notifier: NotifierService ,
    private services: TerminalService,
    private dateOnTimezone: DateOnTimeZonePipe,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<InfoTerminalComponent>,
    public dialog: MatDialog,
    public rol:RolHandlerService
  ) {}


 ngOnInit(): void {
    this.loadTerminal()
  }

  async loadTerminal(){
    const { terminalId } = this.data;
    if(!terminalId) return;
    const response = await this.services.findById(terminalId);
    if(response && response.data){
      this.infoTerminal = response.data;
      this.vaildatePendings(this.infoTerminal.hasPending);
      this.setFormGroupInfo()
    }
  }

  vaildatePendings(hasPending:boolean){
    if(hasPending) {
      this.notifier.notify('warning', this.translate.instant('warning.terminalHasPendings'));
    }   
  }

  setFormGroupInfo(){
    const dateWithTimeZone =  this.infoTerminal.dateCreate ? this.dateOnTimezone.transform(this.infoTerminal.dateCreate+"", true): "";
    const userCanEdit = this.rol.canByInput(this.rol.functIds.edit_terminals);
    this.registerForm = this.formBuilder.group({
      //solo lectura
        identificadorField:[{value: this.infoTerminal.idAtento, disabled:true}],
        serialNumberField:[{value:this.infoTerminal.serialNumber, disabled:true}],
        manufacIDField:[{value: this.infoTerminal.manufacID, disabled:true}],
        modelField:[{value: this.infoTerminal.modelNumber, disabled:true}],
        
        dateCreateField:[{value:dateWithTimeZone, disabled:true}],
        commerceField:[{value: this.infoTerminal.commerce, disabled:true}],
        orderCommerceField:[{value: this.infoTerminal.orderCommerce, disabled:true}],
      //editables    
        nameField:[{value:this.infoTerminal.name, disabled:!userCanEdit}],
        locationField:[{value:this.infoTerminal.location, disabled:!userCanEdit}],
        puntoVentaField:[{value:this.infoTerminal.location, disabled:!userCanEdit}],
      //solo lectura
        versionKernel:[{value:this.infoTerminal.versionKernel, disabled:true}],
        bootVersion:[{value:this.infoTerminal.bootVersion, disabled:true}],
        osVersion:[{value:this.infoTerminal.osVersion, disabled:true}],
        swRevField:[{value: this.infoTerminal.swRev, disabled:true}],
        
        imeiField:[{value: this.infoTerminal.imei, disabled:true}],
        ipField:[{value: this.infoTerminal.ip, disabled:true}],
        macBTField:[{value: this.infoTerminal.macBT, disabled:true}],
        simField:[{value: this.infoTerminal.sim, disabled:true}],
   });
  }


  onFormSubmit(): void {
    if (this.registerForm.invalid) return;

    const data = this.applyFormats(this.registerForm);

    let objNew : VendingMachineAtento = new VendingMachineAtento();
    if(this.infoTerminal.id) objNew = { ...this.infoTerminal }; 

    objNew.name=data['nameField'] 
    objNew.puntoVenta=data['puntoVentaField'] 
    objNew.location=data['locationField']  

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant('confirmDialog.terminalTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.terminalText');

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
         let result = await this.services.updateEditService(objNew)
         if(result){
           this.notifier.notify('success', this.translate.instant('success.updateTerminal'));
           this.dialogRef.close(FormCreateActions.SAVED);
         }

      }
    });

  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
