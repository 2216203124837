import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { NotifierService } from 'angular-notifier';
import { Rol } from 'src/app/models/rol.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { RolService } from '../rol.service';
import { FUNCTION_IDS } from 'src/app/util/constants';

@Component({
  selector: 'app-form-edit-rol',
  templateUrl: './form-edit-rol.component.html',
  styleUrls: ['./form-edit-rol.component.css']
})
export class FormEditRolComponent implements OnInit {
  registerFormStep1!: FormGroup;
  registerFormStep2!: FormGroup;

  listaRol: Rol[] = [];
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  rolForm!: FormGroup;
  rol: Rol | null = null;
  rolId: string | undefined; 

  permissions = [
    {
      category: 'Monitorizacion',
      items: [
        { name: 'edit_terminals', label: 'roles.permissions.edit_terminalsg', icon: 'visibility' },
        { name: 'view_terminals', label: 'roles.permissions.view_terminals', icon: 'visibility' },
        { name: 'view_sells', label: 'roles.permissions.view_sells', icon: 'visibility' }
      ]
    },
    {
      category: 'Estadisticas',
      items: [
        { name: 'view_statistics', label: 'roles.permissions.view_statistics', icon: 'visibility' },
        { name: 'view_functionality', label: 'roles.permissions.view_functionality', icon: 'visibility' },
        { name: 'view_sensors', label: 'roles.permissions.view_sensors', icon: 'visibility' },
        { name: 'view_status', label: 'roles.permissions.view_status', icon: 'visibility' }
      ]
    },
    {
      category: 'Soporte',
      items: [
        { name: 'view_support', label: 'roles.permissions.view_support', icon: 'visibility' },
        { name: 'view_alerts', label: 'roles.permissions.view_alerts', icon: 'visibility' },
        { name: 'edit_alerts', label: 'roles.permissions.edit_alerts', icon: 'edit' },
        { name: 'delete_alerts', label: 'roles.permissions.view_alerts', icon: 'delete' },
        { name: 'view_incidences', label: 'roles.permissions.view_incidences', icon: 'visibility' },
        { name: 'edit_incidences', label: 'roles.permissions.edit_incidences', icon: 'edit' }
      ]
    },
    {
      category: 'Configuracion',
      items: [
        { name: 'view_applications', label: 'roles.permissions.view_applications', icon: 'visibility' },
        { name: 'edit_applications', label: 'roles.permissions.edit_applications', icon: 'edit' },
        { name: 'delete_applications', label: 'roles.permissions.delete_applications', icon: 'delete' },
        { name: 'view_parameters', label: 'roles.permissions.view_parameters', icon: 'visibility' },
        { name: 'edit_parameters', label: 'roles.permissions.edit_parameters', icon: 'edit' },
        { name: 'delete_parameters', label: 'roles.permissions.delete_parameters', icon: 'delete' },
        { name: 'view_profiles', label: 'roles.permissions.view_profiles', icon: 'visibility' },
        { name: 'edit_profiles', label: 'roles.permissions.edit_profiles', icon: 'edit' },
        { name: 'delete_profiles', label: 'roles.permissions.delete_profiles', icon: 'delete' },
        { name: 'view_custom_alerts', label: 'roles.permissions.view_custom_alerts', icon: 'visibility' },
        { name: 'delete_custom_alerts', label: 'roles.permissions.delete_custom_alerts', icon: 'delete' },
        { name: 'view_pending_tasks', label: 'roles.permissions.view_pending_tasks', icon: 'visibility' },
        { name: 'delete_pending_tasks', label: 'roles.permissions.delete_pending_tasks', icon: 'delete' }
      ]
    },
    {
      category: 'Administrador',
      items: [
        { name: 'view_users', label: 'roles.permissions.view_users', icon: 'visibility' },
        { name: 'edit_users', label: 'roles.permissions.edit_users', icon: 'edit' },
        { name: 'delete_users', label: 'roles.permissions.delete_users', icon: 'delete' },
        { name: 'view_clients', label: 'roles.permissions.view_clients', icon: 'visibility' },
        { name: 'edit_clients', label: 'roles.permissions.edit_clients', icon: 'edit' },
        { name: 'delete_clients', label: 'roles.permissions.delete_clients', icon: 'delete' },
        { name: 'view_subclients', label: 'roles.permissions.view_subclients', icon: 'visibility' },
        { name: 'edit_subclients', label: 'roles.permissions.edit_subclients', icon: 'edit' },
        { name: 'delete_subclients', label: 'roles.permissions.delete_subclients', icon: 'delete' }
      ]
    },
    {
      category: 'Mantenimiento',
      items: [
        { name: 'send_file', label: 'roles.permissions.send_file', icon: 'visibility' },
        { name: 'get_file', label: 'roles.permissions.get_file', icon: 'visibility' },
        { name: 'view_file', label: 'roles.permissions.view_file', icon: 'visibility' },
        { name: 'delete_file', label: 'roles.permissions.delete_file', icon: 'visibility' },
        { name: 'commands', label: 'roles.permissions.commands', icon: 'visibility' },
        { name: 'pending_tasks', label: 'roles.permissions.pending_tasks', icon: 'visibility' },
        { name: 'edit_pending_tasks', label: 'roles.permissions.edit_pending_tasks', icon: 'edit' },
        { name: 'delete_pending_tasks', label: 'roles.permissions.delete_pending_tasks', icon: 'delete' },
        { name: 'history', label: 'roles.permissions.history', icon: 'visibility' }
      ]
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: RolService,
    private utilServicios: UtilService,
    public dialogRef: MatDialogRef<FormEditRolComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.registerFormStep1 = this.formBuilder.group({
      nombre: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.rol = this.data.rol;        
    this.rolId = this.data.rolId;   

    this.registerFormStep2 = this.createPermissionsFormGroup();

    if (this.rol) {
      this.loadFormData(this.rol);
      this.initializePermissions();
    }

    await this.cargar();

    this.listenToPermissionChanges();
  }

  private initializePermissions() {
    // Hacer un mapeo entre idFunction y el nombre del permiso
    const permissionMap = this.createPermissionMap();
  
    if (this.rol?.listFunction) {
      this.rol.listFunction.forEach((func) => {
        const permissionName = permissionMap[func.idFunction]; // Buscar el nombre del permiso por idFunction
  
        if (permissionName) {
          const permissionControl = this.registerFormStep2.get(permissionName);
          if (permissionControl) {
            permissionControl.setValue(func.status); // Asignar el estado del permiso
          }
        }
      });
    }
  }
  
  private createPermissionMap(): { [key: number]: string } {
    const map: { [key: number]: string } = {};
    this.permissions.forEach(category => {
      category.items.forEach(permission => {
        // Asegurarse de que permission.name es una clave válida de FUNCTION_IDS
        const functionId = FUNCTION_IDS[permission.name as keyof typeof FUNCTION_IDS];
        map[functionId] = permission.name;
      });
    });
    return map;
  }
  
  

  loadFormData(rol: Rol): void {
    this.registerFormStep1.patchValue({
      nombre: rol.name,
      description: rol.description,
    });

    if (rol.listFunction) {
      rol.listFunction.forEach((func) => {
        const permissionControl = this.registerFormStep2.get(func.idFunction.toString());
        if (permissionControl) {
          permissionControl.setValue(func.status);
        }
      });
    }
  }

  async cargar(): Promise<void> {
    try {
      this.utilServicios.findRol().subscribe((listaRolTemp: Rol[]) => {
        this.listaRol = listaRolTemp;
        if (this.rol) {
          this.loadFormData(this.rol);
        }
      });
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }
  
  createPermissionsFormGroup(): FormGroup {
    const group: { [key: string]: any } = {};

    this.permissions.forEach(category => {
      category.items.forEach(permission => {
        group[permission.name] = new FormControl(false);
      });
    });

    return this.formBuilder.group(group);
  }

  // Cambiar el nombre a onSave
  async onSave(): Promise<void> {
    if (this.registerFormStep1.invalid || this.registerFormStep2.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const rolData = this.buildRolForDatabase();

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.rolTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.rolText');

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.saveRol(rolData);
      }
    });
  }


  private buildRolForDatabase(): any {
    const rolId = this.rolId;  
    const rolName = this.registerFormStep1.value.nombre;
    const rolDescription = this.registerFormStep1.value.description; 
    const permissions = this.registerFormStep2.value;
  
    const listFunction = Object.keys(permissions).flatMap((key) => {
      const functionKey = key as keyof typeof FUNCTION_IDS;
      const idFunction = FUNCTION_IDS[functionKey];
  
      if (idFunction !== undefined && typeof permissions[functionKey] === 'boolean') {
        return [{ idFunction, status: permissions[functionKey] }];
      }
      return [];
    });
  
    return {
      id: rolId,  // Usa el rolId para la actualización
      name: rolName,
      description: rolDescription, 
      listFunction: listFunction,
    };
  }
  
  
  async saveRol(rolData: any) {
    try {
      const response = await this.service.updateRol(rolData);

      if (response && response.status >= 0) {
        this.notifier.notify('success', this.translate.instant('success.updateRol'));
        this.utilServicios.getRolsAndOperators();
        this.dialogRef.close(1);
      } else {
        this.notifier.notify('error', this.translate.instant('error.updateRol'));
      }
    } catch (error) {
      this.notifier.notify('error', this.translate.instant('error.general'));
    }
  }

  formExit(): void {
    this.dialogRef.close();
  }

   // Lógica para escuchar cambios en los permisos de manera dinámica
private listenToPermissionChanges(): void {
  const permissionGroups = [
    { view: 'view_terminals', edit: 'edit_terminals', delete: null },
    { view: 'view_sells', edit: null, delete: null },
    { view: 'view_functionality', edit: null, delete: null },
    { view: 'view_sensors', edit: null, delete: null },
    { view: 'view_status', edit: null, delete: null },
    { view: 'view_alerts', edit: 'edit_alerts', delete: 'delete_alerts' },
    { view: 'view_incidences', edit: 'edit_incidences', delete: null },
    { view: 'view_applications', edit: 'edit_applications', delete: 'delete_applications' },
    { view: 'view_parameters', edit: 'edit_parameters', delete: 'delete_parameters' },
    { view: 'view_profiles', edit: 'edit_profiles', delete: 'delete_profiles' },
    { view: 'view_file', edit: null, delete: 'delete_file' },
    { view: 'pending_tasks', edit: 'edit_pending_tasks', delete: 'delete_pending_tasks' },
    { view: 'view_users', edit: 'edit_users', delete: 'delete_users' },
    { view: 'view_clients', edit: 'edit_clients', delete: 'delete_clients' },
    { view: 'view_subclients', edit: 'edit_subclients', delete: 'delete_subclients' }
  ];

  // Itera sobre cada grupo de permisos y registra los listeners
  permissionGroups.forEach(group => {
    const viewControl = this.registerFormStep2.get(group.view);
    const editControl = group.edit ? this.registerFormStep2.get(group.edit) : null;
    const deleteControl = group.delete ? this.registerFormStep2.get(group.delete) : null;

    // Lógica para cambios en "view"
    viewControl?.valueChanges.subscribe(value => {
      if (!value) {
        editControl?.setValue(false, { emitEvent: false });
        deleteControl?.setValue(false, { emitEvent: false });
      }
    });

    // Lógica para cambios en "edit"
    editControl?.valueChanges.subscribe(value => {
      if (value) {
        viewControl?.setValue(true, { emitEvent: false });
      }
    });

    // Lógica para cambios en "delete"
    deleteControl?.valueChanges.subscribe(value => {
      if (value) {
        viewControl?.setValue(true, { emitEvent: false });
        editControl?.setValue(true, { emitEvent: false });
      }
    });
  });
}
}