export class PerfilApp {
    idApp!: number;
    idParam!: number | undefined;
    paramExtra!: string;
    typeParamExtra!: string;
}

export class PerfilAppSelected extends PerfilApp {
    appName!:string;
    version!: string;
    pakage!: string;
    paramName!:string;
    fileName!: string;

    mapToPerfilApp():PerfilApp{
        const perfilApp = new PerfilApp;
        perfilApp.idApp = this.idApp;
        perfilApp.idParam = this.idParam;
        perfilApp.paramExtra = this.paramExtra;
        perfilApp.typeParamExtra = this.typeParamExtra;
        return perfilApp;
    }
}


