import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { environment } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NotifierService } from 'angular-notifier';
import { PerfilesService } from './perfiles.service';
import { Parameters } from 'src/app/models/parameters.model';
import { FormControl, FormGroup } from '@angular/forms';
import { NewPerfilesComponent } from './new-perfiles/new-perfiles.component';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { Profile } from 'src/app/models/profiles.model';
import { EditPerfilesComponent } from './edit-perfiles/edit-perfiles.component';
import { Aplications } from 'src/app/models/aplications.model';
import { SelectionModel } from '@angular/cdk/collections';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TranslateService } from '@ngx-translate/core';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

enum Columns {
  operator = 'operator',
  name = 'name',
  description = 'description',
  dateCreate = 'dateCreate',
  dateUpdate = 'dateUpdate',
  numMaquinas = 'numMaquinas',
  code =  'code',
  view = 'view'
}

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'name', type:'string' },
  { field:'description', type:'string' },
  { field:'dateCreate', type:'date' },
  { field:'dateUpdate', type:'date' },
]
@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class PerfilesComponent implements OnInit, AfterViewInit {
  displayedColumns = [...Object.keys(Columns)];
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `profiles.${column.field}`, column.type, column.options);
    return fielterField;
  })

  operatorsList: Operator[] = [];
  dataSource = new MatTableDataSource<Profile>();
  selection = new SelectionModel<Profile>(true, []); // Initialize SelectionModel
  exportFileName: string | undefined;

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate;
  direccion: string | null = "DESC";
  inactiveTerminals = 0;
  isEditing = false;
  selectedProfile: Profile | null = null;

  filterList: FilterableValue[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private dialogRef!: MatDialogRef<NewPerfilesComponent>;
  private dialogMonitoringRef!: MatDialogRef<TabsComponent>;

  nameFilter = new FormControl();
  dateStartFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private notifier: NotifierService,
    private profile: PerfilesService,
    private monitoringService: MonitoringService,
    private utilsService: UtilService,
    private translate:TranslateService,
    public dialog: MatDialog, 
    public commonService: CommonService,
    public rol:RolHandlerService
  ) {}

  private exitsFilterableValue(column: string): boolean {
    return this.filterList.some((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const existIdVM = this.exitsFilterableValue('idVM');
    const existIdOerator = this.exitsFilterableValue('idOperator');

    if (this.data?.obj && !existIdVM) {
      const idVM = this.data.obj.id;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", 'EQUALS'));
    }

    let userSessionOperatorId = this.utilsService.getOperatorId();
    if (userSessionOperatorId && !existIdOerator) {
      this.filterList.push(new FilterableValue("idOperator", userSessionOperatorId + "", "long", 'EQUALS'));
    }

    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    const request = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.profile.find(request);
    let pagCount = await this.profile.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<Profile>(value.data.map((profile:Profile)=>{
        const operator = this.operatorsList.find((op)=> op.id === profile.idOperator);
        profile.operator = operator?.name ?? ''; 
        return profile;
      }));
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  }

  private loadOperators(){
    this.utilsService.findOperators().subscribe((operators)=> this.operatorsList = operators);
  }
  
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.exportFileName = this.translate.instant('export.fileProfile');
    this.dataSource.sort = this.sort;
    this.loadOperators();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  resetPaginatorState(){
    this.paginator.firstPage();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent() {;
    this.lanzarLLamada();
  }


  announceSortChange(sortState: Sort) {
    console.log("ordenar=" + JSON.stringify(sortState))
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }
    this.resetPaginatorState();
    this.lanzarLLamada();
  }


  async onView(row: any) {
    let value = await this.monitoringService.getMachine(row.idVM);
    this.dialogMonitoringRef = this.dialog.open(TabsComponent, {
      width: '90%', height: '95%', panelClass: 'custom-modalbox-big',
      data: {
        obj: value?.data
      }
    });

    this.dialogMonitoringRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  async onNew() {
    const newDialogRef: MatDialogRef<NewPerfilesComponent> = this.dialog.open(NewPerfilesComponent, {
      width: '800px',
      panelClass: 'custom-modalbox-big',
      data: {
        vm: this.data.obj
      }
    });

    newDialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        this.notifier.notify('success', 'Nuevo perfil creado correctamente');
        this.lanzarLLamada();
      }
    });
  }

  async onEdit(row: Aplications) {
    const editDialogRef: MatDialogRef<EditPerfilesComponent> = this.dialog.open(EditPerfilesComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: row
    });

    editDialogRef.afterClosed().subscribe((result) => {
      if (result === 1) this.lanzarLLamada();
    });
  }

  async onQrCodeClick(profileId: number): Promise<void> {
    this.dialog.open(EnrollmentComponent, {
      width: '600px',
      panelClass: 'custom-modalbox-big',
      data: { code: profileId }
    });
  }
  
  closeEditPanel() {
    this.isEditing = false;
    this.selectedProfile = null;
  }

  async onClose(row: Parameters) {
    this.dialogRef = this.dialog.open(NewPerfilesComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: { incidencia: row, vm: this.data.obj, close: true }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.notifier.notify('success', 'Ticket close successfully')
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

}
