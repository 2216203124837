<section class="form-container">
    <div class="form-header">
        <h2>{{ 'filter.add' | translate }}</h2>
    </div>
    
    <div class="form-body">
        <form [formGroup]="filterForm">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'filter.field' | translate }}</mat-label>
                    <mat-select formControlName="field" required>
                        <mat-option *ngFor="let field of fields" [value]="field">{{ field.label | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="filterForm.get('field')?.hasError('required')">
                    {{ 'error.required.field' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'filter.operator' | translate }}</mat-label>
                    <mat-select formControlName="operator" required>
                        <mat-option *ngFor="let op of operators" [value]="op">{{ op.label | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="filterForm.get('operator')?.hasError('required')">
                        {{ 'error.required.field' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'filter.compareValue' | translate }}</mat-label>
                    <ng-container *ngIf="!hasOptions">
                        <input matInput formControlName="compareValue" [type]="inputType" placeholder="{{ 'filter.placeholder.compareValue' | translate }}" required>
                    </ng-container>
                    <ng-container *ngIf="hasOptions">
                        <mat-select formControlName="compareValue" required>
                            <mat-option *ngFor="let opt of options" [value]="opt.value">{{ opt.label }}</mat-option>
                        </mat-select>
                    </ng-container>
                    <mat-error *ngIf="filterForm.get('compareValue')?.hasError('required')">
                        {{ 'error.required.field' | translate }}
                      </mat-error>
                </mat-form-field>
            </div>

             <!-- Botón de Enviar -->
            <div class="botonera">
                <button mat-stroke-button class="custom-white-button" (click)="dialog.close()">{{ 'common.cancel' | translate }}</button>
                <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()" [disabled]="filterForm.invalid">{{ 'common.submit' | translate }}</button>
            </div>
        </form>
    </div>

</section>