<main id="main" class="main">
    <div class="pagetitle">
      <h1> {{ 'maintenace.tabs.createCommands' | translate }}</h1>
    </div>

    <section class="row w-100">
        <ng-container *ngIf="!hasVendingMachineSelected">
          <article class="col-lg-6">
            <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
          </article>  
        </ng-container>

        <article class="bloque col-lg-6 form-container">
            <form [formGroup]="commandParams">
                <!-- Command -->
                <div class="form-group" style="margin-top: 10px;">
                  <mat-form-field appearance="outline">
                    <mat-label> {{ 'maintenace.input.command' | translate }} </mat-label>
                    <mat-select formControlName="command" required>
                        <mat-option *ngFor="let type of commandTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="commandParams.get('command')?.hasError('required')">
                      {{ 'error.required.field' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
          
                <!-- PackageName -->
                <div class="form-group" *ngIf="commandParams.get('packageName')?.enabled">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'maintenace.input.packageName' | translate }}<span class="obligatorio"></span></mat-label>
                    <input matInput formControlName="packageName" placeholder="{{ 'maintenace.placeholder.packageName' | translate }}" required>
                    <mat-error *ngIf="commandParams.get('packageName')?.hasError('required')">
                        {{ 'error.required.field' | translate }}
                      </mat-error>
                  </mat-form-field>
                </div>

                <!-- Level -->
                <div class="form-group" *ngIf="commandParams.get('level')?.enabled">
                    <mat-form-field appearance="outline">
                        <mat-label> {{ 'common.fileType' | translate }}</mat-label>
                        <mat-select formControlName="level" required>
                            <mat-option *ngFor="let type of levelTypes" [value]="type">{{ type }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="commandParams.get('level')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
          
                <div class="botonera">
                  <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">
                      {{ 'common.submit' | translate }}
                  </button>
                </div>
              </form>
        </article>
    </section>

</main>