export enum COMMAND_TYPES {
    BLOCK="BLOCK",
    UNBLOCK="UNBLOCK",
    RESET="RESET",
    ERASE="ERASE",
    SWITCHOFF="SWITCHOFF",
    UNINSTALL="UNINSTALL",
    ACTIVATE_LOG="ACTIVATE_LOG"
 }
 
export enum LEVEL_TYPES {
   DEBUG = "DEBUG",
   ERROR = "ERROR",
   TRACE = "TRACE",
   INFO = "INFO"
 }

export interface CommandParam {
    command: COMMAND_TYPES;
    packageName?: string;
    level?:LEVEL_TYPES;
}

export class Command {
    machines: { id: number }[] = [];
    params: CommandParam[] = [];

}