import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotifierService } from 'angular-notifier';
import { Aplications } from 'src/app/models/aplications.model';
import { AppsService } from '../aplications.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FirmwareUpload } from 'src/app/models/firmwareUpload.model';
import { FormCreateActions } from 'src/app/util/constants';
import { FilterableValue, FilterAndPagBean, OPERATION_FILTER, TYPE_FILTER } from 'src/app/models/FilterAndPagBean';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { convertFileToBase64, downloadFile } from 'src/app/util/util';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-edit-app',
  templateUrl: './edit-app.component.html',
  styleUrls: ['./edit-app.component.css']
})
export class EditAppComponent implements OnInit {
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  isLinear: BooleanInput;
  selectedFile: File | null = null;
  showDropZone = false;
  isFileSelected: boolean | undefined;
  versions: Aplications[] = [];
  filterList: FilterableValue[] = [];
  currentVersion: string | null = null; // Para la versión actual
  packageName: string = '';
  isAddingVersion = false;

  isOpen: { [key: string]: boolean } = {
    details: true,
    dates: true,
    versiones: true,
  };
  confirmDialogRef: any;

  readOnly: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { app: Aplications, readOnly: boolean },
    private appService: AppsService,
    public rol:RolHandlerService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.readOnly = !!this.data.readOnly;

    // Primer formulario para los datos de la aplicación
    this.firstFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: this.readOnly }, Validators.required],
      description: [{ value: '', disabled: this.readOnly }, Validators.compose([Validators.required, Validators.maxLength(400)])],
      packageName: [{ value: '', disabled: true }, Validators.required],
      dateCreate: [{ value: '', disabled: true }],
      dateUpdate: [{ value: '', disabled: true }],
    });

    // Segundo formulario para gestionar los archivos y la versión
    this.secondFormGroup = this.formBuilder.group({
      uploadFiles: [null, Validators.required],
      fileBase64: [''],
      version: ['', Validators.required]
    });
    // Escuchar cambios en el campo de versión
    this.secondFormGroup.get('version')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.showDropZone = true; // Mostrar la zona de carga si el campo de versión está vacío
      }
    });

    if(!this.data) return;

    // Verifica que los datos estén disponibles
    const hasPackage = !!this.data.app.pakage; 
    if (hasPackage) {
      this.loadFullVersionsData();
    } else {
      this.loadVersionData();
    }
  }

  async loadFullVersionsData() {
    const { pakage } = this.data.app;
    const packageFilter = new FilterableValue("pakage", pakage, TYPE_FILTER.String, OPERATION_FILTER.EQUALS);
    const request = new FilterAndPagBean(null, null, [packageFilter], 0, 0, 0);

    // Llama al servicio para obtener los datos del backend
    const response = await this.appService.find(request)
    if (response && response.data?.length) {
      const dataAppVersion: Aplications[] = response.data;
      const lastApp = dataAppVersion.find((app)=> app.isLast);
      this.loadFormData(lastApp ? lastApp : dataAppVersion[0]);
      this.versions = [...dataAppVersion];
    }
  }

  
  async loadVersionData() {
    const { id } = this.data.app;
    // Llama al servicio para obtener los datos del backend
    const response = await this.appService.get(id);
    if (response && response.data) {
      this.loadFormData(response.data);
      this.versions = [...[response.data]];
    }
  }


  // Lógica para iniciar el proceso de agregar una nueva versión
  startNewVersion() {
    this.isAddingVersion = true;
    this.showDropZone = true; // Mostrar la zona de carga de archivos
    this.secondFormGroup.reset(); // Reiniciar el formulario
    this.selectedFile = null; // Limpiar el archivo seleccionado
    this.isFileSelected = false; // Indicar que no hay archivo seleccionado
  }
  


  onSave(): void {
    // Validamos ambos formularios
    if (this.firstFormGroup.valid && (this.isAddingVersion || this.versions.length > 0)) {

      // Crea el objeto FirmwareUpload para enviar al backend
      let objNew: FirmwareUpload = new FirmwareUpload();
      objNew.id = this.data.app.id;

      const { name, description } = this.firstFormGroup.value;

      // Creamos el objeto Aplications combinando los datos de ambos formularios
      const appNew: Aplications = new Aplications;
      appNew.name = name;
      appNew.description = description;
      appNew.dateCreate = this.data.app.dateCreate;
      appNew.pakage = this.data.app.pakage;
      appNew.version = this.data.app.version;
      appNew.base64 = this.data.app.base64;
      appNew.fileName = this.data.app.fileName;


      let lstAppNew: Aplications[] = [appNew];
      objNew.fileList = lstAppNew;

      this.saveToBackend(objNew);

    } else {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
    }
  }

  saveToBackend(objNew: FirmwareUpload): void {
    this.appService.addUpdate(objNew).then(() => {
      this.notifier.notify('success', this.translate.instant('success.appSaved'));
      this.dialogRef.close(FormCreateActions.SAVED);
    })
  }

  // Método para cargar los datos en el formulario
  loadFormData(data: Aplications): void {
    this.firstFormGroup.patchValue({
      name: data.name,
      description: data.description,
      packageName: data.pakage,
      dateCreate: data.dateCreate,
      dateUpdate: data.dateUpdate,
    });
    this.packageName = data.pakage;
    this.currentVersion = data.version;
    this.firstFormGroup.markAsDirty();
  }

  // Método que se llama cuando se abre el diálogo de edición
  openEditDialog(selectedApp: Aplications): void {
    this.data.app = selectedApp; // Asigna el registro seleccionado
    this.loadFormData(this.data.app); // Carga los datos en el formulario
    this.dialog.open(EditAppComponent, {
      data: this.data // Pasa los datos al diálogo
    });
  }

  extractVersion(fileName: string): string {
    const versionPattern = /(\d+\.\d+\.\d+[-\w]*)/; // para encontrar la versión
    const match = fileName.match(versionPattern);
    return match ? match[0] : ''; // Devuelve la versión si se encuentra, sino devuelve una cadena vacía
  }

  addVersion(): void {
    this.showDropZone = true;
  }

  removeVersion(id: number): void {
    if (this.versions.length <= 1) {
      this.notifier.notify('warning', this.translate.instant('warning.singleVersionRequired'));
      return;
    }
  
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteVersionTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteVersionText');
  
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });
  
    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.appService.deleteVersion(id);
        if (response?.status === 0) { // Verificar el estado de respuesta
          this.versions = this.versions.filter(version => version.id !== id);
          this.notifier.notify('success', this.translate.instant('success.versionDeleted'));
        }
      }
    });
  }
  

  onDelete(id: number): void {
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteAppTitle'); // "Delete App"
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteAppText'); // "Are you sure you want to delete this app?"

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%',
      panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        const response = await this.appService.delete(id);
        if (response?.status === 0) {
          this.notifier.notify('success', this.translate.instant('success.appDeleted'));
          this.dialogRef.close(FormCreateActions.SAVED)
        }
      }
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  mapFileToBase64(file: File): void {
    convertFileToBase64(file).then((base64String) => {
      if (!base64String) {
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        return;
      }
  
      this.selectedFile = file;
      this.secondFormGroup.patchValue({
        uploadFiles: this.selectedFile,
        fileBase64: base64String,
        version: this.extractVersion(file.name) // Extraer versión del nombre del archivo
      });
      this.secondFormGroup.get('version')?.enable(); // Habilitar el campo de versión
      this.isFileSelected = true;
      this.showDropZone = false;
  
      this.showDropZone = false; // Ocultar la zona de carga si se ha seleccionado un archivo
    }).catch(() => {
      this.notifier.notify('warning', this.translate.instant('error.fileInvalid'));
    });
  }
  
  // Método que se llama cuando se selecciona un archivo
  async onFileSelected(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
  
      // Verificar si la versión ya existe en la lista de versiones
      const version = this.extractVersion(file.name);
      if (this.isVersionDuplicated(version)) {
        this.notifier.notify('warning', this.translate.instant('warning.duplicateVersion'));
        return;
      }
  
      //  mapear el archivo a Base64 si la versión es válida y no está duplicada
      this.mapFileToBase64(file);
  
      // Reiniciar el input de archivo para permitir seleccionar el mismo archivo de nuevo
      input.value = ''; 
    }
  }
  


  // Método para manejar el drop de archivos
  async onFileDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];

      // Verificar si la versión ya existe en la lista de versiones
      const version = this.extractVersion(file.name);
      if (this.isVersionDuplicated(version)) {
        this.notifier.notify('warning', this.translate.instant('warning.duplicateVersion'));
        return;
      }

      // Proceder a mapear el archivo a Base64 si la versión es válida y no está duplicada
      this.mapFileToBase64(file);
    }
  }


  // Método auxiliar para verificar si la versión ya existe
  private isVersionDuplicated(version: string): boolean {
    return this.versions.some(existingVersion => existingVersion.version === version);
  }

  // Método que maneja la subida y confirmación de la nueva versión
  async onNewVersion(): Promise<void> {
    // Verificar que haya un archivo y que cumpla con los requisitos de nombre
    const { fileBase64, version } = this.secondFormGroup.value;

    if (!fileBase64 || !this.selectedFile) {
      this.notifier.notify('warning', this.translate.instant('warning.fileUploadValidation'));
      return;
    }


    // Validar si el formulario es válido
    if (this.secondFormGroup.invalid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      return;
    }

    const { name, description, pakage } = this.data.app;

    // Crear el objeto de aplicación (Aplications) basado en la nueva versión
    const appNew: Aplications = new Aplications;
    appNew.name = name;
    appNew.description = description;
    appNew.pakage = pakage;
    appNew.version = version.trim();
    appNew.base64 = fileBase64;
    appNew.fileName = this.selectedFile ? this.selectedFile.name : '';

    // Crear un objeto FirmwareUpload para hacer la actualización
    let objNew: FirmwareUpload = new FirmwareUpload();
    objNew.fileList = [appNew]; // Añadimos la nueva versión a la lista de archivos

    try {
      // Verificar si ya existe una versión de la aplicación
      const existingVersionIndex = this.versions.findIndex(versionApp =>
        versionApp.version === version && versionApp.pakage === this.data.app.pakage && versionApp.id === this.data.app.id
      );

      if (existingVersionIndex >= 0) {
        // Si existe, reemplazar el registro de la versión existente
        this.versions[existingVersionIndex] = appNew;
      } else {
        // Si no existe, añadirla al inicio
        this.versions.unshift(appNew);
      }

      // Actualizamos en el backend la nueva versión
      await this.appService.addUpdate(objNew);

      // Actualizar dataSource para reflejar el cambio
      this.notifier.notify('success', this.translate.instant('success.versionSaved'));

      // Reiniciar los estados y limpiar el formulario
      this.isAddingVersion = false;
      this.showDropZone = false;
      this.selectedFile = null; // Limpiar archivo seleccionado

    } catch (error) {
      this.notifier.notify('error', `Error al actualizar`);
    }
  }


  isValidFileType(file: File): boolean {
    return true;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  // Método para borrar el archivo seleccionado
  deleteFile(): void {
    this.selectedFile = null;
    this.secondFormGroup.patchValue({ fileBase64: null, version: null });
    this.secondFormGroup.get('version')?.disable(); // Deshabilitar el campo de versión
    this.packageName = '';
  }

  onCheckboxChange(event: MatCheckboxChange): void {
    this.isFileSelected = event.checked;
  }

  formatFileSize(size: number): string {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    else return `${(size / 1048576).toFixed(2)} MB`;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  downloadVersion(version:Aplications): void {
    if(version.base64){
      downloadFile(version.fileName,version.base64);
    } else {
      this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
    }
       
  }
}
