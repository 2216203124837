import { Inject, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { parseStringToUTCDate } from 'src/app/util/util';

@Pipe({
    standalone:true,
    name: 'dateOnTimeZone',
})
export class DateOnTimeZonePipe implements PipeTransform {
  private authService  = Inject(AuthService);
  private timeZone = this.authService.timeZone;
  private lang = this.authService.language;

  transform(value:string, uselang?:boolean): string {
    if(!value) return "";
    const lang = (uselang ? this.lang:"en-US");
    const localDate = parseStringToUTCDate(value).toLocaleString(lang, { timeZone: this.timeZone });  
    return localDate;
  }
}
