
import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { FilterAndPagBean, FilterableValue, OPERATION_FILTER } from 'src/app/models/FilterAndPagBean';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { NotifierService } from 'angular-notifier';
import { AppsService } from './aplications.service';
import { Parameters } from 'src/app/models/parameters.model';
import { AuthService } from 'src/app/auth/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { NewAppComponent } from './new-app/new-app.component';
import { TabsComponent } from '../../monitoring/tabs/tabs.component';
import { Aplications } from 'src/app/models/aplications.model';
import { EditAppComponent } from './edit-app/edit-app.component';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { Operator } from 'src/app/models/operator.model';
import { RolHandlerService } from 'src/app/services/rol-handler.service';


enum Columns {//en el orden que se quiera mostrar
  operator = 'operator',
  name = 'name',
  dateCreate = 'dateCreate',
  dateUpdate = 'dateUpdate',
  pakage = 'pakage',
  version = 'version',
  description = 'description',
  view = 'view'
}

const FILTER_COLUMNS: { field: string, type: FieldType, options?: FilterOption[] }[] = [
  { field: 'name', type: 'string' },
  { field: 'dateCreate', type: 'date' },
  { field: 'dateUpdate', type: 'date' },
  { field: 'version', type: 'string' },
  { field: 'description', type: 'string' },
]


@Component({
  selector: 'app-aplications',
  templateUrl: './aplications.component.html',
  styleUrls: ['./aplications.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class AplicationsComponent implements OnInit, AfterViewInit {
  displayedColumns = [...Object.keys(Columns)];
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    return new FilterField(column.field, `aplications.${column.field}`, column.type, column.options);
  });
  private notifier: NotifierService;
  exportFileName: string | undefined;
  dataSource = new MatTableDataSource<Aplications>();
  operatorsList: Operator[] = [];

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.dateCreate + "";
  direccion: string | null = "DESC";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  inactiveTerminals = 0;
  isAdmin: boolean = true;

  filterList: FilterableValue[] = [];
  private dialogRef!: MatDialogRef<NewAppComponent>;
  private dialogMonitoringRef!: MatDialogRef<TabsComponent>;

  ///filtros
  nameFilter = new FormControl();
  dateStartFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, notifier: NotifierService,
    private aplications: AppsService,
    private utilServicios: UtilService,
    public dialog: MatDialog, private authService: AuthService,
    private translate: TranslateService,
    public rol:RolHandlerService,
    public commonService: CommonService) {
    this.notifier = notifier;
  }

  private exitsFilterableValue(column: string): boolean {
    return this.filterList.some((filter) => filter.column === column);
  }

  async lanzarLLamada() {
    const existIdVM = this.exitsFilterableValue('idVM');
    const existIdOerator = this.exitsFilterableValue('idOperator');
    const existIsLast = this.exitsFilterableValue('isLast');

    if (this.data?.obj && !existIdVM) {
      const idVM = this.data.obj.id;
      this.filterList.push(new FilterableValue("idVM", idVM, "long", OPERATION_FILTER.EQUALS));
    }

    let userSession = this.authService.currentUserValue();
    if (userSession?.operator && !existIdOerator) {
      this.filterList.push(new FilterableValue("idOperator", userSession.operator.id + "", "long", OPERATION_FILTER.EQUALS));
    }

    if(!existIsLast) this.filterList.push(new FilterableValue("isLast", "true", "boolean",  OPERATION_FILTER.EQUALS));

    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    const request = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );

    let value = await this.aplications.find(request);
    let pagCount = await this.aplications.countTotal(request);
    if (value) {
      this.dataSource = new MatTableDataSource<Aplications>(value.data.map((app:Aplications)=>{
        const operator = this.operatorsList.find((op)=> op.id === app.idOperator);
        app.operator = operator?.name ?? ''; 
        return app
      }));
      this.dataSource.paginator = this.paginator;  // Verificar que paginator esté bien asignado
      this.dataSource.sort = this.sort;            // Verificar que sort esté bien asignado
      if (pagCount) {
        this.pageLength = pagCount.data?.total ?? 0;
      }
    }
  } catch () {
    console.error("Error en lanzarLLamada:");
    this.notifier.notify('error', 'Error al cargar los datos');
  }

  private loadOperators(){
    this.utilServicios.findOperators().subscribe((operators)=> this.operatorsList = operators);
  }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.exportFileName = this.translate.instant('export.fileApps');
    this.loadOperators();
    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    if (this.paginator && this.sort) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.lanzarLLamada();  // Asegurarse de lanzar llamada solo cuando paginator y sort están listos
    }
  }
  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  resetPaginatorState() {
    this.paginator.firstPage();
  }

  refrescar() {
    this.lanzarLLamada();
  }
  
  pageEvent() {
    this.lanzarLLamada();
  }
  
  announceSortChange(sortState: Sort) {
    this.sortId = sortState.active.replace("_", ".");  // Asegura que el sortId sea correcto
    this.direccion = sortState.direction ? sortState.direction.toUpperCase() : null;
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  onView(row: any) {
    this.dialogMonitoringRef = this.dialog.open(TabsComponent, {
      width: '90%', height: '95%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });

    this.dialogMonitoringRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  async onNew() {
    const newDialogRef: MatDialogRef<NewAppComponent> = this.dialog.open(NewAppComponent, {
      width: '600px',
      panelClass: 'custom-modalbox-big',
      data: {
        vm: this.data.obj
      }
    });

    newDialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        this.notifier.notify('success', this.translate.instant('success.appCreated'));        
        this.lanzarLLamada();
      } 
    });
  }


  async onEdit(row: Aplications) {
    const editDialogRef: MatDialogRef<EditAppComponent> = this.dialog.open(EditAppComponent, {
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
      data: { app:row, readOnly:false }
    });

    editDialogRef.afterClosed().subscribe((result) => {
      if (result === 1)  this.lanzarLLamada();
    });
  }


  async onClose(row: Parameters) {
    this.dialogRef = this.dialog.open(NewAppComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        incidencia: row, vm: this.data.obj, close: true
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) this.lanzarLLamada();
    });

  }
}
