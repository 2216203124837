import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { Aplications } from 'src/app/models/aplications.model';
import { Parameters } from 'src/app/models/parameters.model';
import { PerfilApp } from 'src/app/models/perfilApp.model';
import { EditAppComponent } from '../../aplications/edit-app/edit-app.component';
import { EditParamComponent } from '../../param/edit-param/edit-param.component';
import { ListRelatedExtraComponent } from '../list-related-extra/list-related-extra.component';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

interface RelatedApp {
  idApp: number; 
  appName?:string;
  appVersion?:string
  idParam?: number;
  paramName?:string;
  paramExtra?: string;
}

@Component({
  standalone:true,
  imports:[CommonModule, MatIconModule, MatLegacyButtonModule, ListRelatedExtraComponent, TableEmptyStateComponent],
  selector: 'app-list-related-apps',
  templateUrl: './list-related-apps.component.html',
  styleUrls: ['./list-related-apps.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class ListRelatedAppsComponent implements OnChanges{
  @Input() idProfile?:number;
  @Input('data') listedApps:PerfilApp[] = [];
  @Input() apps:Aplications[] = [];
  @Input() parameters:Parameters[] = [];
  @Output() delete = new EventEmitter();

  relatedApps:RelatedApp[] = []; 

  constructor(
    private dialog: MatLegacyDialog,
    public rol:RolHandlerService
  ){}

  ngOnChanges(): void {
    this.relatedApps = this.listedApps.map((perfilApp:PerfilApp)=>{
      const app = this.apps.find((app)=>{return app.id === perfilApp.idApp});
      const param = this.parameters.find((param)=>{return param.id === perfilApp.idParam}); 
      const relatedApp:RelatedApp = {
        idApp: perfilApp.idApp,
        appName: app?.name,
        appVersion: app?.version,
        idParam: perfilApp.idParam,
        paramName: param?.name,
        paramExtra: perfilApp.paramExtra 
      }
      return relatedApp;
    })
  }

  openParamExtra(relatedApp:RelatedApp){
    this.dialog.open(ListRelatedExtraComponent,{
      data:{ idProfile: this.idProfile, idApp:relatedApp.idApp },
      width: '600px',
      panelClass: 'custom-modalbox-big'
    })
  }

  openAppDetail(relatedApp:RelatedApp){
    this.dialog.open(EditAppComponent,{
      data:{ app:{ id:relatedApp.idApp, name:relatedApp.appName }, readOnly:true },
      backdropClass: 'custom-edit-panel-backdrop',
      panelClass: 'edit-panel',
    })
  }

  openParamDetail(relatedApp:RelatedApp){
    this.dialog.open(EditParamComponent,{
        data:{ param:{ id:relatedApp.idParam, name:relatedApp.paramName }, readOnly:true },
        backdropClass: 'custom-edit-panel-backdrop',
        panelClass: 'edit-panel',
    });
  }

  deleteItem(index:number){
    this.delete.emit(index);
  }

}
