import { Component, OnInit } from '@angular/core';
import packageJson from '../../../../package.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../welcome/login.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Users } from 'src/app/models/users.model';
import { LangTypes, LanguageService } from 'src/app/services/language.service';
import { UtilService } from 'src/app/services/util.service';
import { ComponentsStateService } from 'src/app/services/components-state.service';

@Component({
  selector: 'app-pages-login',
  templateUrl: './pages-login.component.html',
  styleUrls: ['./pages-login.component.css']
})
export class PagesLoginComponent implements OnInit {

  
  registerForm!: FormGroup;
  appVersion!:string;

  constructor(private formBuilder: FormBuilder, 
    private router: Router,
    private authService:AuthService,
    private services: LoginService,
    private languageService:LanguageService,
    private utilsService:UtilService,
    private componentsState:ComponentsStateService
  ) { 
    this.appVersion = packageJson.version;

  }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      password: ["", Validators.compose([Validators.required, 
       // Validators.minLength(6),
        //Validators.maxLength(255),
        //Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
      ])]      ,     
      username: ["", Validators.compose([Validators.required,
        //Validators.minLength(6),
        //Validators.maxLength(255),
        //Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
      ])]  ,
    });

  }




  async onFormSubmit(): Promise<void> {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let user=data['username']
    let pass=data['password']

    let tokenSession =  await this.services.login(user,pass)

    if(tokenSession?.token){


      let userSession:Users;
      userSession =  await this.services.getUserInfo(user,tokenSession?.token)
      
      if(userSession){        
        this.authService.guardarToken(tokenSession?.token);
        this.authService.setCurrentUserValue(userSession);
        this.languageService.setLanguage(userSession.lang as LangTypes);
        this.componentsState.updateLabelsState();
        this.utilsService.getRolsAndOperators();
      } 
    }
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


}
