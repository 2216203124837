import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesLoginComponent } from './pages/pages-login/pages-login.component';
import { PagesRegisterComponent } from './pages/pages-register/pages-register.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { AlertsComponent as AlertasComponent} from './pages/views/support/alerts/alerts.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';

import { AuthGuard } from './auth/auth-guard.service';

import { MonitoringComponent } from './pages/views/monitoring/monitoring.component';
import { SensoresComponent } from './pages/views/monitoring/tabs/sensores/sensores.component';
import { PendingTasksComponent } from './pages/comun/pending-tasks/pending-tasks.component';
import { TabsComponent } from './pages/views/monitoring/tabs/tabs.component';
import { SendFileComponent } from './pages/comun/send-file/send-file.component';
import { IncidencesComponent } from './pages/views/support/incidences/incidences.component';
import { OperatorComponent } from './pages/admin/operator/operator.component';
import { ClientComponent } from './pages/admin/client/client.component';
import { GroupsComponent } from './pages/views/config/groups/groups.component';
import { CustomAlertComponent } from './pages/views/config/custom-alert/custom-alert.component';
import { PendingTaskComponent } from './pages/views/config/pending-task/pending-task.component';
import { SendFileMaintenaceComponent } from './pages/views/maintenance/send-file-maintenace/send-file-maintenace.component';
import { StadisticFunctionallyComponent } from './pages/views/stadistic/stadistic-functionally/stadistic-functionally.component';
import { StadisticStateComponent } from './pages/views/stadistic/stadistic-state/stadistic-state.component';
import { AdminGuard } from './auth/admin-guard';
import { GetFileComponent } from './pages/views/maintenance/get-file/get-file.component';
import { AplicationsComponent } from './pages/views/config/aplications/aplications.component';
import { ParamComponent } from './pages/views/config/param/param.component';
import { PerfilesComponent } from './pages/views/config/profile/perfiles.component';
import { SubClientComponent } from './pages/admin/sub-client/sub-client.component';
import { HistoryUserComponent } from './pages/views/maintenance/history-user/history-user.component';
import { RolComponent } from './pages/admin/rol/rol.component';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  
  { path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuard] },
  { path: 'sensores', component: SensoresComponent, canActivate: [AuthGuard] },
  { path: 'incidenceMonitoring', component: IncidencesComponent, canActivate: [AuthGuard] },
  { path: 'pendingMonitoring', component: PendingTasksComponent, canActivate: [AuthGuard] },
  { path: 'getFile', component: GetFileComponent, canActivate: [AuthGuard] },
  { path: 'tabsMonitoring', component: TabsComponent, canActivate: [AuthGuard] },
  { path: 'sendFileComponent', component: SendFileComponent, canActivate: [AuthGuard] },
  { path: 'alerts', component: AlertasComponent, canActivate: [AuthGuard]},
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard,], data: {authorg:'authserver',authkey:'user'} },  
  { path: 'operator', component: OperatorComponent, canActivate: [AuthGuard,AdminGuard] },
  { path: 'rol', component: RolComponent, canActivate: [AuthGuard, AdminGuard] },
  { path: 'client', component: ClientComponent, canActivate: [AuthGuard] },
  { path: 'subClient', component: SubClientComponent, canActivate: [AuthGuard] },
  { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard]},
  { path: 'aplications', component: AplicationsComponent, canActivate: [AuthGuard]},
  { path: 'parameters', component: ParamComponent, canActivate: [AuthGuard]},
  { path: 'profile', component: PerfilesComponent, canActivate: [AuthGuard]},
  { path: 'customAlert', component: CustomAlertComponent, canActivate: [AuthGuard]},
  { path: 'pendingTask', component: PendingTaskComponent, canActivate: [AuthGuard] },
  { path: 'sendFile', component: SendFileMaintenaceComponent, canActivate: [AuthGuard] },
  { path: 'history-user', component: HistoryUserComponent, canActivate: [AuthGuard] },
  //configure as lazy loading component
  { path: 'commands', loadComponent:() => import('./pages/views/maintenance/commands/commands.component')
    .then(mod=> mod.CommandsComponent),canActivate: [AuthGuard] },  
  { path: 'stadistic_funcional', component: StadisticFunctionallyComponent, canActivate: [AuthGuard] },
  { path: 'stadistic_state', component: StadisticStateComponent, canActivate: [AuthGuard] },
  { path: 'signup', component: PagesRegisterComponent},
  { path: 'pages-login', component: PagesLoginComponent },
  { path: 'pages-register', component: PagesRegisterComponent },
  { path: 'user-profile', component: UsersProfileComponent },
  { path: '**', redirectTo: 'welcome' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
