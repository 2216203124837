<div class="pagetitle">
    <h2>{{ 'profiles.terminals' | translate }}</h2>
</div>

<app-filter [fields]="filterColumns" (onApply)="onFilterApply($event)"></app-filter>
<div class="related-machines">
    <div id="tableDescription" style="display:none;">{{ 'vendingMachine.tableDescription' | translate }}</div>
    <table class="table related-machines-table" aria-labelledby="tableDescription">
        <thead class="related-machines-thead">
            <tr>
                <th scope="col" class="expanded-column">{{ 'vendingMachine.terminalSN' | translate }}</th>
                <th scope="col">{{ 'vendingMachine.modelNumber' | translate }}</th>
                <th scope="col">{{ 'vendingMachine.manufacID' | translate }}</th> 
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody class="related-machines-tbody">
            <tr *ngFor="let vm of machines">
                <td class="related-machines-cells expanded-column">{{ vm.serialNumber }}</td>
                <td class="related-machines-cells">{{ vm.modelNumber }}</td>
                <td class="related-machines-cells">{{ vm.manufacID }}</td> 
                <td class="related-machines-cells">
                    <ng-container *ngIf="rol.canByInput(rol.functIds.delete_profiles)">
                        <button mat-icon-button (click)="delete(vm)">
                            <mat-icon mat-list-icon class="delete-icon">delete_outline</mat-icon>
                        </button>   
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
    
    <!-- Empty state -->
    <ng-container *ngIf="!machines.length">
        <app-table-empty-state [icon]="false"></app-table-empty-state>
    </ng-container>

    <!-- Paginador -->
    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent()" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
</div>

<div class="botonera mt-3">
    <button mat-stroke-button class="custom-white-button" (click)="cancelChanges()">
        {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button class="custom-add-button" (click)="submitChanges()">
        {{ 'common.submit' | translate }}
    </button>
</div>