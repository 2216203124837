import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { FormCreateActions, LEVEL_HIERARCHY } from 'src/app/util/constants';
import { NotifierService } from 'angular-notifier';
import { ClientService } from '../client.service';
import { Client } from 'src/app/models/client.model';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyHandlerService } from 'src/app/services/hierarchy-handler.services';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-form-new-client',
  templateUrl: './form-new-client.component.html',
  styleUrls: ['./form-new-client.component.css']
})
export class FormNewClientComponent implements OnInit {
  registerForm!: FormGroup;
  listCommerce: string[] = [];
  operators: Operator[] = []; // Lista de operadores como objetos
  isAdmin: boolean = true;  // Control de visibilidad para perfil ADMIN

  private clienteBBDD: Client | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { clientId: number },
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: ClientService,
    private utilServicios: UtilService,
    private translate:TranslateService,
    public hierarchy:HierarchyHandlerService,
    public rol:RolHandlerService,
    public dialogRef: MatDialogRef<FormNewClientComponent>,
    public dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    // Obtener el rol del usuario actual
    this.isAdmin = this.utilServicios.isAdmin();
    // Inicializar el formulario
    this.registerForm = this.formBuilder.group({
      name: [null, Validators.required],
      cod: [null],
      cif:[null],
      localidad:[null],
      codPostal:[null],
      web:[null],
      email:[null],
      phone:[null],
      personContact:[null],
      idOperator:[this.utilServicios.getOperatorId(),Validators.required]
    });

     // Cargar operadores si es admin
    if (this.isAdmin){
      this.registerForm.get('idOperator')?.enable(); 
      await this.loadOperators();
    }
    
    if (this.data) this.cargar()
  }

  async cargar(): Promise<void> {
    this.clienteBBDD = await this.service.getItem(this.data.clientId);
    if(this.clienteBBDD){
      this.registerForm.patchValue({
        name: this.clienteBBDD.name,
        cod: this.clienteBBDD.cod,
        cif:this.clienteBBDD.cif,
        localidad: this.clienteBBDD.localidad,
        codPostal: this.clienteBBDD.codPostal,
        web: this.clienteBBDD.web,
        email: this.clienteBBDD.email,
        phone: this.clienteBBDD.phone,
        personContact: this.clienteBBDD.personContact,
        idOperator: this.clienteBBDD.idOperator
      });
    }
  }

  // Método que carga operadores reales
  private async loadOperators(): Promise<void> {
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      this.operators = operators; // Almacena la lista de operadores
    });
  }

  onFormSubmit(): void {
    if (this.registerForm.invalid) return;
    
    const data = this.registerForm.value;
    let objNew: Client = new Client();
    objNew.name = data.name;
    objNew.cod = data.cod;
    objNew.cif = data.cif;
    objNew.localidad = data.localidad;
    objNew.codPostal = data.codPostal;
    objNew.web = data.web;
    objNew.email = data.email;
    objNew.phone = data.phone;
    objNew.personContact = data.personContact;
    objNew.idOperator = this.utilServicios.getOperatorId() as number;
    
    if (this.isAdmin) objNew.idOperator = data.idOperator;  // Agregar operador seleccionado si es ADMIN
    if (this.clienteBBDD) objNew.id = this.clienteBBDD.id;

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.createClientTitle'); 
    dialogData.textI18n = this.translate.instant('confirmDialog.createClientText'); 

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) this.saveClient(objNew);
    });
  }

  async saveClient(objNew:Client){
    const val = await this.service.addUpdate(objNew);
    if (val!.status >= 0) {
      this.notifier.notify('success', this.translate.instant('success.clientSaved'));
      this.dialogRef.close(FormCreateActions.SAVED);
    }
  }

  formExit(): void {
    this.dialogRef.close(FormCreateActions.EXIT);
  }

}
