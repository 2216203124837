interface TerminalModel {
    id:number;
    name:string;
    idFabricante:number;
}

interface Factory {
    id:number;
    name:string;
    listModelos: TerminalModel[]
}

export class Factories {
    _list:Factory[];

    constructor(list:Factory[]){
        this._list = list;
    }

    private isAValidName(name:string, compareName:string){
        return (name.toUpperCase() === compareName.toUpperCase());
    }

    private isValidModelName(factory:Factory, modelName:string){
        return factory.listModelos.some((item)=> this.isAValidName(modelName, item.name));
    }

    public isAValidFactoryAndModel(factoryName:string, modelName:string){
        return this._list.some((item)=> (this.isAValidName(factoryName, item.name) && this.isValidModelName(item, modelName)));
    }

}