import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NotifierService } from 'angular-notifier';
import { Parameters } from 'src/app/models/parameters.model';
import { ParamService } from '../param.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FormCreateActions } from 'src/app/util/constants';
import { convertFileToBase64, downloadFile } from 'src/app/util/util';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

@Component({
  selector: 'app-edit-param',
  templateUrl: './edit-param.component.html',
  styleUrls: ['./edit-param.component.css']
})
export class EditParamComponent implements OnInit {

  form!: FormGroup;
  @Input() dataSource = new MatTableDataSource<Parameters>();
  isLinear: BooleanInput;
  showDropZone = false;
  uploadProgress = 0;
  selectedFile: File | null = null;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  isOpen: { [key: string]: boolean } = {
    details: true,
    dates: true,
    fileUpload: true,
  };

  readOnly:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { param: Parameters, readOnly:boolean},
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private paramService: ParamService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditParamComponent>,
    public rol:RolHandlerService
  ) {
    this.readOnly = !!data.readOnly;
    this.form = this.formBuilder.group({
      name: [{value:data.param.name, disabled:this.readOnly}, Validators.required],
      dateCreate: [{ value: data.param.dateCreate, disabled: true }],
      dateUpdate: [{ value: data.param.dateUpdate, disabled: true }],
      version: [data.param.version],
      description: [{value:data.param.description, disabled:this.readOnly}, Validators.compose([Validators.required, Validators.maxLength(400)])],
      fileName: [data.param.fileName],
      base64: [data.param.base64]
    });
  }

  ngOnInit(): void {
    // Obtener los datos del backend usando el id del parámetro
    this.paramService.get(this.data.param.id).then(response => {
      if (response && response.status === 0 && response.data) {
        // Carga los datos recibidos del backend en el formulario
        const paramData = response.data;
        this.form.patchValue({
          name: paramData.name,
          description: paramData.description,
          version: paramData.version,
          dateCreate: paramData.dateCreate,
          dateUpdate: paramData.dateUpdate,
          fileName:paramData.fileName,
          base64:paramData.base64
        });

        this.form.markAsDirty();
        // Establece el archivo del backend
        if (paramData.base64 && paramData.fileName) {
          this.selectedFile = new File([paramData.base64], paramData.fileName, { type: 'application/octet-stream' });
        }
      } else {
        this.onCancel();
      }
    });
  }


  onDelete(): void {
    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant('confirmDialog.deleteParamTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.deleteParamText');
  
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', 
      panelClass: 'custom-modalbox',
      data: dialogData
    });
  
    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        try {
          await this.paramService.delete(this.data.param.id);
          this.dialogRef.close(1);
          /*this.notifier.notify('success', this.translate.instant('confirmDialog.profileDeleted')); // "Deleted successfully"*/
        } catch (error) {
          const errorMessage = (error as Error).message || 'Unknown error';
          this.notifier.notify('error', `${this.translate.instant('confirmDialog.deleteProfile')} ${errorMessage}`); // "Error deleting: "
        }
      }
    });
  }

  toggleDropZone(): void {
    this.showDropZone = !this.showDropZone;
  }
  toggleSection(section: string): void {
    this.isOpen[section] = !this.isOpen[section];
  }

  private mapFileToBase64(file:File){
    // Convertir el archivo a base64 y actualizar el formulario
    convertFileToBase64(file).then(base64String => {
      if(!base64String){
        this.notifier.notify('warning', this.translate.instant('error.fileEmpty'));
        return;
      }
      this.selectedFile = file;
      this.form.patchValue({ fileName: this.selectedFile.name});
      this.form.patchValue({ base64: base64String});
      this.form.markAsDirty();
      this.showDropZone = false; // Oculta la zona de arrastre
    }).catch(error => {
      this.notifier.notify('warning', this.translate.instant('error.fileInvalid'))
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.mapFileToBase64(file);
    }
  }

  onFileDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.mapFileToBase64(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
  }

  deleteFile(): void {
    this.selectedFile = null;
    this.form.get('fileName')?.reset();
    this.form.get('base64')?.reset();
    this.uploadProgress = 0;
  }

  uploadFile(file: File): void {
    const uploadInterval = setInterval(() => {
      this.uploadProgress += 10;
      if (this.uploadProgress >= 100) {
        clearInterval(uploadInterval);
      }
    }, 500);
  }

  onSave(): void {
    if (!this.form.valid) {
      this.notifier.notify('warning', this.translate.instant('warning.formValidation'));
      console.warn('Form is not valid:', this.form.errors); // Log si el formulario no es válido
      return;
    }
    
    // Extrae los valores del formulario
    const formData = this.form.value;
    
    // Asigna los valores al objeto Parameters que se enviará
    const updatedParam: Parameters = new Parameters;

    updatedParam.name = formData.name;
    updatedParam.dateCreate = this.data.param.dateCreate;
    updatedParam.description = formData.description;
    updatedParam.version = formData.version;
    updatedParam.id = this.data.param.id;
    updatedParam.base64 = formData.base64
    updatedParam.fileName = formData.fileName;
    
    // Verifica si hay un archivo seleccionado
    if (this.selectedFile) {
      convertFileToBase64(this.selectedFile).then(base64File => {
        updatedParam.base64 = formData.base64;
        updatedParam.fileName = formData.fileName;

        // Llama al servicio para agregar/actualizar el archivo
        return this.paramService.addUpdate(updatedParam);
      }).then(() => {
        this.dialogRef.close(FormCreateActions.SAVED);
      }).catch(error => {
        this.dialogRef.close(FormCreateActions.EXIT);    
        this.notifier.notify('error', `Error al actualizar: ${error.message}`);
      });
    } else {
      this.paramService.addUpdate(updatedParam).then(() => {
        this.dialogRef.close(FormCreateActions.SAVED);
      }).catch(error => {
        this.notifier.notify('error', `Error al actualizar: ${error.message}`);
        this.dialogRef.close(FormCreateActions.EXIT);     
      });
    }
  
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  public downloadVersion(): void {
    const { fileName, base64 } = this.form.value;
    if (base64) {
      downloadFile(fileName, base64);
    } else {
      this.notifier.notify('error', this.translate.instant('error.errorOnDownload'));
    }
  }
}
