<div class="profile-app">
    <div class="profile-app-header">
        <p>{{ selectedApp.appName }} ({{ selectedApp.version }})</p>

        <!-- Delete button -->
        <button mat-icon-button class="delete-button-apps" (click)="removeApp(selectedApp)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="profile-app-form">
        <div class="mb-3 w-50">
            <label for="paramSelect" class="form-label">{{ 'newProfile.selectParameter' | translate }}</label>
            <select class="form-select" aria-label="Parameters select" (change)="onParameterSelect($event, selectedApp)">
                <option selected></option>
                <option *ngFor="let param of parameters" [value]="param.id">{{ param.name }}</option>
            </select>
        </div>

        <div class="select-file w-50">
            <!-- File upload -->
            <div *ngIf="selectedApp.paramExtra">
                <label for="fileSelect" class="form-label">{{ 'common.selectedFile' | translate }}</label>
                <div class="selected-file">
                    <p> {{ selectedApp.fileName}}</p>
                    <button mat-icon-button color="warn" (click)="removeFileApp()">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </div>
            </div>

            <div *ngIf="!selectedApp.paramExtra">
                <label for="fileSelect" class="form-label">{{ 'newProfile.uploadCSVFile' | translate }}</label>
                <button class="drop-zone w-100" (drop)="onFilesDrop($event, selectedApp)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                    <mat-icon>cloud_upload</mat-icon>
                    <input id="fileSelect" type="file" (change)="onAppFileSelected($event, selectedApp)" #fileUpload accept=".csv" style="display:none;">
                </button>
            </div>
        </div>
    </div>

</div>