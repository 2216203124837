<div class="header-container">
    <div class="pagetitle">
        <h1>{{ 'parameters.new' | translate }}</h1>
    </div>
</div>

<mat-stepper [linear]="isLinear" #stepper>

    <!-- Paso 1: Detalles -->
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{ 'common.details' | translate }}</ng-template>

            <div class="details-container">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'parameters.name' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="name"
                            placeholder="{{ 'parameters.placeholder.name' | translate }}" required>
                        <mat-error *ngIf="firstFormGroup.get('name')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group-inline">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'parameters.version' | translate }} <span
                                    class="obligatorio"></span></mat-label>
                            <input matInput formControlName="version"
                                placeholder="{{ 'parameters.placeholder.version' | translate }}" required>
                            <mat-error *ngIf="firstFormGroup.get('version')?.hasError('required')">
                                {{ 'error.required.field' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Campo de tipo de archivo-->
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label> {{ 'common.fileType' | translate }}</mat-label>
                            <mat-select formControlName="storageType" required>
                                <mat-option *ngFor="let type of fileTypes" [value]="type">{{ 'maintenace.fileTypes.'+type | translate }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="firstFormGroup.get('storageType')?.hasError('required')">
                                {{ 'error.required.field' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'parameters.description' | translate }} <span
                                class="obligatorio"></span></mat-label>
                        <textarea matInput formControlName="description" rows="4"
                            placeholder="{{ 'parameters.placeholder.description' | translate }}" required></textarea>
                        <mat-error *ngIf="firstFormGroup.get('description')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                        <mat-error *ngIf="firstFormGroup.get('description')?.hasError('maxlength')">{{ 'error.maxLength' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperNext>{{ 'common.next' | translate }}</button>
            </div>
        </form>
    </mat-step>

    <!-- Paso 2: Archivos -->
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>{{ 'common.files' | translate }}</ng-template>

            <div class="terminals-container">

                <div class="file-upload-container">

                    <div class="drop-zone" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)"
                        (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                        <div class="drop-zone-icon">
                            <mat-icon>cloud_upload</mat-icon>
                            <p>{{ 'common.selectFiles' | translate }}</p>
                        </div>
                    </div>

                    <input type="file" class="file-upload" (change)="onFileSelected($event)" #fileUpload
                        style="display: none;" accept="*/*" multiple>

                </div>
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'parameters.path' | translate }} <span class="obligatorio"></span></mat-label>
                        <input matInput formControlName="path" placeholder="{{ 'parameters.path' | translate }}"
                            required>
                        <mat-error *ngIf="firstFormGroup.get('path')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="selectedFile" class="selected-file-info">
                    <div class="file-position">
                        <p> {{ 'common.selectedFile' | translate }}: {{ selectedFile.name }}</p>
                        <button mat-icon-button color="warn" class="delete-button" (click)="deleteFile()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </div>
            </div>

            <div class="button-group">
                <button mat-stroke-button class="custom-white-button" matStepperPrevious>{{ 'common.back' | translate }}</button>
                <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
            </div>
        </form>
    </mat-step>

</mat-stepper>