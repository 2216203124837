import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Profile } from 'src/app/models/profiles.model';
import { PerfilesService } from '../perfiles.service';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { TerminalsSelectorComponent } from '../terminals-selector/terminals-selector.component';


@Component({
  standalone:true,
  imports:[ CommonModule, TranslateModule, MatIconModule, MatButtonModule, TerminalsSelectorComponent ],
  selector: 'app-edit-machines',
  templateUrl: './edit-machines.component.html',
  styleUrls: ['./edit-machines.component.css']
})
export class EditMachinesComponent {
  machines:VendingMachineAtento[] = [];
 
  constructor(private dialog:MatDialog, 
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { profile:Profile }, 
    public dialogRef: MatDialogRef<EditMachinesComponent>,
    private profileService:PerfilesService,
    private notifier:NotifierService,
    private translate:TranslateService
  ){}

  cancelChanges(){
    this.dialogRef.close();
  }

  public async submitChanges(): Promise<void> {
    if (this.machines.length === 0) {
        this.notifier.notify('error', this.translate.instant('warning.terminalIsMissing'));
        return;
    }

    // Confirmar y enviar los datos al backend
    const dialogData = new ConfirmDialogData();
    dialogData.enableCancel = false;
    dialogData.titleI18n = this.translate.instant('confirmDialog.updateProfileTitle');
    dialogData.textI18n = this.translate.instant('confirmDialog.updateProfileText');

    const confirmSave = this.dialog.open(ConfirmDialogComponent, {
        width: '35%', panelClass: 'custom-modalbox',
        data: dialogData
    });

    confirmSave.afterClosed().subscribe(async (response: ConfirmDialogActions) => {
        if (response === ConfirmDialogActions.CONFIRM) {
            await this.updateProfile();
        }
    });
  }

  private async getRelatedListVendingMachines(): Promise<VendingMachineAtento[]>{
    const filterAndPagBean = new FilterAndPagBean(null,null,[],null,null,null);
    const response  = await this.profileService.getVendingMachines(this.data.profile.id, filterAndPagBean);
    if(response && response.data) return response.data;
    return [];
  }

  public async updateProfile() {
    const profile = {...this.data.profile};
    const listVendingMachine = await this.getRelatedListVendingMachines();
    profile.listVendingMachine = [...listVendingMachine, ...this.machines];
    
    const response = await this.profileService.saveTerminals(profile);
    if (response!.status >= 0) this.dialogRef.close({ result: 1 });
  }

  
}
