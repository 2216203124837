import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { VendingMachineAtento } from 'src/app/models/vendingMachineAtento.model';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { MonitoringService } from './monitoring.service';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { FilterAndPagBean, FilterableValue } from 'src/app/models/FilterAndPagBean';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';
import moment from 'moment';
import { TabsComponent } from './tabs/tabs.component';
import { AppliedFilter, FieldType, FilterField, FilterOption } from 'src/app/models/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { parseStringToUTCDate } from 'src/app/util/util';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { RolHandlerService } from 'src/app/services/rol-handler.service';

const BaseColumns = {
  serialNumber: 'serialNumber',
  idAtento: 'idAtento',
  manufacID: 'manufacID',
  modelNumber: 'modelNumber',
  nameOperator: 'nameOperator',
  swRev: 'swRev',
  commerce: 'commerce',
  puntoVenta: 'puntoVenta',
  lastUpdate: 'lastUpdate',
  coverage: 'coverageLevel',
  pending: 'hasPendingDownload',
  view: 'view'
};

const { nameOperator, ...Columns } = BaseColumns;
const ColumnsAdmin = { ...BaseColumns };

const FILTER_COLUMNS:{ field:string, type: FieldType, options?:FilterOption[]}[] = [
  { field:'serialNumber', type:'string' },
  { field:'commerce', type:'string' },
  { field:'softwareRevision', type:'string' },
  { field:'modelNumber', type:'string' },
  { field:'manufacID', type:'string' },
  { field:'puntoVenta', type:'string'},
]
@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css'],
  providers:[{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})

export class MonitoringComponent implements OnInit, AfterViewInit {
  displayedColumns = Object.keys(Columns).filter(column => column !== 'idAtento');
  filterColumns:FilterField[] = FILTER_COLUMNS.map((column)=>{
    const fielterField = new FilterField(column.field, `monitoring.${column.field}`, column.type, column.options);
    return fielterField;
  })

  dataSource = new MatTableDataSource<VendingMachineAtento>();
  isAdmin: boolean = true;
  pageLength = 0;
  inactiveTerminals = 0;
  pageSize = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.idAtento + "";
  direccion: string | null = "ASC";
  showMap = false;
  exportFileName: string | undefined;

  filterList: FilterableValue[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private dialogRef!: MatDialogRef<TabsComponent>;
  
  constructor(private servicios: MonitoringService,
    private utilServicios: UtilService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public translate:TranslateService,
    public rol:RolHandlerService
  ) {}
  
  async lanzarLLamada() {
    const request = new UsersConsoleRequest();
  
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;
  
    request.filterAndPagBean = new FilterAndPagBean(
      this.direccion,
      this.sortId,
      this.filterList,
      startPage,
      endPage,
      pageIndex
    );
  
    let value = await this.servicios.find(request);
    let pagCount = await this.servicios.countTotal(request);
  
    if (value) {
      // Procesar cada elemento para decidir si mostrar los iconos de cobertura
      const dataWithCoverageIcons = value.data.map((item: { coverageLevel: any; coverageType: any; }) => {
        return {
          ...item,
          coverageIcons: {
            showNormalIcons: !!item.coverageLevel && !!item.coverageType,
            tooltip: item.coverageLevel && item.coverageType ? `${item.coverageLevel}% ${item.coverageType}` : null
          }
        };
      });
  
      this.dataSource = new MatTableDataSource<VendingMachineAtento>(dataWithCoverageIcons);
  
      if (pagCount && pagCount.data) {
        this.pageLength = pagCount.data.total ?? 0;
        this.inactiveTerminals = this.pageLength - (pagCount.data.inactiveTerminals !== undefined ? pagCount.data.inactiveTerminals : 0);
      }
    }
  }
  



  async cargar() {
    //operadores
    this.utilServicios.findOperators().subscribe((operators:Operator[]) => {
      if(!operators.length) return;
      const operationsOptions = operators.map((op:Operator)=>{ return { label:op.name, value:(op.id as number) }});
      this.filterColumns.push(new FilterField('idOperator', 'monitoring.nameOperator', 'string', operationsOptions));
    });
}

  async ngOnInit(): Promise<void> {
    this.isAdmin = await this.utilServicios.isAdmin();
    this.exportFileName = this.translate.instant('export.fileTerminal');
    if (this.isAdmin) {
      this.displayedColumns = Object.keys(ColumnsAdmin).filter(column => column !== 'idAtento');
      await this.cargar();
    } else {
      this.displayedColumns = Object.keys(Columns).filter(column => column !== 'idAtento');
    }

    this.lanzarLLamada();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isVmOff(row: VendingMachineAtento) {
    let val = false

    if (row.lastConect) {
      let mydate = parseStringToUTCDate(row.lastConect.toString())
      let fecha1 = moment(mydate); // 1st argument - string, 2nd argument - format        
      let fecha2 = moment();

      let diff_h = fecha2.diff(fecha1, 'h'); // Diff in hours

      if (diff_h >= (environment.hoursDesactivo)) { val = true }
    }

    return val;
  }

  onFilterApply(appliedFilters:AppliedFilter[]){
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter)=>{
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  pageEvent() {;
    this.lanzarLLamada();
  }

  resetPaginatorState(){
    this.paginator.firstPage();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".")
    this.sortId = active;

    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase()
    } else {
      this.direccion = null
      this.sortId = null
    }

    this.resetPaginatorState();
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }
  onView(row: VendingMachineAtento) {

    this.dialogRef = this.dialog.open(TabsComponent, {
      width: '90%', height: '95%', panelClass: 'custom-modalbox-big',
      data: { terminalId: row.id, terminalName:row.serialNumber }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });

  }
  

  getCoverageClass(element: VendingMachineAtento): string {
    if (!element.coverageLevel) {
      return 'no-coverage';
    } else if (element.coverageLevel >= 75) {
      return 'high-coverage';
    } else if (element.coverageLevel >= 30) {
      return 'medium-coverage';
    } else {
      return 'low-coverage';
    }
  }


  getCoverageTooltip(element: VendingMachineAtento): string {
    return element.coverageLevel
      ? `${element.coverageLevel}% ${element.coverageType}`
      : 'Sin datos';
  }

}
