<main id="main" class="main">
    <div class="pagetitle">
        <h1> {{ 'maintenace.tabs.sendFile' | translate }}</h1>
    </div>

    <section class="row w-100">
        <ng-container  *ngIf="!hasDefaultVendingMachine">
            <article class="col-lg-6 px-3 form-container">
                <app-select-machine-detail [dataSource]="selectedVendingMachines"></app-select-machine-detail>
            </article>
        </ng-container>
        
        <article class="col-lg-6 px-3 form-container">
            <form [formGroup]="fileForm">
                <div class="row mb-2">
                    <app-send-file [viewButtom]="false" [fileUp]="firmWareUpload" [hora]="pendingHour"></app-send-file>
                </div>

                <!-- Tipo de Archivo -->
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label> {{ 'common.fileType' | translate }}</mat-label>
                        <mat-select formControlName="storageType" required>
                            <mat-option *ngFor="let type of fileTypes" [value]="type">{{ 'maintenace.fileTypes.'+type | translate }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="fileForm.get('storageType')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Nombre del paquete -->
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'maintenace.input.packageName' | translate }}<span
                                class="obligatorio"></span></mat-label>
                        <input matInput formControlName="packageName"
                            placeholder="{{ 'maintenace.placeholder.packageName' | translate }}" required>
                        <mat-error *ngIf="fileForm.get('packageName')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'maintenace.input.path' | translate }} <span
                                class="obligatorio"></span></mat-label>
                        <input matInput formControlName="path"
                            placeholder="{{ 'maintenace.placeholder.path' | translate }}" required>
                        <mat-icon matSuffix matTooltip="{{ 'maintenace.tooltip.path' | translate }}">info</mat-icon>
                        <mat-error *ngIf="fileForm.get('path')?.hasError('required')">
                            {{ 'error.required.field' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="botonera">
                    <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">
                        {{ 'common.submit' | translate }}
                    </button>
                </div>
            </form>
        </article>
    </section>
</main>