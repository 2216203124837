<section class="section">
    <form [formGroup]="registerForm">
        <div class="row">
            <div class="file-upload-container">
                <!-- Mostrar solo si no hay archivo cargado -->
                <div class="drop-zone" *ngIf="!fileName" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
                  <div class="drop-zone-icon">
                    <mat-icon>cloud_upload</mat-icon>
                    <p>{{ 'common.selectFiles' | translate }}</p>
                  </div>
                </div>
        
                <!-- Mostrar nombre de archivo cargado y botón para eliminar -->
                <div *ngIf="fileName" class="file-uploaded">
                  <p class="file-uploaded-text">Archivo seleccionado: {{ fileName }}</p>
                  <button mat-icon-button  class="delete-button" (click)="removeFile()">
                    <mat-icon>delete</mat-icon>
                </button>
                </div>
        
                <!-- Input oculto para seleccionar archivos -->
                <input type="file" (change)="onFileSelected($event)" hidden #fileUpload>
              </div>
            </div>
          
        <div class="element-header py-3">
            <span>{{ 'maintenace.timeSchedule' | translate }}</span>
        </div>

        <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <mat-form-field appearance="outline" ngDefaultControl>
                            <mat-label>{{ 'maintenace.input.startTime' | translate }}</mat-label>
                            <input type="time" matInput formControlName="timeIni" (change)="valuechange($event)">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <mat-form-field appearance="outline" ngDefaultControl>
                            <mat-label>{{ 'maintenace.input.endTime' | translate }}</mat-label>
                            <input  type="time" matInput formControlName="timeEnd"  (change)="updateValueEnd($event)">
                        </mat-form-field>
                    </div>
                </div>
        </div>


        <div class="row botonera" *ngIf="viewButtom">
            <div class="col-lg-12">
                <button class="btn btn-aceptar" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
            </div>
        </div>

    </form>
</section>