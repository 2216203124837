<!-- Hidden file input -->
<input type="file" class="file-upload" (change)="onFilesSelected($event)" #fileUpload hidden multiple>
<!-- Drop zone -->
<div class="drop-zone" (drop)="onFilesDropTerminals($event)" (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)" (click)="fileUpload.click()">
    <div class="drop-zone-icon">
        <mat-icon>cloud_upload</mat-icon>
        <p>{{ 'common.selectFiles' | translate }}</p>
    </div>
</div>
