<app-header *ngIf="(isAuthenticated$ | async)" ></app-header>
<app-sidebar *ngIf="(isAuthenticated$ | async)"></app-sidebar>
<app-topbar *ngIf="_router.url !== '/terms-conditions' && (isAuthenticated$ | async) === false " ></app-topbar>


<router-outlet></router-outlet><notifier-container></notifier-container>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" color="#fff"  size="medium" [type]="typeSelected">
    <p style="color: white">{{textShown}}</p>
</ngx-spinner>
<app-footer *ngIf="!(_router.url === '/pages-register' || _router.url === '/pages-login' || _router.url === '/pages-error404' || _router.url === '/welcome')" ></app-footer>


<form action="{{logoutBaseUrl}}" method="post" name="logout" #logoutForm>
    <input type="hidden" id="redirect" name="redirect" value="{{redirect}}"/>
    <a class="d-flex align-items-center" role="button" routerLink="/" (click)="logout()"> </a>
</form>

<!-- para borrar -->
<footer id="footer" class="footer" *ngIf="showDebugInfo">

    <div class="container-fluid">
        <div class="container-fluid mt-2">
            <hr>

            <div class="card-body">
                  
                <button type="button" class="btn " (click)="loadCSS('default')">Style Default</button>
                <button type="button" class="btn " (click)="loadCSS('xiibero')">Style Xiibero</button> 
                <button type="button" class="btn " (click)="loadCSS('FISERVeu')">Style FISERV</button>
                               </div>


            <p>
                <button class="btn btn-success mr-1" (click)='login()'>login</button>
                <button class="btn btn-primary mr-1" (click)='logout()'>logout</button>
                <button class="btn btn-link mr-1" (click)='logoutExternally()'>logout externally...</button>
            </p>
            <p>
                <button class="btn btn-warning mr-1" (click)='refresh()'>force silent refresh</button>
                <button class="btn btn-secondary mr-1" (click)='reload()'>reload page</button>
                <button class="btn btn-danger mr-1" (click)='clearStorage()'>clear storage</button>
            </p>
            <hr>
            <table  aria-describedby="" class="table table-bordered table-sm table-props">
                <tr><th>IsAuthenticated</th><td><code id="isAuthenticated">{{isAuthenticated$ | async}}</code></td></tr>
                <tr><th>HasValidToken</th><td><code id="hasValidToken">{{hasValidToken}}</code></td></tr>
                <tr><th>IsDoneLoading</th><td><code id="isDoneLoading">{{isDoneLoading$ | async}}</code></td></tr>
                <tr><th>CanActivateProtectedRoutes</th><td><code id="canActivateProtectedRoutes">{{canActivateProtectedRoutes$ | async}}</code></td></tr>
                <tr><th>IdentityClaims</th><td class="pre"><code id="identityClaims">{{identityClaims | json}}</code></td></tr>
                <tr><th>RefreshToken</th><td><code class="break-all">{{refreshToken}}</code></td></tr>
                <tr><th>AccessToken</th><td><code class="break-all">{{accessToken}}</code></td></tr>
                <tr><th>IdToken</th><td><code class="break-all">{{idToken}}</code></td></tr>
            </table>
        </div>
    </div>
</footer>


