// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BuildFlags, LogLevel } from "src/app/util/cm-types";
import { AUTHENTICACION } from "src/app/util/constants";

export const environment = {
  production: false,
  debugInfo: false,
  paginacion: [5 , 10, 25, 50, 100],
  defaultPaginacion: 10,
  hoursDesactivo: 1,
  pathTempfile:"/tmp"
};

/**
 * Configuracion general de la aplicacion
 */
 export const AppConfig = {
  RunSymbols: BuildFlags.DEBUG | BuildFlags.DEV,
  LogLevel: LogLevel.TRACE,
  // Longitud maxima de texto volcado al log en una linea
  LogMsgMaxLength: 1024,
}


//Valores maximos graficas
export const MAX_PRINTED_LINE=100000
export const MAX_KEYSTROKES=100000
export const MAX_HOURS_ACTIVATES=100000
export const MAX_LOAD_CYCLES=100000

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

export const SERVER_URL_CONSOLA_REST = '/mdm-server/consoleRest/';
export const SERVER_URL_AUTH_TMS = '/mdm-server/auth/';


export const AUTH_ENTORNO = AUTHENTICACION.TMS_SERVER;


