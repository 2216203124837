import { FilterAndPagBean } from "./FilterAndPagBean";

export class Parameters {
	
	id!:number;
	//idOperator!:number;
	name!:string; 
	dateCreate!: Date;
	dateUpdate!: Date;
	description!: string;
	base64!:string;
	fileName!:string;
	package!:string;
	storageType!:string;
	path!:string;
	//fileBytes!:any[];
	//md5sum!:string;
	version!:string;
	idOperator?:number;
	operator?:string;
	
	filterAndPagBean!: FilterAndPagBean | undefined;
}
