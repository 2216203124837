import { inject, Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginatorIntlService implements MatLegacyPaginatorIntl{
    translate = inject(TranslateService);

    changes: Subject<void> =  new Subject<void>();
    firstPageLabel: string = this.translate.instant('paginator.firstPageLabel');
    lastPageLabel: string = this.translate.instant('paginator.lastPageLabel');
    itemsPerPageLabel: string = this.translate.instant('paginator.itemsPerPageLabel');
    nextPageLabel: string = this.translate.instant('paginator.nextPageLabel');
    previousPageLabel: string = this.translate.instant('paginator.previousPageLabel');

    getRangeLabel(page: number, pageSize: number, length: number): string {
      const pageLabel = this.translate.instant('paginator.page')
      const pageof = this.translate.instant('paginator.of')

      if (length === 0) {
        return `${pageLabel} 1 ${pageof} 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `${pageLabel} ${page + 1} ${pageof} ${amountPages}`;
    };

    updateLabelsState(){
      this.firstPageLabel = this.translate.instant('paginator.firstPageLabel');
      this.lastPageLabel = this.translate.instant('paginator.lastPageLabel');
      this.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPageLabel');
      this.nextPageLabel = this.translate.instant('paginator.nextPageLabel');
      this.previousPageLabel = this.translate.instant('paginator.previousPageLabel');
      this.changes.next();
    }
  
}
