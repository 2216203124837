import { Pipe, PipeTransform } from '@angular/core';
import { Operator } from 'src/app/models/operator.model';
import { UtilService } from 'src/app/services/util.service';

@Pipe({
  name: 'operatorName'
})
export class OperatorNamePipe implements PipeTransform {
  private operators: Operator[] = [];

  constructor(private utilService: UtilService) {
    this.utilService.findOperators().subscribe((operators:Operator[]) => {
        this.operators = operators;
      });
  }

  transform(operatorId: number): string {
    const operator = this.operators.find(op => op.id === operatorId);
    return operator ? operator.name : 'Unknown Operator';
  }
}
