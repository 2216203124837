import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginatorIntl, MatLegacyPaginatorModule, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { Rol } from 'src/app/models/rol.model';
import { environment } from 'src/environments/environment';
import { FilterableValue, FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { AppliedFilter, FieldType, FilterField } from 'src/app/models/filter.model';
import { UtilService } from 'src/app/services/util.service';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { NotifierService } from 'angular-notifier';
import { RolService } from './rol.service'; // Importa el servicio correspondiente
//import { FormRolComponent } from './form-rol/form-rol.component';
import { CommonModule } from '@angular/common';
import { FilterComponent } from 'src/app/components/filter/filter.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { PaginatorIntlService } from 'src/app/services/paginator-intl.service';
import { TableEmptyStateComponent } from 'src/app/components/table-empty-state/table-empty-state.component';
import { FormNewRolComponent } from './form-new-rol/form-new-rol.component';
import { FormUsersComponent } from '../users/form-users/form-users.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FormEditRolComponent } from './form-edit-rol/form-edit-rol.component';

enum Columns {
  view = 'view',
  name = 'name',
  description = 'description',
}

const FILTER_COLUMNS: { field: string, type: FieldType }[] = [
  { field: 'name', type: 'string' },
  { field: 'description', type: 'string' },
];

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyTableModule,
    MatLegacyPaginatorModule,
    MatSortModule,
    MatTableExporterModule,
    TranslateModule,
    FilterComponent,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    TableEmptyStateComponent
  ],
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css'],
  providers: [{ provide: MatLegacyPaginatorIntl, useClass: PaginatorIntlService }]
})
export class RolComponent implements OnInit {
  displayedColumns = Object.keys(Columns);
  dataSource = new MatTableDataSource<Rol>();
  exportFileName: string | undefined;
  private dialogRef!: MatDialogRef<FormNewRolComponent>;
  private dialogRefEdit!: MatDialogRef<FormEditRolComponent>;

  roles = []; // Array de roles cargados en el componente de tabla

  pageLength = 0;
  pageSize = environment.defaultPaginacion;
  direccion: string | null = "ASC";
  pageSizeOptions = environment.paginacion;
  sortId: string | null = Columns.name;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // Filters config
  filterColumns: FilterField[] = FILTER_COLUMNS.map((column) => {
    const filterField = new FilterField(column.field, `roles.${column.field}`, column.type);
    return filterField;
  });
  filterList: FilterableValue[] = [];

  constructor(
    private service: RolService, // Servicio para manejar los roles
    public dialog: MatDialog,
    private utilService: UtilService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private rolService: RolService
  ) {}

  async lanzarLLamada() {
    const paginatorState = this.getPaginatorState();
    const filterAndPagBean = new FilterAndPagBean(
        this.direccion,
        this.sortId,
        this.filterList,
        paginatorState.startPage,
        paginatorState.endPage,
        paginatorState.pageIndex
    );
        const value = await this.service.find(filterAndPagBean);
        console.log('Datos recibidos en lanzarLLamada:', value); // Para inspeccionar los datos

        const pagCount = await this.service.countTotal(filterAndPagBean);

        if (value && value.data) {
            this.dataSource.data = value.data; // Asegúrate de que esto esté asignado correctamente
            this.pageLength = pagCount ? pagCount.data?.total ?? 0 : 0; 
        } else {
            this.dataSource.data = []; 
            this.pageLength = 0; 
        }

}


  private getPaginatorState() {
    const { pageIndex, pageSize } = this.paginator ?? { pageIndex: 0, pageSize: this.pageSize };
    const startPage = pageIndex * pageSize;
    const endPage = startPage + pageSize;

    return { startPage, endPage, pageIndex };
  }

  async ngOnInit(): Promise<void> {
    this.exportFileName = this.translate.instant('export.fileRole');

    // Definimos las columnas de manera condicional
    this.displayedColumns = [
      Columns.name,
      Columns.description,
      Columns.view
    ];

    // Cargar datos al iniciar
    this.lanzarLLamada();
  }

   onNew() {
    this.dialogRef = this.dialog.open(FormNewRolComponent, {
      width: '800px', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/ }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

   onEdit(row: Rol) {
    this.dialogRefEdit = this.dialog.open(FormEditRolComponent, {
      width: '700px', 
      panelClass: 'custom-modalbox-big',
      data: { 
        rol: row,       
        rolId: row.id 
      }
    });
  
    this.dialogRefEdit.afterClosed().subscribe((result) => {
      if (result === 1) {
        console.log('CONFIRM received from dialog window');
        this.lanzarLLamada();  
      } else if (result === 0) {
        console.log('CANCEL received from dialog window');
      }
    });
  }
  
  

    onDelete(rol: Rol) {
      const dialogData = new ConfirmDialogData();
      dialogData.titleI18n = this.translate.instant('confirmDialog.deleteRolTitle');
      dialogData.textI18n = this.translate.instant('confirmDialog.deleteRolText');
    
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '25%', panelClass: 'custom-modalbox',
        data: dialogData
      });
    
      confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
        if (result === ConfirmDialogActions.CONFIRM) {
          if (rol.id !== undefined) {  // Verificar que rol.id no sea undefined
            await this.deleteRol(rol.id);
          } else {
            console.error('Error: El ID del rol es undefined.');
          }
        }
      });
    }
    

  private async deleteRol(rolId: number) {
    const result = await this.service.deleteRol(rolId);
    if (result && result.status === 0) {
      this.notifier.notify('success', this.translate.instant('success.roleDeleted'));
      this.lanzarLLamada();
    }
  }
  

  refrescar() {
    this.lanzarLLamada();
  }

  pageEvent() {
    this.lanzarLLamada();
  }

  announceSortChange(sortState: Sort) {
    let active = sortState.active.replace("_", ".");
    this.sortId = active;
    if (sortState.direction) {
      this.direccion = sortState.direction.toUpperCase();
    } else {
      this.direccion = null;
      this.sortId = null;
    }
    this.paginator.firstPage();
    this.lanzarLLamada();
  }

  onFilterApply(appliedFilters: AppliedFilter[]) {
    this.filterList = [];
    this.filterList = appliedFilters.map((appliedFilter) => {
      const filter = appliedFilter.filter;
      return new FilterableValue(filter.column, filter.value, filter.type, filter.operation);
    });
    this.paginator.firstPage();
    this.lanzarLLamada();
  }
}

