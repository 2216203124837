<main class="main o-hidden">
  <div class="pagetitle mt-3">
      <h1>{{ 'maintenace.title' | translate }}</h1>
  </div>

  <ng-container *ngIf="terminal">
    <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">  
        <mat-tab *ngIf="rol.canByInput(rol.functIds.send_file)">
          <ng-template mat-tab-label>
            <span>{{ 'maintenace.tabs.sendFile' | translate }}</span>
          </ng-template>
            <app-send-file-maintenace [data]="terminal"></app-send-file-maintenace>
        </mat-tab>
        <mat-tab *ngIf="rol.canByInput(rol.functIds.get_file)">
          <ng-template mat-tab-label>
            <span>{{ 'maintenace.tabs.getFile' | translate }}</span>
          </ng-template>
            <app-get-file [data]="terminal"></app-get-file>
        </mat-tab>
        <mat-tab *ngIf="rol.canByInput(rol.functIds.commands)">
          <ng-template mat-tab-label>
            <span>{{ 'maintenace.tabs.createCommands' | translate }}</span>
          </ng-template>
            <app-commands [data]="terminal"></app-commands>
        </mat-tab>  
        <mat-tab *ngIf="rol.canByInput(rol.functIds.view_file)">
          <ng-template mat-tab-label>
            <span>{{ 'maintenace.tabs.listFiles' | translate }}</span>
          </ng-template>
          <ng-template matTabContent>
            <app-list-files [data]="terminal"></app-list-files>
          </ng-template>
        </mat-tab>  

    </mat-tab-group>
  </ng-container>
  
</main>