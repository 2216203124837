<div class="pagetitle">
    <h1 i18n>{{ 'incidences.title' | translate }}</h1>
</div>
<section class="section">
    <form [formGroup]="registerForm">

        <div class="details-container">
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label> {{ 'incidences.type' | translate }}<span class="obligatorio"></span></mat-label>
                    <mat-select formControlName="type" [value]="typeSelect">
                        <mat-option value="NULL"> </mat-option>
                        <mat-option value="0">{{ 'incidences.types.other' | translate }}</mat-option>
                        <mat-option value="1">{{ 'incidences.types.suggestion' | translate }}</mat-option>
                        <mat-option value="2">{{ 'incidences.types.telemetryFailure' | translate }}</mat-option>
                        <mat-option value="3">{{ 'incidences.types.terminalBreakdown' | translate }}</mat-option>
                        <mat-option value="4">{{ 'incidences.types.nonActiveTerminal' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.get('type')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>

                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'incidences.operator' | translate }}<span class="obligatorio"></span></mat-label>
                    <mat-select formControlName="operator">
                        <mat-option *ngFor="let operator of operatorList" [value]="operator.id">
                            {{ operator.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.get('operator')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
            </div>
            
            <div class="form-group">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'incidences.serialNumber' | translate }}<span class="obligatorio"></span></mat-label>
                    <mat-select formControlName="numTer">
                        <mat-option *ngFor="let terminal of terminals" [value]="terminal.id">{{ terminal.serialNumber }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="registerForm.get('numTer')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'incidences.description' | translate }}<span class="obligatorio"></span></mat-label>
                        <textarea formControlName="commet" rows="7" matInput placeholder=""></textarea>
                        <mat-error *ngIf="registerForm.get('commet')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isCloseIncidence">
            <div class="col-lg-12">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'incidences.resolution' | translate }}<span class="obligatorio"></span></mat-label>
                        <textarea formControlName="resolution" rows="7" matInput placeholder=""></textarea>
                        <mat-error *ngIf="registerForm.get('resolution')?.hasError('required')">{{ 'error.required.field' | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- Botones para navegación -->
        <div class="botonera mt-3">
            <button mat-stroke-button class="custom-white-button" (click)="formExit()">{{ 'common.back' | translate }}</button>
            <button mat-flat-button class="custom-add-button" (click)="onFormSubmit()">{{ 'common.submit' | translate }}</button>
        </div>

    </form>
</section>