<main class="login-section">
  <div class="blur"></div> 
    <div class="container">
       <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
             <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                   <div class="card mb-3">
                      <div class="card-body">
                         <div class="pt-4 pb-2">
                            <div class="d-flex justify-content-center py-4"> 
                              <div  class="logo d-flex align-items-center w-auto"> 
                                 <img src="assets/img/logo.png" alt=""> 
                                 <span class="d-lg-block" i18n><b>MDM</b></span> 
                              </div>
                           </div>                           
                         </div>
                         <form  [formGroup]="registerForm" class="row g-3" novalidate>
                             <div class="cardLogin">
                                  <mat-form-field ngDefaultControl>
                                      <label i18n>{{ 'login.Username' | translate }}</label>
                                      <input class="form-control" matInput formControlName="username" type="text"  required >
                                      <mat-error *ngIf="registerForm.get('username')?.hasError('required')"  i18n>{{ 'error.required.username' | translate }}</mat-error>
                                      <mat-error *ngIf="registerForm.get('username')?.hasError('minLength')"  i18n>{{ 'error.minLength' | translate }}</mat-error>
                                      <mat-error *ngIf="registerForm.get('username')?.hasError('maxLength')"  i18n>{{ 'error.maxLength' | translate }}</mat-error>
                                      <mat-error *ngIf="registerForm.get('username')?.hasError('pattern')"  i18n>{{ 'error.pattern' | translate }}</mat-error>
                                  </mat-form-field>
                              </div>

                              <div class="cardLogin">
                                 <mat-form-field ngDefaultControl>
                                     <label i18n> {{ 'login.Password' | translate }}</label>
                                     <input class="form-control" matInput formControlName="password" type="password"  required >
                                     <mat-error *ngIf="registerForm.get('password')?.hasError('required')"  i18n>{{ 'error.required.password' | translate }}</mat-error>
                                     <mat-error *ngIf="registerForm.get('password')?.hasError('minLength')"  i18n>{{ 'error.minLength' | translate }}</mat-error>
                                     <mat-error *ngIf="registerForm.get('password')?.hasError('maxLength')"  i18n>{{ 'error.maxLength' | translate }}</mat-error>
                                     <mat-error *ngIf="registerForm.get('password')?.hasError('pattern')"  i18n>{{ 'error.pattern' | translate }}</mat-error>
                                 </mat-form-field>
                             </div>

                        
                            <div class="col-12"> 
                              <button class="btn btn-primary w-100 custom-login-button" (click)="onFormSubmit()" i18n>{{ 'login.login' | translate }}</button>
                           </div>
                            <div class="col-12">
                               <p class="small mb-0">Version {{appVersion}}</p>
                            </div>
                         </form>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
    </div>
 </main>